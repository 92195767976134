import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getBanksRequest } from '../../infrastructure/api/banksInfo';

export const initialState = {
	navBarLogo: null,
	alertData: { text: '', type: '', icon: '', dismissTime: 0 },
	banksInfo: [],
};

export const getBanksFetch = createAsyncThunk('reservations/getBanksFetch', async (_, { rejectWithValue }) => {
	try {
		return await getBanksRequest();
	} catch (error) {
		return rejectWithValue(error);
	}
});

const AdminLayout = createSlice({
	name: 'adminLayout',
	initialState,
	reducers: {
		setNavBarLogo: (state, action) => {
			state.navBarLogo = action.payload;
		},
		setAlertData: (state, action) => {
			state.alertData = action.payload;
		},
	},
	extraReducers: {
		[getBanksFetch.pending]: (state) => {
			state.error = null;
		},
		[getBanksFetch.rejected]: (state, { payload }) => {
			state.error = payload;
		},
		[getBanksFetch.fulfilled]: (state, { payload }) => {
			state.banksInfo = payload;
		},
	},
});

export const { setNavBarLogo, setAlertData } = AdminLayout.actions;

export default AdminLayout.reducer;
