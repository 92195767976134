import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { NotificationBox, Steps } from '@vecindario/vecindario-suite-components';
import { history } from '../../../../../shared/application/helpers/history';

import {
	COMPLETED_STEPS_NOTIFICATION_BOX,
	STEPS,
	STEPS_NOTIFICATION_BOX,
} from '../../../../../shared/application/constants/steps-options';
import {
	associatesInformationRoute,
	financialInformationRoute,
	informationRoute,
	personalInformationRoute,
	requestRoute,
} from '../../../../creditRequest/infrastructure/routing/routes';

import './StepSection.scss';
import {
	selectPreApprovedDetail,
	selectCurrentStep,
	selectRequestSteps,
} from '../../../../creditRequest/application/selectors/credit-request';
import { isUserLogged } from '../../../../authentication/application/selectors/user';
import { REQUEST_STAGES } from '../../../../../shared/application/constants/pre-approved';

const StepSection = () => {
	const preApprovedDetail = useSelector(selectPreApprovedDetail);
	const requestSteps = useSelector(selectRequestSteps);
	let currentStep = useSelector(selectCurrentStep);
	const hasUser = useSelector(isUserLogged);

	currentStep = hasUser ? currentStep : 0;
	const stage = preApprovedDetail?.stage || REQUEST_STAGES.INCOMPLETE;
	const isIncomplete = stage === REQUEST_STAGES.INCOMPLETE;
	const currentStepToComponent = isIncomplete ? currentStep - 1 : currentStep;

	const preApprovedSteps = STEPS(preApprovedDetail?.partners && preApprovedDetail?.partners[0]).filter(
		(step) => step.step <= requestSteps,
	);

	return (
		<div className="step-container">
			<div className="title-step">Guía para solicitar tu preaprobado</div>
			<Steps
				currentStep={currentStepToComponent}
				history={history}
				steps={preApprovedSteps}
				actualFunctionalSteps={preApprovedDetail?.total_steps}
			/>
			<LoggedStepSection />
		</div>
	);
};

const LoggedStepSection = ({ isIncomplete = false }) => {
	const preApprovedDetail = useSelector(selectPreApprovedDetail);
	const currentStep = useSelector(selectCurrentStep);
	const hasUser = useSelector(isUserLogged);

	const getStepToView = () => {
		if (isIncomplete) {
			return STEPS_NOTIFICATION_BOX.find((step_notify) => step_notify.step === currentStep);
		}

		return COMPLETED_STEPS_NOTIFICATION_BOX.find((step_notify) => step_notify.step === preApprovedDetail?.stage);
	};

	const getStep = getStepToView();

	const handleStep = () => {
		switch (getStep.step) {
			case 1:
				history.push(informationRoute);
				break;
			case 2:
				history.push(personalInformationRoute);
				break;
			case 3:
				history.push(financialInformationRoute);
				break;
			case 4:
				history.push(associatesInformationRoute(preApprovedDetail?.partners[0]));
				break;
			default:
				history.push(associatesInformationRoute(requestRoute));
		}
	};

	if (!hasUser) return null;

	return (
		<NotificationBox
			stepCompleted={getStep?.completed}
			title={getStep?.title}
			description={getStep?.description}
			textButton={getStep?.textButton}
			handleClick={handleStep}
			showIcon={true}
			showCheckIcon={getStep?.showCheckIcon}
		/>
	);
};

LoggedStepSection.propTypes = {
	isIncomplete: PropTypes.bool,
};

export default StepSection;
