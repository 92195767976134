import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card, LazyImage, Icon, TextTag, Spinner } from '@vecindario/vecindario-suite-components';
import { getBanks } from '../../../infrastructure/api/banks-information';
import { tipsRoute } from '../../../../tips/infrastructure/routes';
import { bankRequestProjectRoute, bankRequestRoute } from '../../../../bankRequest/infrastructure/routing/routes';
import './BankCard.scss';
import { INFO_BANK, PROJECT_SLUG } from '../../../../../shared/application/constants/pre-approved-data';
import { setValueToLocalStorage } from '../../../../../shared/application/helpers/common-functions';

const ContentCard = ({ logo, responseTime, validityPreApproved, slugBank, data, slugProject }) => {
	const history = useHistory();

	const handleOnclick = (slug) => {
		if (slug === 'credifamilia') {
			history.push(tipsRoute);
		} else {
			if (slugProject) {
				setValueToLocalStorage(PROJECT_SLUG, slugProject);
				history.push(bankRequestProjectRoute(slugProject, slug));
			} else {
				history.push(bankRequestRoute(slug));
			}
			const dataToJson = JSON.stringify(data);
			setValueToLocalStorage(INFO_BANK, dataToJson);
		}
	};
	return (
		<Card classNameCard="bank-card" onClick={() => handleOnclick(slugBank)}>
			<div className="header">
				<div className="contentImage">
					<LazyImage src={logo} imageName={'ejemplo'} />
				</div>
			</div>
			<div className="footer">
				<div className="prue">
					<div className="time">
						<Icon icon="ri-time-line" size="small" />
						<TextTag tag="span" variant="-body-sm" font="Lato">
							Tiempo de respuesta: <strong className="color-primary-500">{responseTime}</strong>
						</TextTag>
					</div>
					<div className="time">
						<Icon icon="ri-calendar-line" size="small" />
						<TextTag tag="span" variant="-body-sm" font="Lato">
							Vigencia de preaprobado: {validityPreApproved}
						</TextTag>
					</div>
					{slugBank === 'colsubsidio' && (
						<TextTag variant="-body-xs" className="extra-info">
							*Aplica solo para afiliados a caja de compensación Colsubsidio.
						</TextTag>
					)}
				</div>
				<Icon icon="ri-arrow-right-line" size="small" aditionalClassName="arrow-r" />
			</div>
		</Card>
	);
};

const BankCard = () => {
	const [banks, setBanks] = useState([]);
	const [loading, setLoading] = useState(false);
	const { slug } = useParams();

	useEffect(() => {
		setLoading(true);
		getBanks(slug)
			.then((data) => setBanks(data))
			.finally(() => setLoading(false));
	}, [slug]);

	return (
		<div className="bankcard-section">
			{loading ? (
				<div className="loading">
					<Spinner size="x-large" />
				</div>
			) : (
				banks.map((bank) => (
					<ContentCard
						key={bank.id}
						logo={bank.logo}
						responseTime={bank.responseTime}
						validityPreApproved={bank.validityPreApproved}
						slugBank={bank.slug_bank}
						slugProject={slug}
						data={bank}
					/>
				))
			)}
		</div>
	);
};

ContentCard.propTypes = {
	logo: PropTypes.string,
	responseTime: PropTypes.string,
	validityPreApproved: PropTypes.string,
	slugBank: PropTypes.string,
	data: PropTypes.object,
	slugProject: PropTypes.string,
};

export default BankCard;
