import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Select, Input, MessageError } from '@vecindario/vecindario-suite-components';
import InformativePageWrapper from '../../../../../shared/presentation/pages/InformativePageWrapper';
import { PERSONAL_INITIAL_BANNER } from '../../../../../shared/application/constants/images';
import { personalInformationFields } from '../../../application/constants/finantialInformation';

import './InitialSection.scss';
import { selectDataLists, preApprvedMessageError } from '../../../application/selectors/credit-request';
import { requestInformation, updateRequestCredential } from '../../../application/slices/credit-request';
import { selectUserGreeting } from '../../../../authentication/application/selectors/user';
import { initialRequestSchema } from '../../../application/schemas/initialRequest';
import ConfirmationModal from '../../../../../shared/presentation/components/utils/ConfirmationModal';

const StartRequestSection = () => {
	const dispatch = useDispatch();
	const preApprovedError = useSelector(preApprvedMessageError);
	const dataLists = useSelector(selectDataLists);
	const userGreeting = useSelector(selectUserGreeting);

	const [modalState, setModalState] = useState(false);
	const [dataForm, setDataForm] = useState(false);
	const [formError, setFormError] = useState('');

	useEffect(() => {
		if (preApprovedError?.message) {
			setFormError(preApprovedError?.message);
		}
		return () => {
			setFormError('');
		};
	}, [preApprovedError]);

	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
		watch,
	} = useForm({
		resolver: yupResolver(initialRequestSchema),
		mode: 'onChange',
	});

	const sendRequest = (data) => {
		const requestData = {
			document_number: parseInt(data.document_number),
			document_type: data.document_type,
		};
		dispatch(updateRequestCredential(requestData));
		dispatch(requestInformation(requestData));
	};
	const onSubmit = (data) => {
		setDataForm(data);
		setModalState(true);
	};

	const itemSelected = (value) => {
		setValue(personalInformationFields.TYPE_ID, value);
	};

	const handleCloseModal = () => {
		setModalState(false);
	};

	const handleDocumentNumber = (value) => {
		const lastValue = value.substring(0, value.length - 1);
		const maxLength = 10;
		setValue(personalInformationFields.NUMBER_ID, value.length <= maxLength ? value : lastValue);
	};

	return (
		<InformativePageWrapper banner={PERSONAL_INITIAL_BANNER} name={userGreeting}>
			<div className="initial-request-section">
				<p className="text">
					Antes de empezar con el pre-aprobado de tu crédito hipotecario, necesitamos saber un poco más de ti con los
					siguientes datos:
				</p>

				<form className="request-form-group" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
					<div className="initial-from-group">
						<div className="form-control">
							<Select
								id="Tipo de documento"
								options={dataLists.tipoDocumento}
								onSelectItem={(item) => {
									itemSelected(item.value);
								}}
								showIconError={false}
								label="Tipo de documento"
								{...register(personalInformationFields.TYPE_ID)}
								error={errors[personalInformationFields.TYPE_ID]?.message}
							/>
						</div>
						<div className="form-control">
							<Input
								placeholder="Número de documento"
								type="number"
								{...register(personalInformationFields.NUMBER_ID)}
								onChange={(e) => handleDocumentNumber(e.target.value)}
								error={errors[personalInformationFields.NUMBER_ID]?.message}
							/>
						</div>
					</div>

					{formError && <MessageError message={formError} />}

					<div className="credit-warning">
						<p>
							Recuerda que para solicitar tu crédito debes contar con la{' '}
							<b className="bold-text">antigüedad mínima requerida según tu ocupación actual.</b>
						</p>
					</div>

					<div className="form-action">
						<Button type="submit" text="Entrar" />
					</div>
				</form>

				<ConfirmationModal
					onClose={handleCloseModal}
					open={modalState}
					description={
						<span>
							Recuerda que tus datos:{' '}
							<b>{`  ${watch()[personalInformationFields.TYPE_ID]} ${watch()[personalInformationFields.NUMBER_ID]}`}</b>, serán
							enviados y no podrán ser modificados una vez inicies el proceso de solicitud.
						</span>
					}
					onConfirm={() => {
						sendRequest(dataForm);
						handleCloseModal();
					}}
				/>
			</div>
		</InformativePageWrapper>
	);
};

export default StartRequestSection;
