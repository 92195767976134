import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AdminLayout from '../../../../../shared/presentation/layouts/AdminLayout';
import {
	requestCities,
	updateSuccessfulSave,
	requestDataLists,
	fetchOwnerInformation,
} from '../../../application/slices/credit-request';
import {
	ownerInformationData,
	selectPreApprovedResponse,
	selectShowSuccessfulSave,
} from '../../../application/selectors/credit-request';
import SuccessfulSaveModal from '../../components/SuccessfulSaveModal';
import { REQUEST_STEP_KEY } from '../../../application/constants/local-storage';
import {
	financialInformationRoute,
	informationRoute,
	personalInformationRoute,
	requestRoute,
} from '../../../infrastructure/routing/routes';
import { history } from '../../../../../shared/application/helpers/history';
import { isEmptyObject } from '../../../../../shared/application/helpers/common-functions';
import './CreditRequestLayout.scss';
import { getBanksFetch } from '../../../../../shared/application/slices/adminLayout';

const validateRedirectionRoutes = (currentPath = '') => {
	const lastActiveStep = parseInt(localStorage.getItem(REQUEST_STEP_KEY) || '0');

	if (currentPath === informationRoute && lastActiveStep < 1) {
		history.replace(requestRoute);
	} else if (currentPath === personalInformationRoute && lastActiveStep < 2 && lastActiveStep >= 1) {
		history.replace(informationRoute);
	} else if (currentPath === financialInformationRoute && lastActiveStep < 3 && lastActiveStep >= 2) {
		history.replace(personalInformationRoute);
	}
};

const CreditRequestLayout = ({ children }) => {
	const dispatch = useDispatch();
	const showSuccessMessage = useSelector(selectShowSuccessfulSave);
	const preApproved = useSelector(selectPreApprovedResponse);
	const ownerInformation = useSelector(ownerInformationData);
	const { pathname } = useLocation();

	useEffect(() => {
		validateRedirectionRoutes(pathname);
	}, [pathname]);

	useEffect(() => {
		dispatch(requestDataLists());
		dispatch(requestCities());
		dispatch(getBanksFetch());
	}, [dispatch]);

	useEffect(() => {
		if (isEmptyObject(ownerInformation) && !isEmptyObject(preApproved)) {
			dispatch(fetchOwnerInformation(preApproved));
		}
	}, [dispatch, preApproved, ownerInformation]);

	const handleSuccessfulModalClose = () => dispatch(updateSuccessfulSave(false));

	return (
		<AdminLayout>
			<div className="credit-request-layout">{children}</div>
			<SuccessfulSaveModal open={showSuccessMessage} onClose={handleSuccessfulModalClose} />
		</AdminLayout>
	);
};

CreditRequestLayout.propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

export default CreditRequestLayout;
