import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@vecindario/vecindario-suite-components';
import { WARNING_ERROR } from '../../../../application/constants/icons';

const ConfirmationModal = ({ open = false, onClose = () => {}, description = '', onConfirm = () => {} }) => {
	const modalStyle = {
		container: {
			backgroundColor: 'white',
			borderRadius: '8px',
			display: 'flex',
			justifyContent: 'center',
			flexDirection: 'column',
			alignItems: 'center',
			padding: '0px 6%',
			minWidth: '250px',
			maxWidth: '650px',
			width: '90%',
			maxHeight: '350px',
		},
		content: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			textAlign: 'justify',
			alignItems: 'center',
			textAlignLast: 'center',
			padding: '0',
			height: '100%',
		},

		icon: {
			fontSize: '5rem',
			color: '#0b1d2e',
			marginBottom: '3rem',
		},

		description: {
			fontSize: '1.5rem',
		},
		buttonContainer: {
			display: 'flex',
			justifyContent: 'center',
			flexWrap: 'wrap',
			marginTop: '3rem',
		},
		button: {
			margin: '1rem',
		},
	};

	return (
		<Modal isOpen={open} onClose={onClose} contentStyle={modalStyle.container}>
			<div style={modalStyle.content}>
				<i className={WARNING_ERROR} style={modalStyle.icon}></i>

				<p style={modalStyle.description}>{description}</p>
				<div style={modalStyle.buttonContainer}>
					<Button text="Cancelar" variant="bordered" style={modalStyle.button} onClick={onClose} />
					<Button text="Continuar" style={modalStyle.button} onClick={onConfirm} />
				</div>
			</div>
		</Modal>
	);
};

ConfirmationModal.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	onConfirm: PropTypes.func,
};

export default ConfirmationModal;
