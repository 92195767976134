import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import './InformativePageWrapper.scss';
import BankInformation from '../../components/utils/BankInformation/index';
import { getBankCredifamilia } from '../../../application/selectors/adminLayout';

const InformativePageWrapper = ({ children, banner, variant, bannerClassName, description }) => {
	const infoCredifamilia = useSelector(getBankCredifamilia);
	return (
		<div className={`informative-page-wrapper ${variant} `}>
			<BankInformation
				logo={infoCredifamilia?.logo}
				responseTime={infoCredifamilia?.responseTime}
				validityPreApproved={infoCredifamilia?.validityPreApproved}
			/>
			<div className="banner-container">
				<img src={banner} className={`banner ${bannerClassName}`} alt="informative-banner" />
			</div>

			<div className="wrapper-description">{description}</div>
			<div className="content-wrapper">{children}</div>
		</div>
	);
};

InformativePageWrapper.propTypes = {
	banner: PropTypes.string,
	name: PropTypes.string,
	variant: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
	description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	bannerClassName: PropTypes.string,
	nameClassName: PropTypes.string,
};

InformativePageWrapper.defaultProps = {
	image: '',
	name: '',
	variant: '',
	description: '',
	bannerClassName: '',
};

export default InformativePageWrapper;
