import { authHeader, handleResponse } from '../../../../shared/infrastructure/api/apiHandler';
import { urlSendResultCopy } from './backend-urls';

export const postSendResultCopy = (preApprovedId, data = {}) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data),
	};
	return fetch(urlSendResultCopy(preApprovedId), requestOptions).then(handleResponse);
};

export default { postSendResultCopy };
