import { authHeader, handleResponse } from './apiHandler';
import { urlGetBanks } from './backend-urls';

export const getBanksRequest = () => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};

	return fetch(urlGetBanks, requestOptions).then(handleResponse);
};

export default {};
