import { authHeader, handleResponse } from '../../../../shared/infrastructure/api/apiHandler';
import { urlGetLastPreApproved, urlPostPreApproved, urlGetCaptcha } from './backend-urls';

export const postPreApproved = (data = {}) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data),
	};
	return fetch(urlPostPreApproved, requestOptions).then(handleResponse);
};

export const getLastPreApproved = () => {
	const requestOptions = {
		method: 'Get',
		headers: authHeader(),
	};
	return fetch(urlGetLastPreApproved, requestOptions).then(handleResponse);
};

export const fetchCaptcha = () => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};
	return fetch(urlGetCaptcha, requestOptions).then(handleResponse);
};
