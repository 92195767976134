import {
	associatesInformationRoute,
	financialInformationRoute,
	personalInformationRoute,
	requestRoute,
} from '../../../domains/creditRequest/infrastructure/routing/routes';

export const STEPS = (request_id) => {
	return [
		{
			path: requestRoute,
			step: 1,
			text: 'Selecciona tu banco de preferencia',
			withExtraValidation: true,
			validation: true,
		},
		{
			path: personalInformationRoute,
			step: 2,
			text: 'Ingresa tus datos',
			withExtraValidation: true,
			validation: true,
		},
		{
			path: financialInformationRoute,
			step: 3,
			text: 'Recibe la respuesta',
			withExtraValidation: true,
			validation: true,
		},
		{
			path: associatesInformationRoute(request_id),
			step: 4,
			text: 'Datos de tu acompañante',
			withExtraValidation: true,
			validation: true,
		},
	];
};

export const STEPS_NOTIFICATION_BOX = [
	{
		step: 1,
		title: 'Paso 1',
		description: '¿Ya sabes con quién vas a solicitar tu preaprobado?',
		textButton: 'Ver más',
		buttonAction: '',
		completed: false,
		showCheckIcon: true,
	},
	{
		step: 2,
		title: 'Paso 2',
		description: '¡Queremos conocerte! Llena tus datos en el formulario',
		textButton: 'Ver más',
		buttonAction: '',
		completed: false,
		showCheckIcon: true,
	},
	{
		step: 3,
		title: 'Paso 3',
		description: 'Conoce el monto de tu crédito preaprobado',
		textButton: 'Ver más',
		buttonAction: '',
		completed: false,
		showCheckIcon: true,
	},
	{
		step: 4,
		title: 'Paso 4',
		description: '¿Estás con alguien en esto? Llena sus datos en el formulario',
		textButton: 'Ver más',
		buttonAction: '',
		completed: false,
		showCheckIcon: true,
	},
];

export const COMPLETED_STEPS_NOTIFICATION_BOX = [
	{
		step: 'in_review', // TODO definir el estado de este paso
		title: '¡Danos un momento!',
		description: '¡Estamos verificando tu solicitud con la tecnología de Vecindario Suite!',
		textButton: '',
		buttonAction: '',
		completed: true,
		showCheckIcon: false,
	},
	{
		step: 'approved',
		title: '¡Felicidades!',
		description: '¡Ya tienes el preaprobado! Ahora puedes conocer el estado de tu crédito!',
		textButton: '',
		buttonAction: '',
		completed: true,
		showCheckIcon: true,
	},
];
