import React from 'react';
import { Button } from '@vecindario/vecindario-suite-components';
import { useSelector } from 'react-redux';
import './RequestDenied.scss';
import { selectCurrentUserName } from '../../../../domains/authentication/application/selectors/user';
import { ACCESS_DENIED } from '../../../application/constants/images';
import { history } from '../../../application/helpers/history';
import InformativePageWrapper from '../../pages/InformativePageWrapper';
import { requestRoute } from '../../../../domains/creditRequest/infrastructure/routing/routes';

const RequestDenied = () => {
	const userName = useSelector(selectCurrentUserName);
	return (
		<InformativePageWrapper banner={ACCESS_DENIED} name={`${userName}, algo no va bien`} variant={'access-denied'}>
			<div className="access-denied-section">
				<p className="text">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nunc sed diam elementum. Eget duis nisi donec
					leo vitae ut. Elementum ac a cursus ultrices. Fringilla viverra et sociis ut vulputate eu velit libero. Aenean
					morbi enim nullam tincidunt sed egestas amet, elit habitant. Sem commodo at lacus dui. Luctus sit enim blandit odio
					feugiat velit. Pellentesque facilisis nulla posuere nibh orci, pharetra ac rhoncus a. Odio arcu, aliquam et,
					rhoncus mi facilisis molestie venenatis.
				</p>

				<Button
					type="submit"
					text={'Volver'}
					onClick={() => {
						history.push(requestRoute);
					}}
				/>
			</div>
		</InformativePageWrapper>
	);
};

export default RequestDenied;
