import { createSelector } from '@reduxjs/toolkit';

export const userState = (state) => state.user;

export const getCurrentUser = createSelector(userState, (user) => {
	const { currentUser } = user;
	return currentUser;
});

export const selectCurrentUserName = createSelector(userState, (user) => {
	const { currentUser } = user;
	const name = currentUser?.name || currentUser?.first_name || currentUser?.user_detail?.name || '';
	return name.split(' ')[0];
});

export const isUserLogged = createSelector(userState, (user) => {
	const { currentUser } = user;
	return !!currentUser;
});

export const selectUserGreeting = createSelector(userState, (user) => {
	const { currentUser } = user;
	const name = currentUser?.name || currentUser?.first_name || currentUser?.user_detail?.name || '';
	return name ? `Hola, ${name}` : 'Hola';
});
