import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Footer } from '@vecindario/vecindario-suite-components';
import tipsInformation from '../Components/TipCard/tipsInformation';
import TipCard from '../Components/TipCard';
import { history } from '../../../../shared/application/helpers/history';
import { requestRoute } from '../../../creditRequest/infrastructure/routing/routes';
import './Tips.scss';
import { homeRoute } from '../../../../shared/infrastructure/routing/routes';
import { getBankCredifamilia } from '../../../../shared/application/selectors/adminLayout';
import { getBanksFetch } from '../../../../shared/application/slices/adminLayout';
import {
	getValueFromLocalStorage,
	resetCache,
	setValueToLocalStorage,
} from '../../../../shared/application/helpers/common-functions';
import { PROJECT_SLUG } from '../../../../shared/application/constants/pre-approved-data';
import { homeBankRoute } from '../../../home/infrastructure/routing/routes';

const Tips = () => {
	const tips = 3;
	const [currentTip, setCurrentTip] = useState(0);
	const dispatch = useDispatch();
	const { slug } = useParams();

	if (slug) {
		setValueToLocalStorage(PROJECT_SLUG, slug);
	}

	const projectSlug = getValueFromLocalStorage(PROJECT_SLUG);
	const nextTip = () => (currentTip + 1 >= tips ? history.push(requestRoute) : setCurrentTip(currentTip + 1));

	const backTip = () => {
		if (currentTip - 1 < 0) {
			if (projectSlug) {
				history.push(homeBankRoute(projectSlug));
			} else {
				history.push(homeRoute);
			}
		}
		setCurrentTip(currentTip - 1);
	};

	const infoCredifamilia = useSelector(getBankCredifamilia);

	useEffect(() => {
		dispatch(getBanksFetch());
		resetCache();
	}, [dispatch]);

	return (
		<section className="tips">
			{tipsInformation.map((info, key) => {
				return (
					currentTip === key && (
						<TipCard
							key={key}
							infoCredifamilia={infoCredifamilia}
							banner={info.banner}
							subTitle={info.subText}
							text={info.description}
							currentTipStep={currentTip + 1}
							totalStep={tips}
							onClick={backTip}
						>
							<Button
								text={info.buttonText}
								onClick={() => {
									nextTip();
								}}
							/>
						</TipCard>
					)
				);
			})}
			<Footer theme="light" />
		</section>
	);
};

export default Tips;
