import { helpers } from '@vecindario/suite-library';
import {
	CAPTCHA_TRANSACTION_ID,
	FORM_ERRORS,
	PRE_APPROVED_RESULT_KEY,
	REQUEST_STEP_KEY,
} from '../../../domains/creditRequest/application/constants/local-storage';

export const { removeValueFromLocalStorage, setValueToLocalStorage, getValueFromLocalStorage } =
	helpers.commonFunctions;

export const numberWithDots = (x) => {
	if (x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
	}
	return x !== 0 ? '' : '0';
};
export const replaceString = (value, replace, replaced) => {
	return value.toString().split(`${replace}`).join(replaced);
};

export const capitalizeFirstLetter = (string) =>
	string ? string.charAt(0).toUpperCase() + string.toLowerCase().slice(1) : string;

export const removeCharactersToFormatCurrency = (value) =>
	value
		.toString()
		.replace(/\s/g, '')
		.replace(/[^\w\s]/gi, '');

export const currencyCopFormat = (number) => {
	const currencyValue = removeCharactersToFormatCurrency(number);
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 0,
	});

	return `${replaceString(formatter.format(currencyValue), ',', '.')}`;
};

export const removeCopFormat = (number) => {
	number = replaceString(number, '$', '');
	return replaceString(number, '.', '');
};

export const formatCurrency = (value) => (value ? `$ ${numberWithDots(value)}` : `$ `);

export const formatMillions = (value) =>
	value ? `$ ${numberWithDots(value.toString().replace(/\B(?=(\d{6})+(?!\d))/g, '’'))}` : '$ 0';

export const importResource = (options) => {
	const firstScript = document.getElementsByTagName('script')[0];
	if (options.id && document.getElementById(options.id)) return;
	if (options.style) {
		const style = document.createElement('link');
		style.id = options.id;
		style.rel = 'stylesheet';
		style.href = options.style;
		style.async = !!options.async;
		if (firstScript) {
			firstScript.parentNode.insertBefore(style, firstScript);
		} else {
			document.querySelector('head').appendChild(style);
		}
	} else {
		const script = document.createElement('script');
		script.src = options.script;
		script.id = options.id;
		script.type = 'text/javascript';
		script.defer = true;
		script.async = !!options.async;
		if (firstScript) {
			firstScript.parentNode.insertBefore(script, firstScript);
		} else {
			document.querySelector('head').appendChild(script);
		}
	}
};

export const getErrorMessage = (field = '', errors = {}) => errors[field]?.message;

export const isEmptyObject = (obj = {}) => obj && Object.keys(obj).length === 0;

export const removeEmptyFields = (obj = {}) => {
	return Object.fromEntries(
		Object.entries(obj).filter(([key, value]) => {
			if (value === 0) return true;
			return typeof value !== 'boolean' ? !!value : true;
		}),
	);
};

export const resetCache = () => {
	localStorage.removeItem(PRE_APPROVED_RESULT_KEY);
	localStorage.setItem(REQUEST_STEP_KEY, '0');
	localStorage.removeItem(CAPTCHA_TRANSACTION_ID);
	localStorage.removeItem(FORM_ERRORS);
};

export const formatName = (name) => (name.length > 0 ? `${name},` : '');

export const validateEmailDomain = (email) => {
	return !email.split('.').includes('con');
};
