import * as yup from 'yup';
import { personalBankInformationFields } from '../constants/formInformation';
import {
	EMPTY_FIELD,
	INVALID_EMAIL_FORMAT,
	INVALID_PHONE_FORMAT,
	MESSAGE_ERROR_DOMAIN,
} from '../../../../shared/application/constants/messages/error-messages';
import { validateEmailDomain } from '../../../../shared/application/helpers/common-functions';

export const bankInformation = {};
bankInformation[personalBankInformationFields.NAME] = yup.string().required(EMPTY_FIELD);
bankInformation[personalBankInformationFields.LASTNAME] = yup.string().required(EMPTY_FIELD);
bankInformation[personalBankInformationFields.CELLPHONE] = yup.string().required(EMPTY_FIELD);
bankInformation[personalBankInformationFields.EMAIL] = yup
	.string()
	.email(INVALID_EMAIL_FORMAT)
	.test('not-con-domain', MESSAGE_ERROR_DOMAIN, (value) => validateEmailDomain(value))
	.required(EMPTY_FIELD);
bankInformation[personalBankInformationFields.PHONE] = yup.string().min(10, INVALID_PHONE_FORMAT);
bankInformation[personalBankInformationFields.ADDRESS] = yup.string().required(EMPTY_FIELD);
bankInformation[personalBankInformationFields.AMOUNT] = yup.number().required(EMPTY_FIELD);
bankInformation[personalBankInformationFields.FRONTDOCUMENT] = yup.string();
bankInformation[personalBankInformationFields.BACKDOCUMENT] = yup.string();
bankInformation[personalBankInformationFields.WORKINGLETTER] = yup.string();

export const bankInformationSchema = yup.object().shape(bankInformation);
