export const personalBankInformationFields = {
	NAME: 'first_name',
	LASTNAME: 'last_name',
	CELLPHONE: 'mobile',
	EMAIL: 'email',
	PHONE: 'phone',
	ADDRESS: 'address',
	AMOUNT: 'amountCredit',
	SLUG: 'slug_bank',
	WORKINGLETTER: 'working_letter',
	FRONTDOCUMENT: 'front_document_identification',
	BACKDOCUMENT: 'back_document_identification',
};

export const personalBankDefaultValues = {
	[personalBankInformationFields.NAME]: '',
	[personalBankInformationFields.LASTNAME]: '',
	[personalBankInformationFields.CELLPHONE]: '',
	[personalBankInformationFields.EMAIL]: '',
	[personalBankInformationFields.PHONE]: '',
	[personalBankInformationFields.ADDRESS]: '',
	[personalBankInformationFields.AMOUNT]: '',
	[personalBankInformationFields.SLUG]: '',
	[personalBankInformationFields.WORKINGLETTER]: '',
	[personalBankInformationFields.FRONTDOCUMENT]: '',
	[personalBankInformationFields.BACKDOCUMENT]: '',
};
