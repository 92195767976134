import React, { useEffect, useMemo, useState, useCallback } from 'react';
import {
	Input,
	DatePicker,
	Button,
	Select,
	PhoneInput,
	MessageError,
	Checkbox,
	TextTag,
	NumberFormat,
} from '@vecindario/vecindario-suite-components';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
	personalInformationFields,
	credifamiliaFields,
	companionDefaultValues,
} from '../../../application/constants/finantialInformation';
import { companionInformationSchema } from '../../../application/schemas/companion-information';
import {
	DOCUMENT_TYPES,
	EMPLOYMENT,
	EMPLOYMENT_EMPLOYEE,
	EMPLOYMENT_INDEPENDENT,
	SERVICE_PROVIDER,
	YES_NO_OPTION,
} from '../../../../../shared/application/constants/form-options';
import { cleanRequestErrors, updateFormErrors, updateSuccessfulSave } from '../../../application/slices/credit-request';
import {
	getErrorMessage,
	removeEmptyFields,
	isEmptyObject,
	removeCharactersToFormatCurrency,
} from '../../../../../shared/application/helpers/common-functions';
import {
	selectCities,
	selectPreApprovedDetail,
	selectDataLists,
	ownerInformationData,
	selectCompanionErrors,
	selectCaptchaError,
	selectUserAlreadyExistError,
	selectPreApprovedResponse,
} from '../../../application/selectors/credit-request';
import './CompanionInformationSection.scss';
import { saveUserDetails } from '../../../infrastructure/api/associates-information';
import { SERVER_SIDE_ERROR } from '../../../../../shared/application/constants/messages/error-messages';
import { history } from '../../../../../shared/application/helpers/history';
import { associatesInformationRoute, checkingRequestRoute } from '../../../infrastructure/routing/routes';
import ParentsAmountModal from '../ParentsAmountModal';
import CaptchaSection from '../CaptchaSection';
import Spinner from '../../../../../shared/presentation/components/utils/Spinner';
import { CAPTCHA_TRANSACTION_ID } from '../../../application/constants/local-storage';
import { isUserLogged } from '../../../../authentication/application/selectors/user';
import {
	dataAuthorizationRoute,
	dataProcessingPoliciesRoute,
} from '../../../../../shared/infrastructure/routing/routes';

const CompanionInformationSection = ({ partnerId, userData }) => {
	const [formError, setFormError] = useState('');
	const [modalPartnerAmount, setModalPartnerAmount] = useState(false);
	const [modalPartnerAmountText, setModalPartnerAmountText] = useState('');
	const [loader, setLoader] = useState(false);
	const [showMonthlyIncome, setShowMonthlyIncome] = useState(true);
	const dispatch = useDispatch();
	const cities = useSelector(selectCities);
	const dataLists = useSelector(selectDataLists);
	const preApproved = useSelector(selectPreApprovedDetail);
	const ownerInformation = useSelector(ownerInformationData);
	const companionErrors = useSelector(selectCompanionErrors);
	const validationErrors = useMemo(() => companionErrors[partnerId] ?? {}, [companionErrors, partnerId]);
	const captchaError = useSelector(selectCaptchaError);
	const [reloadCaptcha, setReloadCaptcha] = useState(false);
	const alreadyExistError = useSelector(selectUserAlreadyExistError);
	const hasUser = useSelector(isUserLogged);

	const preApprovedResponse = useSelector(selectPreApprovedResponse);
	const currentPreApproved = preApprovedResponse || preApproved;

	const {
		register,
		formState: { errors, isValid },
		handleSubmit,
		setValue,
		getValues,
		reset,
		watch,
		setError,
		control,
	} = useForm({
		resolver: yupResolver(companionInformationSchema),
		mode: 'onChange',
		defaultValues: {},
		reValidateMode: 'onChange',
	});

	const getPartnerIndex = useCallback(() => {
		const { partners } = currentPreApproved;
		if (partners) {
			const index = partners.findIndex((partner) => partner === partnerId);
			return index + 1;
		}
		return null;
	}, [partnerId, currentPreApproved]);

	useEffect(() => {
		const extraInformation = removeEmptyFields(userData.extra_information);
		const userSnapshotBudget = removeEmptyFields(userData.user_snapshot_budget);
		const details = removeEmptyFields({ ...userData, extra_information: null, id: null, user_snapshot_budget: null });
		const initialValues = { ...details, ...extraInformation, ...userSnapshotBudget };
		const resultUserEmpty = isEmptyObject(initialValues);
		const completeData = {
			...initialValues,
			[personalInformationFields.CENTRAL_CONSULTATION]: !resultUserEmpty,
			[personalInformationFields.DATA_TREATMENT]: !resultUserEmpty,
			[personalInformationFields.DATA_AUTHORIZATION]: !resultUserEmpty,
		};

		if (resultUserEmpty) reset();

		Object.keys(completeData).forEach((key) => {
			setValue(key, completeData[key]);
		});
	}, [setValue, userData, currentPreApproved, dispatch, reset]);

	useEffect(() => {
		Object.keys(validationErrors).forEach((key) => setError(key, { type: 'manual', message: validationErrors[key] }));
	}, [setError, validationErrors]);

	const onChangeData = (data, inputId) => {
		setValue(inputId, data, { shouldValidate: true, shouldDirty: true });
	};

	const handleButtonMessage = () => {
		const buttonText = getPartnerIndex() === currentPreApproved?.partners_amount ? 'Solicitar preaprobado' : 'Siguiente';
		return buttonText;
	};

	const showCaptcha = useMemo(
		() =>
			((ownerInformation?.job === EMPLOYMENT_EMPLOYEE &&
				ownerInformation?.extra_information?.activity_type === EMPLOYMENT_EMPLOYEE) ||
				(ownerInformation?.job === EMPLOYMENT_INDEPENDENT &&
					ownerInformation?.extra_information?.activity_type === SERVICE_PROVIDER)) &&
			getPartnerIndex() === currentPreApproved?.partners_amount &&
			!loader &&
			(!alreadyExistError || !captchaError),
		[ownerInformation, currentPreApproved, getPartnerIndex, loader, alreadyExistError, captchaError],
	);

	const cleanWithCurrencyValue = (data) => {
		let { monthly_income } = data;
		if (monthly_income) monthly_income = removeCharactersToFormatCurrency(monthly_income);

		return { ...data, monthly_income };
	};

	const onSubmit = (data) => {
		setFormError('');
		const captchaTransactionId = localStorage.getItem(CAPTCHA_TRANSACTION_ID);

		if (showCaptcha && watch()[credifamiliaFields.CAPTCHA] === '') {
			setFormError('*Debes diligenciar el código para poder continuar');
			return;
		}

		const values = cleanWithCurrencyValue(data);
		setReloadCaptcha(false);
		setLoader(true);
		dispatch(cleanRequestErrors());
		saveUserDetails(currentPreApproved.id, partnerId, {
			...removeEmptyFields(values),
			step_to_complete: 4,
			[personalInformationFields.ACCEPTED_CONDITIONS_DATE]: new Date().toISOString(),
			[personalInformationFields.DATA_AUTHORIZATION]: new Date().toISOString(),
			transaction_id_captcha: captchaTransactionId,
		})
			.then(() => {
				reset(companionDefaultValues);
				if (getPartnerIndex() === currentPreApproved?.partners_amount) {
					history.replace(checkingRequestRoute);
				} else {
					const partnerLabel = getPartnerIndex() + 1 === 1 ? 'primera' : 'segunda';
					setModalPartnerAmountText(`Compártenos la información de la ${partnerLabel} persona.`);
					setModalPartnerAmount(true);
				}
			})
			.catch((err) => {
				setFormError(err?.error || SERVER_SIDE_ERROR);
				dispatch(updateFormErrors(err));
			})
			.finally(() => setLoader(false));
	};

	const onTempSave = () => {
		const values = cleanWithCurrencyValue(getValues());
		setFormError('');
		saveUserDetails(currentPreApproved.id, partnerId, { ...removeEmptyFields(values) })
			.then(() => {
				dispatch(updateSuccessfulSave(true));
			})
			.catch((err) => setFormError(err?.message || SERVER_SIDE_ERROR));
	};

	const onSelectBringsIncoming = (data, inputId) => {
		setShowMonthlyIncome(data);
		onChangeData(data, inputId);
	};

	const disabledButton = () => {
		const values = getValues();
		return !isEmptyObject(values);
	};

	const handleDocumentNumber = (value) => {
		const lastValue = value.substring(0, value.length - 1);
		const maxLength = 10;
		setValue(personalInformationFields.NUMBER_ID, value.length <= maxLength ? value : lastValue);
	};

	const viewOtherPartner = () => {
		reset(companionDefaultValues);
		const partnersRoute = associatesInformationRoute(currentPreApproved?.partners[1]);
		history.push(partnersRoute);
	};

	const showButtonNext =
		currentPreApproved.disabled &&
		currentPreApproved?.partners_amount > 0 &&
		getPartnerIndex() !== currentPreApproved?.partners_amount;

	const onParentModalClose = () => {
		setModalPartnerAmount(false);
		const partnersRoute = associatesInformationRoute(currentPreApproved?.partners[1]);
		history.push(partnersRoute);
	};

	return (
		<>
			<form className="form-group" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
				<div className="companion-from-group">
					<div className="form-control">
						<Input
							label="Nombre"
							placeholder="Nombre"
							{...register(personalInformationFields.NAME)}
							error={getErrorMessage(personalInformationFields.NAME, errors)}
						/>
					</div>
					<div className="form-control">
						<Input
							label="Apellidos"
							placeholder="Apellidos"
							{...register(personalInformationFields.LASTNAME)}
							error={getErrorMessage(personalInformationFields.LASTNAME, errors)}
						/>
					</div>
					<div className="form-control">
						<Select
							id="typeId"
							defaultOptionValue={watch()[personalInformationFields.TYPE_ID]}
							options={dataLists?.tipoDocumento || DOCUMENT_TYPES}
							onSelectItem={(item) => onChangeData(item?.value, personalInformationFields.TYPE_ID)}
							label="Tipo de documento de identidad"
							error={getErrorMessage(personalInformationFields.TYPE_ID, errors)}
						/>
					</div>
					<div className="form-control">
						<Input
							label="Número de documento"
							type="number"
							placeholder="Número de documento"
							{...register(personalInformationFields.NUMBER_ID)}
							onChange={(e) => handleDocumentNumber(e.target.value)}
							error={getErrorMessage(personalInformationFields.NUMBER_ID, errors)}
						/>
					</div>
					<div className="form-control">
						<Input
							label="Correo electrónico"
							placeholder="Correo electrónico"
							{...register(personalInformationFields.EMAIL)}
							error={getErrorMessage(personalInformationFields.EMAIL, errors)}
						/>
					</div>
					<div className="form-control">
						<PhoneInput
							label="Número de celular"
							placeholder="Número de celular"
							onlyCountries={['co']}
							{...register(personalInformationFields.PHONE_NUMBER)}
							onChange={(item) => onChangeData(item.target.value, personalInformationFields.PHONE_NUMBER)}
							error={errors[personalInformationFields.PHONE_NUMBER]?.message}
							value={watch()[personalInformationFields.PHONE_NUMBER]}
						/>
					</div>
					<div className="form-control">
						<label className="form-label">Fecha de nacimiento</label>
						<DatePicker
							onDayChange={(item) => onChangeData(item, personalInformationFields.BIRTH_DATE)}
							error={getErrorMessage(personalInformationFields.BIRTH_DATE, errors)}
							value={watch()[personalInformationFields.BIRTH_DATE]}
							disabledDays={{ after: new Date() }}
						/>
					</div>
					<div className="form-control">
						<Select
							options={dataLists?.sexo}
							label="Sexo"
							id="sex"
							error={getErrorMessage(personalInformationFields.GENDER, errors)}
							onSelectItem={(item) => onChangeData(item?.value, personalInformationFields.GENDER)}
							defaultOptionValue={watch()[personalInformationFields.GENDER]}
						/>
					</div>
					<div className="form-control">
						<Select
							showFinder={true}
							defaultOptionValue={watch()[personalInformationFields.CITY]}
							id="city-residence"
							options={cities}
							onSelectItem={(item) => onChangeData(item?.value, personalInformationFields.CITY)}
							label="Ciudad Residencia"
							error={getErrorMessage(personalInformationFields.CITY, errors)}
						/>
					</div>
					<div className="form-control">
						<Select
							id="employment"
							defaultOptionValue={watch()[personalInformationFields.EMPLOYMENT]}
							options={dataLists?.ocupacionCodeudor || EMPLOYMENT}
							onSelectItem={(item) => onChangeData(item?.value, personalInformationFields.EMPLOYMENT)}
							label="Ocupación"
							error={getErrorMessage(personalInformationFields.EMPLOYMENT, errors)}
						/>
					</div>
					<div className="form-control">
						<Select
							defaultOptionValue={watch()[personalInformationFields.BRINGS_INCOME]}
							id="brings_income"
							options={YES_NO_OPTION}
							label="¿Aporta ingresos?"
							onSelectItem={(item) => onSelectBringsIncoming(item?.value, personalInformationFields.BRINGS_INCOME)}
							error={getErrorMessage(personalInformationFields.BRINGS_INCOME, errors)}
						/>
					</div>
					<div className="form-control">
						<TextTag variant="-body-sm" className="label">
							Ingresos mensuales
						</TextTag>
						<Controller
							control={control}
							name={personalInformationFields.MONTHLY_INCOME}
							render={({ field: { onChange, value, name }, fieldState: { error } }) => (
								<NumberFormat
									disabled={!showMonthlyIncome}
									name={name}
									placeholder="Ingresos mensuales"
									value={value}
									onChange={onChange}
									error={error?.message}
									showIconError={false}
								/>
							)}
						/>
					</div>
					<div className="form-control">
						<Input
							label="Parentesco o relación"
							placeholder="Parentesco o relación"
							{...register(personalInformationFields.RELATIONSHIP)}
							error={getErrorMessage(personalInformationFields.RELATIONSHIP, errors)}
						/>
					</div>

					<CaptchaSection
						show={showCaptcha}
						register={register}
						errors={errors}
						setValue={setValue}
						reloadCaptcha={reloadCaptcha}
					/>

					<div className="form-control checkbox">
						<Checkbox
							className="check-box-form"
							label="Acepto ser consultado ante centrales de riesgo para entender mejor mi perfil y determinar el banco que mas se ajuste a mis características."
							labelPosition="right"
							defaultChecked={watch()[personalInformationFields.CENTRAL_CONSULTATION]}
							name={personalInformationFields.CENTRAL_CONSULTATION}
							{...register(personalInformationFields.CENTRAL_CONSULTATION)}
							error={errors[personalInformationFields.CENTRAL_CONSULTATION]?.message}
						/>
					</div>
					<div className="data-treatment">
						<Checkbox
							className="check-box-form"
							label={
								<p>
									Acepto las{' '}
									<a href={dataProcessingPoliciesRoute} target="_blank" rel="noreferrer">
										políticas de Tratamiento de Datos.
									</a>
								</p>
							}
							labelPosition="right"
							defaultChecked={watch()[personalInformationFields.DATA_TREATMENT]}
							name={personalInformationFields.DATA_TREATMENT}
							{...register(personalInformationFields.DATA_TREATMENT)}
							error={errors[personalInformationFields.DATA_TREATMENT]?.message}
						/>
					</div>
					<div className="data-treatment">
						<Checkbox
							className="check-box-form"
							labelPosition="right"
							name={personalInformationFields.DATA_AUTHORIZATION}
							label={
								<p>
									Acepto la{' '}
									<a href={dataAuthorizationRoute} target="_blank" rel="noreferrer">
										autorización para administración y consulta de datos.
									</a>
								</p>
							}
							defaultChecked={watch()[personalInformationFields.DATA_AUTHORIZATION]}
							{...register(personalInformationFields.DATA_AUTHORIZATION)}
							error={errors[personalInformationFields.DATA_AUTHORIZATION]?.message}
						/>
					</div>
				</div>

				{formError && <MessageError message={formError} className="form-error" />}

				<div className="form-action">
					{hasUser && (
						<Button
							variant="bordered"
							disabled={!disabledButton() || currentPreApproved.disabled}
							onClick={onTempSave}
							type="button"
							text="Guardar"
						/>
					)}
					{!currentPreApproved.disabled && (
						<Button disabled={!isValid || currentPreApproved.disabled} type="submit">
							{loader ? <Spinner /> : handleButtonMessage()}
						</Button>
					)}

					{showButtonNext && <Button type="button" onClick={() => viewOtherPartner()} text="Siguiente" />}
				</div>
			</form>
			<ParentsAmountModal open={modalPartnerAmount} onClose={onParentModalClose} text={modalPartnerAmountText} />
		</>
	);
};

CompanionInformationSection.propTypes = {
	partnerId: PropTypes.string,
	userData: PropTypes.object,
};

export default CompanionInformationSection;
