import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Scrolling } from '@vecindario/vecindario-suite-components';
import InformativeCard from './InformativeCard';
import descriptions from './descriptions';
import { homeBankRoute, homeRoute } from '../../../../home/infrastructure/routing/routes';
import './InformativeSection.scss';
import { getValueFromLocalStorage } from '../../../../../shared/application/helpers/common-functions';
import { PROJECT_SLUG } from '../../../../../shared/application/constants/pre-approved-data';

const buttonTexts = ['¿Qué es un preaprobado?', '¿Por qué es importante?'];

const ScrollButton = ({ text, onClick, selected = false }) => {
	return (
		<Button
			text={text}
			variant={selected ? 'default' : 'bordered'}
			onClick={onClick}
			className={`informative-scroll-btn ${selected ? 'selected' : ''}`}
		/>
	);
};

ScrollButton.propTypes = {
	text: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	selected: PropTypes.bool,
};

const InformativeSection = () => {
	const [currentCard, setCurrentCard] = useState(0);
	const [swipeDirection, setSwipeDirection] = useState('swipe-left');
	const projectSlug = getValueFromLocalStorage(PROJECT_SLUG);

	const handleChange = (index) => {
		setCurrentCard(index);
		currentCard > index ? setSwipeDirection('swipe-right') : setSwipeDirection('swipe-left');
	};

	return (
		<section className="informative-section">
			<Scrolling>
				{buttonTexts.map((text, index) => (
					<ScrollButton
						key={`btn-${index}`}
						text={text}
						onClick={() => handleChange(index)}
						selected={currentCard === index}
					/>
				))}
			</Scrolling>
			{descriptions.map(({ banner, mainText, description }, index) => {
				return (
					currentCard === index && (
						<InformativeCard
							key={`info-card-${index}`}
							banner={banner}
							mainText={mainText}
							description={description}
							className={swipeDirection}
						/>
					)
				);
			})}

			<Link
				className="suite-button default informative-section-cta"
				to={projectSlug ? homeBankRoute(projectSlug) : homeRoute}
			>
				Solicitar preaprobado
			</Link>
		</section>
	);
};

export default InformativeSection;
