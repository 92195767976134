import * as yup from 'yup';
import { copyEmailFields } from '../constants/copy-email';
import {
	EMPTY_FIELD,
	INVALID_EMAIL_FORMAT,
	MESSAGE_ERROR_DOMAIN,
} from '../../../../shared/application/constants/messages/error-messages';
import { validateEmailDomain } from '../../../../shared/application/helpers/common-functions';

const copyEmailSchema = yup.object().shape({
	[copyEmailFields.EMAIL]: yup
		.string()
		.email(INVALID_EMAIL_FORMAT)
		.test('not-con-domain', MESSAGE_ERROR_DOMAIN, (value) => validateEmailDomain(value))
		.required(EMPTY_FIELD),
});

export default copyEmailSchema;
