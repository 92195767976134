import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { PERSONAL_INFORMATION_BANNER } from '../../../../../shared/application/constants/images';
import {
	isPreApprovedComplete,
	selectRequestSteps,
	selectCurrentStep,
} from '../../../application/selectors/credit-request';
import { selectCurrentUserName } from '../../../../authentication/application/selectors/user';
import { setAlertData } from '../../../../../shared/application/slices/adminLayout';
import InformativePageWrapper from '../../../../../shared/presentation/pages/InformativePageWrapper';
import PersonalForm from '../../components/personalInformation/PersonalForm';
import ProgressBarComponent from '../../../../../shared/presentation/components/utils/ProgressBarComponent';
import { formatName } from '../../../../../shared/application/helpers/common-functions';
import {
	COMPLETE_PROGRESS_COMMENT,
	COMPLETE_PROGRESS_TEXT,
	CLOSE_PRE_APPROVED_MESSAGE,
	SAVE_PROGRESS_MESSAGE,
} from '../../../../../shared/application/constants/messages/info-message';
import './PersonalInformation.scss';

const PersonalInformation = () => {
	const dispatch = useDispatch();
	const total_steps = useSelector(selectRequestSteps);
	const userName = useSelector(selectCurrentUserName);
	const isComplete = useSelector(isPreApprovedComplete);
	const currentStep = useSelector(selectCurrentStep);

	useEffect(() => {
		if (currentStep === 2) {
			dispatch(
				setAlertData({
					text: SAVE_PROGRESS_MESSAGE,
					type: 'information',
					icon: 'info-icon',
					dismissTime: 5000,
				}),
			);
		}

		return () => {
			dispatch(
				setAlertData({
					text: '',
					type: '',
					icon: '',
					dismissTime: 0,
				}),
			);
		};
	}, [dispatch, currentStep]);

	return (
		<>
			<Helmet>
				<title>Preaprobados | Información personal | Vecindario </title>
				<meta name="description" content="Solicitud de preaprobado online para crédito hipotecario." />
				<meta property="og:type" content="website" />
				<meta property="og:title" content="Preaprobados | Información personal | Vecindario" />
				<meta property="og:description" content="Solicitud de preaprobado online para crédito hipotecario." />
				<meta property="og:image" content="https://fiducia-suite.s3.us-east-2.amazonaws.com/vecindario-logo.svg" />
			</Helmet>
			<InformativePageWrapper
				name={formatName(userName)}
				banner={PERSONAL_INFORMATION_BANNER}
				nameClassName="title"
				variant="personal-information"
				description={
					<>
						<p className="main-text">Déjanos conocerte un poco más</p>
						<p>¡Queremos hacerlo todo más sencillo para ti! Para empezar compártenos la siguiente información</p>
					</>
				}
			>
				<div className="page-content">
					<ProgressBarComponent
						title=""
						inlineText="PASO"
						comment={CLOSE_PRE_APPROVED_MESSAGE}
						showActualStep={true}
						actualStep={2}
						totalSteps={total_steps}
						isComplete={isComplete}
						completeText={COMPLETE_PROGRESS_TEXT}
						completeComment={COMPLETE_PROGRESS_COMMENT}
					/>
					<PersonalForm />
				</div>
			</InformativePageWrapper>
		</>
	);
};

export default PersonalInformation;
