import React from 'react';
import './DataProcessingPolicy.scss';

const DataProcessingPolicy = () => {
	return (
		<section className="data-processing-policy">
			<div className="text">
				<p className="paragraph">
					<strong className="paragraph-subtitles">POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES</strong>
					La presente política de tratamiento de datos personales (en adelante la “Política”) fue elaborada con base en los
					artículos 15 y 20 de la Constitución Política de Colombia, en la Ley 1581 de 2012, Decreto 1377 de 2013. Su objeto
					es poner en conocimiento el tratamiento que Vecindario LATAM S.A.S., sociedad colombiana con Nit 901.273.319 - 3,
					domiciliada en Medellín (en adelante “Vecindario”), les da a los datos personales y a la información que posee en
					sus bases de datos, los derechos que le asisten a los titulares de estos datos personales e información y los
					mecanismos con que cuentan para ejercerlos.
				</p>

				<p className="paragraph">
					<strong className="paragraph-subtitles">DEFINICIONES</strong>
					Para efectos de la presente Política, las siguientes palabras tendrán los significados que se mencionan a
					continuación:
					<li>
						{' '}
						“Autorización”: Consentimiento previo, expreso e informado del titular para llevar a cabo el tratamiento de datos
						personales.{' '}
					</li>
					<li>
						{' '}
						“Autorización”: Consentimiento previo, expreso e informado del titular para llevar a cabo el tratamiento de datos
						personales.{' '}
					</li>
					<li>“Base de Datos”: Conjunto organizado de datos personales que sea objeto de tratamiento.</li>
					<li>
						“Dato Personal”: Cualquier información vinculada o que pueda asociarse a una o varias personas naturales
						determinadas o determinables; sin perjuicio de lo anterior, Vecindario tratará los datos de las personas jurídicas
						bajo los mismos lineamientos y políticas de seguridad con que trata los Datos Personales.
					</li>
					<li>
						{' '}
						“Dato Público”: Es el dato que no sea semiprivado, privado o sensible. Son considerados Datos Públicos, entre
						otros, los datos relativos al estado civil de las personas, a su profesión u oficio y a su calidad de comerciante
						o de servidor público. Por su naturaleza, los Datos Públicos pueden estar contenidos, entre otros, en registros
						públicos, documentos públicos, gacetas y boletines oficiales y sentencias judiciales debidamente ejecutoriadas que
						no estén sometidas a reserva.
					</li>
					<li>
						{' '}
						“Datos Sensibles”: Se entiende por Datos Sensibles aquellos que afectan la intimidad del titular o cuyo uso
						indebido puede generar su discriminación, tales como aquellos que revelen el origen racial o étnico, la
						orientación política, las convicciones religiosas o filosóficas, la pertenencia a sindicatos, organizaciones
						sociales, de derechos humanos o que promueva intereses de cualquier partido político o que garanticen los derechos
						y garantías de partidos políticos de oposición, así como los datos relativos a la salud, a la vida sexual, y los
						datos biométricos.
					</li>
					<li>
						{' '}
						“Encargado del Tratamiento”: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con
						otros, realice el tratamiento de Datos Personales por cuenta del responsable del tratamiento.
					</li>
					<li>
						{' '}
						“Responsable del Tratamiento”: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con
						otros, decida sobre la Base de Datos y/o el tratamiento de los Datos Personales.
					</li>
					<li> “Titular”: Persona natural cuyos Datos Personales sean objeto de tratamiento.</li>
					<li>
						{' '}
						“Transferencia”: La Transferencia de datos tiene lugar cuando el Responsable y/o Encargado del Tratamiento de
						Datos Personales, ubicado en Colombia, envía información o los Datos Personales a un receptor, que a su vez es
						Responsable del Tratamiento y se encuentra dentro o fuera del país.
					</li>
					<li>
						{' '}
						“Transmisión”: Tratamiento de Datos Personales que implica la comunicación de los mismos dentro o fuera del
						territorio de la República de Colombia cuando tengan por objeto la realización de un tratamiento por el Encargado
						por cuenta del Responsable.
					</li>
					<li>
						{' '}
						“Tratamiento”: Cualquier operación o conjunto de operaciones sobre Datos Personales, tales como la recolección,
						almacenamiento, uso, circulación o supresión.
					</li>
					<li>
						{' '}
						“Aviso de privacidad”: Comunicación verbal o escrita generada por el Responsable del Tratamiento, dirigida al
						Titular para el Tratamiento de sus Datos Personales, mediante la cual se le informa acerca de la existencia de las
						políticas de Tratamiento de información que le serán aplicables, la forma de acceder a las mismas y las
						finalidades del Tratamiento que se pretende dar a los Datos Personales.
					</li>
				</p>

				<p className="paragraph">
					<strong className="paragraph-subtitles">TRATAMIENTO DE LOS DATOS PERSONALES</strong>
					Vecindario recolecta, almacena, usa, procesa, suprime y circula Datos Personales entre los cuales se encuentran,
					pero sin limitarse a, nombres, apellidos, teléfonos, domicilios, direcciones de contacto, correos electrónicos,
					documentos de identidad, datos laborales, información financiera, gustos e intereses, entre otros, con ocasión del
					desarrollo de su objeto social, el cual se circunscribe, entre otras actividades a: la promoción de proyectos
					inmobiliarios de sus aliados; la ordenación de pauta digital en diferentes canales, para la promoción de los
					diferentes productos y servicios de sus aliados; el posicionamiento de marca de los diferentes aliados y marcas de
					la compañía en las diferentes plataformas sociales; la generación de contenidos gráficos y propuestas creativas
					para la promoción de los diferentes productos y servicios de los aliados y marcas de la compañía; el desarrollo de
					soluciones web en frontend y backend para sus aliados y marcas de la compañía enfocadas en la promoción de sus
					productos y servicios, así como la optimización y automatización de procesos, la generación de contenidos
					audiovisuales para los diferentes objetivos de los aliados y marcas de la compañía; la promoción y divulgación de
					diversos beneficios de los aliados y marcas de la compañía; impartir y divulgar conocimiento de diferentes sectores
					a través de cursos virtuales, presenciales o de contenidos; y/o debido a una relación laboral, comercial o civil
					con los distintos Titulares de los Datos Personales. En virtud de lo anterior, los Datos Personales son empleados,
					de forma general, para las siguientes finalidades:
					<li> Contactar a los Titulares en virtud de la relación comercial, civil o laboral existente.</li>
					<li>
						{' '}
						Identificar y mantener un registro y control de los proveedores, clientes, accionistas, usuarios y empleados de
						Vecindario.
					</li>
					<li>
						{' '}
						Pagar honorarios, remuneraciones, dividendos o salarios surgidos en virtud de las relaciones comerciales, civiles
						o laborales existentes con los Titulares.
					</li>
					<li>
						{' '}
						Cumplir con la normativa vigente en materia laboral y de seguridad social, civil, comercial, administrativa, penal
						y demás existente, especialmente aquella relacionada con el archivo y custodia de la información.
					</li>
					<li>
						{' '}
						Gestionar toda la información necesaria para el cumplimiento de las obligaciones tributarias y de registros
						comerciales, corporativos y contables de Vecindario.
					</li>
					<li> Cumplir con las obligaciones de prevención de lavados de activos y financiación de terrorismo.</li>
					<li> Enviar comunicaciones referentes a la relación comercial, civil o laboral existente.</li>
					<li> Enviar mensajes con fines comerciales, publicitarios, educativos y/o de atención a los Titulares.</li>
					<li> Registrar y almacenar la información de los Titulares en las Bases de Datos de Vecindario.</li>
					<li> Proveer los Servicios ofrecidos en el sitio web de Vecindario y de sus marcas aliadas.</li>
					<li> Contestar las solicitudes, peticiones, reclamaciones y quejas de los Titulares.</li>
					<li>
						{' '}
						Evaluar el cumplimiento de las obligaciones derivadas de las relaciones comerciales, civiles o laborales
						existentes con los Titulares o exigir el cumplimiento de las mismas.
					</li>
					<li>
						{' '}
						Efectuar y consultar reportes ante las distintas autoridades administrativas de control y vigilancia, entidades de
						reporte de comportamiento comercial y financiero y autoridades de policía o autoridades judiciales.
					</li>
					<li>
						{' '}
						Realizar trámites administrativos internos de Vecindario tales como reportes contables, auditorías y facturación.
					</li>
					<li>
						{' '}
						Realizar análisis estadísticos, de investigación e intereses del mercado, para ser transmitidos como informes a
						terceros.
					</li>
					<li>
						{' '}
						Transmitir la información y los Datos Personales a terceros involucrados en la prestación del servicio ofrecido.
					</li>
					<li>
						{' '}
						Transmitir y/o transferir los Datos Personales del Titular, en Colombia y al exterior, a empresas vinculadas o
						relacionadas, a proveedores de bienes y servicios y aliados comerciales de Vecindario, según sea requerido para
						efectos del cumplimiento de las obligaciones derivadas las relaciones comerciales, civiles o laborales existentes
						con los Titulares.
					</li>
					<li> Atender requerimientos de organismos de control y vigilancia.</li>
					<li>
						{' '}
						Promocionar actividades, productos y servicios ofrecidos por Vecindario. Para dicho efecto, se utilizarán
						distintos medios de comunicación, tales como correo, e-mail, teléfono fijo, redes sociales y celular (incluyendo
						mensajes de texto y de voz).
					</li>
					<li> Para las demás finalidades establecidas en los términos y condiciones de Vecindario y sus marcas aliadas.</li>
					<li> Para cualquier otra finalidad que resulte en el desarrollo del contrato celebrado con el Titular.</li>
					<li>
						{' '}
						Consultar, solicitar o verificar la información y Datos Personales del Titular en las bases de datos de entidades
						públicas o privadas, o que conozcan personas naturales o jurídicas, o se encuentren en buscadores públicos, redes
						sociales o publicaciones físicas o electrónicas, bien fuere en Colombia o en el exterior.
					</li>
					<li>
						{' '}
						Actualizar la Información del Titular antes Operadores de Información y Riesgo o ante cualquier otra entidad que
						maneje o administre bases de datos.
					</li>
					<li> Efectuar análisis e investigaciones comerciales, estadísticas, de riesgos y de mercado.</li>
					<li> Transferir los Datos Personales a otros responsables en virtud de un contrato comercial o civil.</li>
					<li> Actualizar la información del Titular.</li>
					<li>
						{' '}
						Registrar los Datos Personales en los sistemas de información de Vecindario y en sus Bases de Datos comerciales y
						operativas.
					</li>
					<li>
						{' '}
						Cualquier otra actividad de naturaleza similar a las anteriormente descritas que sean necesarias para desarrollar
						el objeto social de Vecindario.
					</li>
					Nota aclaratoria: Vecindario no usará los Datos Personales del Titular tales como, Correo, Nombre, Teléfono, entre
					otros, recolectados por la herramienta Simula para ofrecer algún tipo de proyecto inmobiliario, cuando el Titular
					acceda a través de un portal web diferente al de Vecindario. Adicionalmente, la base de datos de los empleados de
					Vecindario, se tratará con las siguientes finalidades:
					<li>
						{' '}
						Dar cumplimiento y exigirlo, al contrato laboral, reglamento interno de trabajo, así como las demás políticas
						implementadas por Vecindario frente a sus empleados, y a las obligaciones que de estos se desprenden.
					</li>
					<li>
						{' '}
						Para la constatación del cumplimiento de las obligaciones, la incursión en las prohibiciones, faltas graves y/o
						justas causas a través de los procedimientos laborales establecidos por Vecindario, especialmente a través de
						procedimientos disciplinarios y de auditoría; para lo anterior, Vecindario podrá auditar los equipos electrónicos
						(computadores, tabletas, celulares empresariales, entre otros) y softwares (correos electrónicos, chats,
						programas, archivos corporativos, entre otros) previstos por ésta para la prestación de los servicios de sus
						empleados. También, Vecindario podrá instalar sistemas de video vigilancia, de grabación de sonido, podrá
						recolectar, usar y almacenar datos biométricos (entre ellos, sin limitarse, a aquellos recolectados a través de
						lectores de huella dactilar y lectores faciales).
					</li>
					<li>
						{' '}
						Para la afiliación, pago de cotizaciones, y el cumplimiento en general de las obligaciones deberes, así como la
						exigencia de derechos, de los que Vecindario o el trabajador son titulares, frente al Sistema de Protección
						Social.
					</li>
					<li>
						{' '}
						Para dar cumplimiento, implementación y evaluación del Sistema de Gestión en Salud y Seguridad en el Trabajo.
					</li>
					<li>
						{' '}
						Para adelantar los procedimientos de transcripción, radicación y cobro de subsidios por incapacidad temporal
						frente a las entidades del Sistema de Seguridad Social.
					</li>
					<li> Para el análisis de Vecindario para conceder licencias o permisos.</li>
					<li>
						{' '}
						Para la ejecución directa o solicitud de ejecución a un tercero de pruebas que evidencien la presencia de alcohol
						o de sustancias psicoactivas o psicotrópicas en el organismo del trabajador.
					</li>
					<li>
						{' '}
						Para el adelantamiento de trámites o el cumplimiento de los mismos, iniciados por entidades públicas
						administrativas o judiciales, o ante cualquiera de estas por partede Vecindario.
					</li>
					<li>
						{' '}
						Para el trámite préstamos, embargos, retenciones y/o compensaciones de salarios, prestaciones sociales y demás
						derechos laborales generados en virtud de la relación de trabajo.
					</li>
					<li>
						{' '}
						Para efectos de capacitación, recreación, asistencia a eventos culturales, deportivos o académicos, dirigidos
						directamente por Vecindario o por cualquier tercero.
					</li>
					<li>
						{' '}
						Para el cumplimiento de la normativa laboral vigente y aplicable a Vecindario y a sus trabajadores, incluyendo
						disposiciones constitucionales, legales, reglamentarias, contractuales, así como de políticas y directrices
						internas de Vecindario
					</li>
					Por su parte, además de las finalidades enunciadas en un principio, los datos personales de los Titulares que
					aspiren a vincularse bajo cualquier modalidad permitida por la normativa colombiana con Vecindario, serán usados
					con las siguientes finalidades:
					<li>
						{' '}
						Para adelantar procesos de selección, los cuales podrán incluir entrevistas, sometimiento a pruebas de
						conocimiento y a pruebas sicotécnicas, práctica de visitas domiciliarias, así como el acceso a hojas de vida y las
						comunicaciones con el candidato por cualquier vía.
					</li>
					<li> Para contratar personal bajo cualquier modalidad permitida por la normativa colombiana.</li>
					<li>
						{' '}
						Para consultar referencias personales o familiares, así como los antecedentes laborales y de empleo del candidato,
						incluyendo la constatación de idoneidad y veracidad de los mismos.
					</li>
					<li>
						{' '}
						Para hacer consultas de antecedentes penales, judiciales, disciplinarios, comerciales y financieros en listas
						públicas o privadas.
					</li>
					<li> Para practicar exámenes médicos de ingreso.</li>
					<li>
						{' '}
						Para consultar referencias e historial académico, así como constatar la idoneidad y veracidad de los documentos
						académicos allegados por el candidato.
					</li>
					<li> Para almacenar los datos referidos en la base de datos de Vecindario.</li>
					<li>
						{' '}
						Para el acceso, consulta y almacenamiento de los demás documentos, certificados e información del Titular a los
						que hacen referencia los reglamentos y políticas internas de Vecindario para efectos de adelantar el proceso de
						selección de personal.
					</li>
					Por su parte, además de las finalidades enunciadas en un principio, los datos personales de los proveedores,
					contratistas, clientes y socios de Vecindario, serán usados con las siguientes finalidades:
					<li> Realizar auditorías al interior de Vecindario.</li>
					<li> Realizar el cobro o pago de productos o servicios.</li>
					<li> Realizar compra y venta de productos o servicios.</li>
					<li> Realizar y enviar cotizaciones, publicidad, ofertas o estudios de mercado.</li>
					<li> Para la búsqueda de socios o clientes potenciales.</li>
					<li> Con fines estadísticos o como indicadores de gestión.</li>
					Teniendo en cuenta que Vecindario cuenta con aliados y proveedores para el cumplimiento de su objeto social, para
					la prestación de sus servicios y para la ejecución de los contratos laborales, comerciales o civiles que celebre
					con los Titulares, Vecindario será la Responsable del Tratamiento y sus aliados y proveedores serán los Encargados
					del Tratamiento en los casos en que sea procedente; en esta medida Vecindario asegura que los Encargados del
					Tratamiento darán estricto cumplimiento a lo establecido en la presente Política. En el evento en que la prestación
					de sus servicios y/o la ejecución de los demás contratos laborales, comerciales o civiles que celebre con los
					Titulares implique la Transferencia o Transmisión de los Datos Personales a aliados o proveedores extranjeros,
					Vecindario se compromete a que estos se adhieran a lo establecido por la Ley 1581 de 2012 y el Decreto 1377 de 2013
					y demás normas que modifiquen, adicionen y complementen, y concretamente den estricto cumplimiento a lo establecido
					en la presente Política. Finalmente, concluido el servicio ofrecido por Vecindario o finalizado el contrato entre
					Vecindario y los Titulares, los Datos Personales sólo serán usados para efectos de mantenerlos en el registro de la
					Base de Datos de Vecindario y de enviar mensajes con fines comerciales, académicos y/o publicitarios si así lo
					autoriza el Titular, de lo contrario, serán eliminadosde las Bases de Datos de Vecindario o archivados en
					condiciones seguras cuando así lo requiera la normativa colombiana.
				</p>

				<p className="paragraph">
					<strong className="paragraph-subtitles">TRATAMIENTO DE DATOS SENSIBLES</strong>
					Vecindario restringirá estrictamente el tratamiento de Datos Personales Sensibles a los indispensables para
					realizar su objeto social o para cumplir con requerimientos normativos o contractuales, e informará a los Titulares
					de forma explícita y previa cuales son los Datos Sensibles y la finalidad de su Tratamiento. Vecindario solo dará
					tratamiento a Datos Sensibles sin el consentimiento previo cuando se trate de emergencia vital que requiera de una
					acción inmediata para evitar un daño grave o irreversible. Por otro lado, de acuerdo con el artículo 6 de la Ley
					1581 de 2012, Vecindario podrá darle Tratamiento a Datos Sensibles para `&quot;`una finalidad histórica,
					estadística o científica`&quot;`, siempre y cuando adopte `&quot;` las medidas conducentes a la supresión de
					identidad de los Titulares`&quot;`.
				</p>

				<p className="paragraph">
					<strong className="paragraph-subtitles">DERECHOS DE LOS TITULARES</strong>
					El Titular de los Datos Personales tendrá los siguientes derechos:
					<li>
						{' '}
						Conocer, actualizar y rectificar sus Datos Personales frente al Responsable del Tratamiento o Encargado del
						Tratamiento. Este derecho se podrá ejercer, entre otros frente a datos parciales, inexactos, incompletos,
						fraccionados, que induzcan a error, o aquellos cuyo Tratamiento esté expresamente prohibido o no haya sido
						autorizado;
					</li>
					<li>
						{' '}
						Solicitar prueba de la Autorización otorgada al Responsable del Tratamiento o Fuente de la Información, salvo
						cuando expresamente se exceptúe como requisitopara el Tratamiento, de conformidad con lo previsto en el artículo
						10 de la Ley 1581de 2012;
					</li>
					<li>
						{' '}
						Ser informado por el Responsable del Tratamiento o el Encargado del Tratamiento, previa solicitud, respecto del
						uso que le ha dado a sus Datos Personales;
					</li>
					<li>
						{' '}
						Presentar ante la Superintendencia de Industria y Comercio quejas por infraccionesa lo dispuesto en la Ley 1581 de
						2012 y las demás normas que la modifiquen, adicionen o complementen;
					</li>
					<li>
						{' '}
						Revocar la Autorización y/o solicitar la supresión del Dato Personal cuando en el Tratamiento no se respeten los
						principios, derechos y garantías constitucionales y legales. La revocatoria y/o supresión procederá cuando la
						Superintendencia de Industria y Comercio haya determinado que en el Tratamiento el Responsable o Encargado han
						incurrido en conductas contrarias a la ley y a la Constitución;
					</li>
					<li> Acceder en forma gratuita a sus Datos Personales que hayan sido objeto de Tratamiento;</li>
					<li> Ejercer sus derechos fundamentales al hábeas data y de petición.</li>
				</p>

				<p className="paragraph">
					<strong className="paragraph-subtitles">SEGURIDAD DE LA INFORMACIÓN</strong>
					Vecindario utiliza estrictos procedimientos de seguridad para garantizar la integridad, confidencialidad y
					seguridad de la información y de los Datos Personales suministrados por el Titular, tales como la transmisión y
					almacenamiento de información sensible a través de mecanismos seguros, uso de protocolos seguros, aseguramiento de
					componentes tecnológicos, restricción de acceso a la información sólo a personal autorizado, respaldo de
					información, prácticas de desarrollo seguro de software, entre otros, los cuales evitan su alteración y Tratamiento
					o acceso no autorizado. En los casos en que, de acuerdo con esta Política, Vecindario transfiera la información y/o
					Datos Personales a un tercero, éste último estará obligado a adoptar procedimientos y medidas similares que
					garanticen la seguridad de la información en igual o mayor medida que Vecindario.
				</p>

				<p className="paragraph">
					<strong className="paragraph-subtitles">
						PROCEDIMIENTOS DE ACCESO, CONSULTA Y RECLAMACIÓN SOBRE DATOS PESONALES
					</strong>
					Vecindario garantiza que los Titulares de los Datos Personales a los que se les da Tratamiento, tendrán acceso a
					éstos, y serán informados sobre todas las modificaciones, actualizaciones o supresiones que de ellos se haga,
					siempre y cuando soliciten dicha información. Vecindario pone a disposición de los Titulares el siguiente
					correo:hola@vecindario.com, al cual podrá escribir el Titular o los legitimados según la Ley 1581 de 2012, para
					tener acceso a su información personal, para acreditar el Tratamiento que se les está dando a sus Datos Personales,
					para presentar reclamos o solicitar su actualización, rectificación o supresión, para solicitar la prueba de la
					Autorización por éste dada en su oportunidad y para ejercerlos demás derechos mencionados anteriormente. Cuando se
					trate de consultas, Vecindario tendrá un plazo de 10 días hábiles para dar respuesta, contados a partir de la fecha
					de recibo de las mismas. Cuando no fuere posible atender la consulta dentro de dicho término, se informará al
					interesado, expresando los motivos de la demora y señalando la fecha en que se atenderá su consulta, la cual en
					ningún caso podrá superar los 5 días hábiles siguientes al vencimiento del primer término. Cuando se trate de
					reclamos, los mismos se formularán incluyendo la identificación del Titular, la descripción de los hechos que dan
					lugar al reclamo, la dirección y los documentos que se quiera hacer valer. Si el reclamo resulta incompleto, se
					requerirá al interesado dentro de los 5 días siguientes a la recepción del reclamo para que subsane las fallas.
					Transcurridos 2 meses desde la fecha del requerimiento, sin que el solicitante presente la información requerida,
					se entenderá que ha desistido del reclamo. Una vez recibido el reclamo completo, se incluirá en la base de datos
					una leyenda que diga `&quot;`reclamo en trámite`&quot;` y el motivo del mismo, en un término no mayor a 2 días
					hábiles. Dicha leyenda deberá mantenerse hasta que el reclamo sea decidido. El término máximo para atender el
					reclamo será de 15 días hábiles, contados a partir del día siguiente a la fecha de su recibo. Cuando no fuere
					posible atender el reclamo dentro de dicho término, se informará al interesado los motivos de la demora y la fecha
					en que se atenderá su reclamo, la cual en ningún caso podrá superar los 8 días hábiles siguientes al vencimiento
					del primer término. Para atender el reclamo, solicitud o consulta, es necesario que el Titular o los legitimados
					según la Ley 1581 de 2012, alleguen la siguiente información:
					<li> Nombre completo</li>
					<li> Número de identificación</li>
					<li> Dirección de notificación o de respuesta</li>
					<li> Teléfono de contacto</li>
					<li>
						{' '}
						Cuando el solicitante no sea el Titular, debe allegar la información que de acuerdo con la Ley 1581 de 2012 lo
						legitima para hacer la solicitud, consulta o reclamo en nombre del Titular.
					</li>
				</p>

				<p className="paragraph">
					<strong className="paragraph-subtitles">INFORMACIÓN DEL RESPONSABLE DEL TRATAMIENTO</strong>
					Vecindario será la Responsable del Tratamiento de los Datos Personales y sus datos de contacto son:
					<li> Dirección: Carrera 35 N° 15B - 143, 35 Palms Of. 9201</li>
					<li> Teléfono: 2040676</li>
					<li> Correo electrónico: hola@vecindario.com</li>
					VIGENCIA La presente Política entra en vigencia el día 16 de junio del año 2017, y la misma puede ser modificada
					por Vecindario en cualquier tiempo. Cualquier modificación de la presente Política, será informada a través de la
					página web www.vecindario.com donde se pondrá a disposición de los Titulares, la última versión de la misma,
					indicando la fecha de entrada en vigencia de la correspondiente modificación. Así mismo, cualquier modificación que
					se realice a la presente Política en relación a la identificación del Responsable del Tratamiento de los Datos
					Personales y las finalidades perseguidas con el mismo, serán comunicadas a los Titulares directamente antes de
					suentrada en vigencia, sin perjuicio de la solicitud de una nueva Autorización cuando los cambios se refieran a las
					finalidades mencionadas en el momento de la obtención de la Autorización inicial. Las bases de datos tendrán
					tratamiento durante el tiempo que sea razonable y necesario para la finalidad para la cual fueron obtenidos los
					datos. Una vez cumplida la finalidad, los Datos Personales sólo serán usados para efectos de mantenerlos en el
					registro de la Base de Datos de Vecindario y para enviar mensajes con fines comerciales y/o publicitarios si así lo
					autoriza el Titular, de lo contrario, serán eliminados de las Bases de Datos de Vecindario o archivados en
					condiciones seguras cuando así lo requiera la normativa colombiana.
				</p>
			</div>
		</section>
	);
};

export default DataProcessingPolicy;
