import React from 'react';
import PropTypes from 'prop-types';
import './InformativeCard.scss';

const InformativeCard = ({ banner, mainText, description, className = '' }) => {
	return (
		<article className={`${className} informative-card`}>
			<img src={banner} alt="" className="banner" />
			<h2 className="main-text">{mainText}</h2>
			<p className="description">{description}</p>
		</article>
	);
};

InformativeCard.propTypes = {
	banner: PropTypes.string,
	mainText: PropTypes.string,
	description: PropTypes.string,
	className: PropTypes.string,
};

export default InformativeCard;
