import { createSelector } from '@reduxjs/toolkit';

export const bankRequestState = (state) => state?.bankRequest;

export const getUserInfo = createSelector(bankRequestState, (bankRequest) => {
	return bankRequest?.userInfo;
});

export const getProjectConfigurationRequested = createSelector(bankRequestState, (bankRequest) => {
	return bankRequest?.projectConfigurationRequested;
});

export const getProjectConfiguration = createSelector(bankRequestState, (bankRequest) => {
	return bankRequest?.projectConfiguration;
});

export const creditsActive = createSelector(getProjectConfiguration, (projectConfiguration = {}) => {
	return projectConfiguration?.credits_active;
});
