import React from 'react';
import { TIPS_IMAGE_1, TIPS_IMAGE_2, TIPS_IMAGE_3 } from '../../../../../shared/application/constants/images';
import { dataProcessingPoliciesRoute } from '../../../../../shared/infrastructure/routing/routes';

export default [
	{
		banner: TIPS_IMAGE_1,
		subText: 'Tu información 100% segura',
		description: (
			<span>
				¡Estamos comprometidos con la seguridad de tus datos personales! Tus datos serán tratados conforme a nuestra
				política de tratamiento de datos personales que podrás consultar{' '}
				{
					<a href={dataProcessingPoliciesRoute} target="_blank" rel="noreferrer">
						aquí
					</a>
				}
				.
			</span>
		),
		buttonText: 'Siguiente',
		currentTipStep: 1,
	},
	{
		banner: TIPS_IMAGE_2,
		subText: 'Mucho más fácil y rápido',
		description:
			'¡Tu preaprobado en un día! Tu información será evaluada por nuestro equipo experto para aceptar o rechazar tu solicitud.',
		buttonText: 'Siguiente',
		currentTipStep: 2,
	},
	{
		banner: TIPS_IMAGE_3,
		subText: '¡Te brindamos acompañamiento!',
		description:
			'Contamos con asesores 100% disponibles para ti, para que resuelvas tus dudas, elijas el mejor proyecto de vivienda y te acompañe en la solicitud del preaprobado.',
		buttonText: 'Comenzar',
		currentTipStep: 3,
	},
];
