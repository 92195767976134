import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Uncomment next line when the app should consider user session
// const user = typeof window !== 'undefined' && JSON.parse(localStorage.getItem('user'));
const user = null;

export const initialState = {
	currentUser: user,
	loading: false,
};

const User = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setCurrentUser(state, { payload }) {
			state.user = payload;
		},
	},
});

export const updateUser = createAsyncThunk('user/updateUser', async (userToUpdate, { rejectWithValue }) => {
	try {
		typeof window !== 'undefined' && localStorage.setItem('user', JSON.stringify(userToUpdate));
		return userToUpdate;
	} catch (error) {
		return rejectWithValue(error);
	}
});

export const { setCurrentUser } = User.actions;

export default User.reducer;
