import React from 'react';
import PropTypes from 'prop-types';
import { LazyImage, IconBadge, TextTag } from '@vecindario/vecindario-suite-components';
import BackSection from '../../../../../shared/presentation/components/utils/BackSection';
import './BankRequestContainer.scss';

const BankRequestContainer = ({
	logo,
	title,
	responseTime,
	validityPreApproved,
	banner,
	children,
	subTitle,
	text,
	variant,
	onClick,
}) => {
	return (
		<div className={`bank-request-container ${variant}`}>
			<div className="return-section">
				<BackSection onClick={onClick} />
			</div>

			<div className="content-image">
				<LazyImage src={logo} imageName={title} />
			</div>

			<div className="content-time">
				<div className="time">
					<IconBadge icon="ri-time-line" size="small" bgColor="bg-neutral-300t" />
					<TextTag tag="span" variant="-body-sm" font="Lato">
						Tiempo de respuesta: {responseTime}
					</TextTag>
				</div>
				<div className="time">
					<IconBadge icon="ri-calendar-line" size="small" bgColor="bg-neutral-300t" />
					<TextTag tag="span" variant="-body-sm" font="Lato">
						Vigencia de preaprobado: {validityPreApproved}
					</TextTag>
				</div>
			</div>

			<div className="banner-container">
				<img src={banner} className="banner animate__fadeIn" />
			</div>

			<div className="tip-card-text">
				<h2 className="title">{subTitle}</h2>
				<p className="text">{text}</p>
			</div>

			<div className="children">{children}</div>
		</div>
	);
};

BankRequestContainer.propTypes = {
	logo: PropTypes.string,
	subTitle: PropTypes.string,
	responseTime: PropTypes.string,
	validityPreApproved: PropTypes.string,
	banner: PropTypes.string,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	title: PropTypes.string,
	variant: PropTypes.string,
	children: PropTypes.node,
	onClick: PropTypes.func,
};

BankRequestContainer.defaultProps = {
	logo: '',
	subTitle: '',
	responseTime: '',
	validityPreApproved: '',
	banner: '',
	text: '',
	title: '',
	variant: '',
	onClick: () => {},
};

export default BankRequestContainer;
