import React from 'react';
// import { useDispatch } from 'react-redux';
// import { updateUser } from '../../application/slices/user';

const styles = {
	container: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
		minHeight: '100vh',
	},
	button: {
		padding: '1rem',
		background: 'blue',
		color: 'white',
		borderRadius: '6px',
	},
};

const LoginPage = () => {
	// TODO: REMOVE ALL THIS
	const handleClick = () => {
		const token =
			'eyJhbGciOiJIUzUxMiJ9.eyJlbWFpbCI6InRlc3RpbmcudmVjaW5kYXJpby4xQHZlY2luZGFyaW8uY29tIn0.feOjjUwebru5ewn4W316hyTwrUu0ZRYhHvi0wHCuM8rAGau8a_BnCSpa5TT__S2994dHK-VysU6AqjSVkiOJxA';
		const user = {
			token,
			email: 'testing.vecindario.1@vecindario.com',
			name: 'Mariana',
		};
		localStorage.setItem('user', JSON.stringify(user));
		window.location.replace('/');
	};

	return (
		<div style={styles.container}>
			Token para el usuario testing.vecindario.1@vecindario.com
			<button style={styles.button} onClick={handleClick}>
				Iniciar sesión
			</button>
		</div>
	);
};

export default LoginPage;
