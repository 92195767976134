import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import FinancialInformationSection from '../../components/FinancialInformationSection';
import { setAlertData } from '../../../../../shared/application/slices/adminLayout';
import { SAVE_PROGRESS_MESSAGE } from '../../../../../shared/application/constants/messages/info-message';
import { selectCurrentStep, selectPreApprovedDetail } from '../../../application/selectors/credit-request';

const FinancialInformation = () => {
	const dispatch = useDispatch();
	const currentStep = useSelector(selectCurrentStep);
	const preApproved = useSelector(selectPreApprovedDetail);

	useEffect(() => {
		if (currentStep === 3 && !preApproved.disabled) {
			dispatch(
				setAlertData({
					text: SAVE_PROGRESS_MESSAGE,
					type: 'information',
					icon: 'info-icon',
					dismissTime: 5000,
				}),
			);
		}
		return () => {
			dispatch(
				setAlertData({
					text: '',
					type: '',
					icon: '',
					dismissTime: 0,
				}),
			);
		};
	}, [dispatch, currentStep, preApproved.disabled]);

	return (
		<>
			<Helmet>
				<title>Preaprobados | Información financiera | Vecindario </title>
				<meta name="description" content="Solicitud de preaprobado online para crédito hipotecario." />
				<meta property="og:type" content="website" />
				<meta property="og:title" content="Preaprobados | Información financiera | Vecindario" />
				<meta property="og:description" content="Solicitud de preaprobado online para crédito hipotecario." />
				<meta property="og:image" content="https://fiducia-suite.s3.us-east-2.amazonaws.com/vecindario-logo.svg" />
			</Helmet>
			<FinancialInformationSection />
		</>
	);
};

export default FinancialInformation;
