import React from 'react';
import { useSelector } from 'react-redux';
import { Icon, TextTag } from '@vecindario/vecindario-suite-components';
import { Link } from 'react-router-dom';
import { SUCCESS_THANK_YOU_IMAGE } from '../../../../../../shared/application/constants/images';
import './ThankYouRequestBanks.scss';
import { homeRoute } from '../../../../../../shared/infrastructure/routing/routes';
import { getUserInfo } from '../../../../infrastructure/selectors/bankRequest';
import { homeBankRoute } from '../../../../../home/infrastructure/routing/routes';
import { getValueFromLocalStorage } from '../../../../../../shared/application/helpers/common-functions';
import { PROJECT_SLUG } from '../../../../../../shared/application/constants/pre-approved-data';

const ThankYouRequestBanks = () => {
	const nameUser = useSelector(getUserInfo);
	const projectSlug = getValueFromLocalStorage(PROJECT_SLUG);

	return (
		<main className="container-success-banks">
			<header className="section-header">
				<Link to={projectSlug ? homeBankRoute(projectSlug) : homeRoute} className="button-back">
					<Icon icon="ri-arrow-left-s-line" />
					<TextTag variant="-body-sm">Volver</TextTag>
				</Link>
			</header>
			<section className="section-body">
				<TextTag tag="h3" className="user-name">
					¡{nameUser}!
				</TextTag>
				<img src={SUCCESS_THANK_YOU_IMAGE} alt="" />
				<TextTag variant="-body">Hemos recibido tu solicitud. ¡Pronto nos contactaremos contigo!</TextTag>
			</section>
			<footer>
				<img src="https://suite-preaprobado.s3.us-east-2.amazonaws.com/logo-suite-white.svg" alt="" />
			</footer>
		</main>
	);
};

export default ThankYouRequestBanks;
