import Tips from '../presentation/pages/Tips';
import { UnauthenticatedRoute } from '../../../shared/presentation/redirect-route';
import { tipsProjectRoute, tipsRoute } from './routes';

const tipsRouter = {
	router: [
		{
			path: tipsRoute,
			page: Tips,
			routeComponent: UnauthenticatedRoute,
			exact: true,
		},
		{
			path: tipsProjectRoute(),
			page: Tips,
			routeComponent: UnauthenticatedRoute,
			exact: true,
		},
	],
};

export default tipsRouter;
