import React from 'react';
import { Route, Switch } from 'react-router-dom';
import authRouter from '../../domains/authentication/infrastructure/router';
import homeRouter from '../../domains/home/infrastructure/routing/router';
import banksRequestRouter from '../../domains/bankRequest/infrastructure/routing/router';
import tipsRouter from '../../domains/tips/infrastructure/router';
import creditRequestRouter from '../../domains/creditRequest/infrastructure/routing/router';
import faqsRouter from '../../domains/faqs/infrastructure/router';
import sharedRouter from '../infrastructure/routing/router';
import NotFoundPage from './pages/NotFound';

const Router = () => {
	const defaultLayout = ({ children }) => <>{children}</>;
	const routes = [authRouter, homeRouter, banksRequestRouter, tipsRouter, creditRequestRouter, faqsRouter, sharedRouter];

	return (
		<Switch>
			{routes.map((route) => {
				return route?.router?.map(({ path, page: Component, routeComponent: Route, exact = true, layout, ...rest }) => (
					<Route
						key={path}
						exact={exact}
						path={path}
						component={Component}
						layout={layout || route.layout || defaultLayout}
						{...rest}
					/> // use same key to avoid recreate layout on every render
				));
			})}
			<Route path="*" component={NotFoundPage} />
		</Switch>
	);
};

export default Router;
