import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import InitialInformationSection from '../../components/InitialSection';
import { REQUEST_STEP_KEY } from '../../../application/constants/local-storage';
import { resetCache } from '../../../../../shared/application/helpers/common-functions';
import ConfirmationModal from '../../../../../shared/presentation/components/utils/ConfirmationModal';
import { resetCreditState } from '../../../application/slices/credit-request';

const InitialInformation = () => {
	const [openConfirmation, setOpenConfirmation] = useState(false);
	const dispatch = useDispatch();

	const history = useHistory();
	const currentRequestStep = localStorage.getItem(REQUEST_STEP_KEY);

	useEffect(() => {
		if (parseInt(currentRequestStep) > 0) {
			setOpenConfirmation(true);
		} else {
			resetCache();
			dispatch(resetCreditState());
		}
	}, [currentRequestStep, history, dispatch]);

	const handleCloseConfirmation = () => {
		setOpenConfirmation(false);
		history.goForward();
	};

	const handleConfirm = () => {
		resetCache();
		setOpenConfirmation(false);
		dispatch(resetCreditState());
	};

	return (
		<>
			<Helmet>
				<title>Preaprobados | Validadción de datos | Vecindario</title>
				<meta name="description" content="Solicitud de preaprobado online para crédito hipotecario." />
				<meta property="og:type" content="website" />
				<meta property="og:title" content="Preaprobados | Validación de datos | Vecindario" />
				<meta property="og:description" content="Solicitud de preaprobado online para crédito hipotecario." />
				<meta property="og:image" content="https://fiducia-suite.s3.us-east-2.amazonaws.com/vecindario-logo.svg" />
			</Helmet>
			<InitialInformationSection />
			<ConfirmationModal
				onClose={handleCloseConfirmation}
				open={openConfirmation}
				description="Al regresar se perderá el progreso actual y tendrás que iniciar todo el proceso de solicitud nuevamente."
				onConfirm={handleConfirm}
			/>
		</>
	);
};

export default InitialInformation;
