import { authHeader } from '../../../../shared/infrastructure/api/apiHandler';
import { urlPostBreapprovedBanks } from './backend-urls';

export const createBreapprovedBanks = (data) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader('multipart/form-data'),
		body: data,
	};
	requestOptions.headers.delete('Content-Type');
	return fetch(urlPostBreapprovedBanks, requestOptions);
};

export default {};
