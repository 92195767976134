import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@vecindario/vecindario-suite-components';
import { SUCCESS_MESSAGE } from '../../../../../shared/application/constants/images';

const SuccessfulSaveModal = ({ open, onClose }) => {
	const contentStyle = {
		backgroundColor: '#36BB7E',
		borderRadius: '8px',
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '0 3%',
		maxWidth: '650px',
	};

	const titleStyle = {
		color: 'white',
		fontWeight: '400',
		textAlign: 'center',
		lineHeight: '2.7rem',
	};

	const imageStyle = {
		width: '10vw',
		maxWidth: '150px',
		minWidth: '100px',
		marginBottom: '1rem',
	};

	return (
		<Modal isOpen={open} onClose={onClose} contentStyle={contentStyle}>
			<img src={SUCCESS_MESSAGE} alt="" style={imageStyle} />
			<h1 style={titleStyle}>¡Tu progreso ha sido guardado con éxito!</h1>
		</Modal>
	);
};

SuccessfulSaveModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
};

export default SuccessfulSaveModal;
