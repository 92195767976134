import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Select, Checkbox, MessageError } from '@vecindario/vecindario-suite-components';
import InformativePageWrapper from '../../../../../shared/presentation/pages/InformativePageWrapper';
import { START_REQUEST_IMAGE } from '../../../../../shared/application/constants/images';
import ProgressBarComponent from '../../../../../shared/presentation/components/utils/ProgressBarComponent';
import { startRequestFields } from '../../../application/constants/finantialInformation';
import { commonStartRequestSchema } from '../../../application/schemas/startRequest';
import {
	dataCredential,
	isPreApprovedComplete,
	preApprvedMessageError,
	selectPreApprovedDetail,
} from '../../../application/selectors/credit-request';
import { createPreApproved, changePreApprovedPartner } from '../../../application/slices/credit-request';
import { selectCurrentUserName } from '../../../../authentication/application/selectors/user';
import { PARTNER_AMOUNT } from '../../../../../shared/application/constants/form-options';
import {
	COMPLETE_PROGRESS_COMMENT,
	COMPLETE_PROGRESS_TEXT,
	CLOSE_PRE_APPROVED_MESSAGE,
} from '../../../../../shared/application/constants/messages/info-message';
import { isEmptyObject } from '../../../../../shared/application/helpers/common-functions';
import {
	dataAuthorizationRoute,
	dataProcessingPoliciesRoute,
} from '../../../../../shared/infrastructure/routing/routes';
import './RequestInformationSection.scss';

const RequestSection = () => {
	const userName = useSelector(selectCurrentUserName);

	const WrapperRequestSection = ({ text }) => {
		const preApprovedInfo = useSelector(selectPreApprovedDetail);
		const messageError = useSelector(preApprvedMessageError);
		const dispatch = useDispatch();
		const credential = useSelector(dataCredential);
		const isComplete = useSelector(isPreApprovedComplete);

		const {
			register,
			formState: { errors, isValid },
			handleSubmit,
			setValue,
			watch,
			reset,
		} = useForm({
			resolver: yupResolver(commonStartRequestSchema),
			mode: 'onChange',
		});

		const onSubmit = (data) => {
			let { partners_amount } = data;
			partners_amount = parseInt(partners_amount);
			const newData = {
				partners_amount,
				id: preApprovedInfo.id,
				document_type: credential.document_type,
				document_number: credential.document_number,
			};

			if (!isEmptyObject(preApprovedInfo)) {
				dispatch(changePreApprovedPartner(newData));
			} else {
				dispatch(createPreApproved(newData));
				reset({});
			}
		};

		const itemSelected = (value) => {
			setValue(startRequestFields.NUMBER_APPLICANTS, value, { shouldValidate: true, shouldDirty: true });
		};

		return (
			<div className="start-request-section">
				<p className="text">{text}</p>

				<ProgressBarComponent
					showActualStep={true}
					actualStep={1}
					totalSteps={watch()[startRequestFields.NUMBER_APPLICANTS] > 0 ? 4 : 3}
					isComplete={isComplete}
					completeText={COMPLETE_PROGRESS_TEXT}
					completeComment={COMPLETE_PROGRESS_COMMENT}
					comment={CLOSE_PRE_APPROVED_MESSAGE}
				/>

				<form className="request-form-group" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
					<div className="companion-from-group">
						<div className="form-control">
							<Select
								id="Número de solicitantes"
								options={PARTNER_AMOUNT}
								defaultOptionValue={preApprovedInfo?.partners_amount || 0}
								onSelectItem={(item) => {
									itemSelected(item.value);
								}}
								label="Número de solicitantes"
								error={errors[startRequestFields.NUMBER_APPLICANTS]?.message}
							/>
						</div>
						<div className="form-control">
							<Checkbox
								className="check-box-form"
								label="Acepto ser consultado ante centrales de riesgo para entender mejor mi perfil y determinar el banco que más se ajuste a mis características."
								labelPosition="right"
								defaultChecked={watch()[startRequestFields.CENTRAL_CONSULTATION]}
								name={startRequestFields.CENTRAL_CONSULTATION}
								{...register(startRequestFields.CENTRAL_CONSULTATION)}
								error={errors[startRequestFields.CENTRAL_CONSULTATION]?.message}
							/>

							<Checkbox
								className="check-box-form"
								name={startRequestFields.DATA_TREATMENT}
								label={
									<p>
										Acepto las{' '}
										<a href={dataProcessingPoliciesRoute} target="_blank" rel="noreferrer">
											políticas de Tratamiento de Datos.
										</a>
									</p>
								}
								labelPosition="right"
								defaultChecked={watch()[startRequestFields.DATA_TREATMENT]}
								{...register(startRequestFields.DATA_TREATMENT)}
								error={errors[startRequestFields.DATA_TREATMENT]?.message}
							/>

							<Checkbox
								className="check-box-form"
								name={startRequestFields.DATA_AUTHORIZATION}
								label={
									<p>
										Acepto la{' '}
										<a href={dataAuthorizationRoute} target="_blank" rel="noreferrer">
											autorización para administración y consulta de datos.
										</a>
									</p>
								}
								labelPosition="right"
								defaultChecked={watch()[startRequestFields.DATA_AUTHORIZATION]}
								{...register(startRequestFields.DATA_AUTHORIZATION)}
								error={errors[startRequestFields.DATA_AUTHORIZATION]?.message}
							/>
						</div>
					</div>
					<div className="form-action">
						{messageError && <MessageError message={messageError} className="form-error" />}
						<Button type="submit" text={'Siguiente'} disabled={!isValid} className={`${!isValid && 'disabled'}`} />
					</div>
				</form>
			</div>
		);
	};

	WrapperRequestSection.propTypes = {
		text: PropTypes.string,
	};

	return (
		<InformativePageWrapper banner={START_REQUEST_IMAGE} name={userName}>
			<WrapperRequestSection
				text="Para nosotros es muy importante conocer desde ahora cómo quieres tomar tu crédito, por eso te pedimos que elijas
					una de las siguientes opciones para comenzar"
			/>
		</InformativePageWrapper>
	);
};

export default RequestSection;
