export const DOCUMENT_TYPES = [
	{
		label: 'Cédula de Ciudadanía',
		value: 'CC',
	},
	{
		label: 'NIT',
		value: 'NIT',
	},
	{
		label: 'Cédula de Extranjería',
		value: 'CE',
	},
	{
		label: 'Tarjeta de Identidad',
		value: 'TI',
	},
	{
		label: 'Registro Civil',
		value: 'RC',
	},
	{
		label: 'Pasaporte',
		value: 'P',
	},
	{
		label: 'Carnet Diplomático',
		value: 'CD',
	},
	{
		label: 'Permiso Especial de Permanencia',
		value: 'PEDP',
	},
];

export const EMPLOYMENT_EMPLOYEE = 'Empleado';
export const EMPLOYMENT_INDEPENDENT = 'Independiente';
export const EMPLOYMENT_PENSIONER = 'Pensionado';
export const SERVICE_PROVIDER = 'Prestador de servicios';

export const EMPLOYMENT = [
	{
		label: 'Empleado',
		value: EMPLOYMENT_EMPLOYEE,
	},
	{
		label: 'Independiente',
		value: EMPLOYMENT_INDEPENDENT,
	},
	{
		label: 'Pensionado',
		value: EMPLOYMENT_PENSIONER,
	},
];

export const INDEPENDENT_ACTIVITIES = [
	{
		label: 'Dueño de negocio',
		value: 'owner',
	},
	{
		label: 'Conductor',
		value: 'driver',
	},
	{
		label: 'Transportador',
		value: 'carrier',
	},
	{
		label: 'Rentista de capital',
		value: 'rentier_capital',
	},
	{
		label: 'Prestador de servicios',
		value: 'service_provider',
	},
];

export const EMPLOYEE_ACTIVITIES = [
	{
		label: 'Magisterio',
		value: 'magisterium',
	},
	{
		label: 'Fuerzas militares',
		value: 'military_forces',
	},
	{
		label: 'Ninguno',
		value: 'none',
	},
];

export const CONTRACT_TYPE = [
	{
		label: 'Término fijo/Obra labor',
		value: 'fixed_term',
	},
	{
		label: 'Término indefinido',
		value: 'indefinite_term',
	},
];

export const DEPENDENTS = [
	...Array(11)
		.fill(0)
		.map((_, index) => {
			return { label: `${index}`, value: index };
		}),
];

export const CIVIL_STATUS = [
	{
		label: 'Soltero',
		value: 'single',
	},
	{
		label: 'Casado',
		value: 'married',
	},
	{
		label: 'Unión libre',
		value: 'free_union',
	},
	{
		label: 'Divorciado',
		value: 'divorcee',
	},
	{
		label: 'Separado',
		value: 'separate',
	},
];

export const YES_NO_OPTION = [
	{
		label: 'Si',
		value: true,
	},
	{
		label: 'No',
		value: false,
	},
];

export const PARTNER_AMOUNT = [
	{ value: 0, label: 'Solo yo' },
	{ value: 1, label: 'Otra persona y yo' },
	{ value: 2, label: 'Dos personas y yo' },
];
