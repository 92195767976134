import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TextTag, Card, Icon } from '@vecindario/vecindario-suite-components';
import BankCard from '../components/BankCard';
import { faqsRoute } from '../../../faqs/infrastructure/routes';
import './Home.scss';
import { setValueToLocalStorage } from '../../../../shared/application/helpers/common-functions';
import { PROJECT_SLUG } from '../../../../shared/application/constants/pre-approved-data';
import { requestProjectConfiguration } from '../../../bankRequest/infrastructure/slices/bankRequest';
import {
	creditsActive,
	getProjectConfigurationRequested,
} from '../../../bankRequest/infrastructure/selectors/bankRequest';

const Home = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const virtualVisit = localStorage.getItem('virtualVisit');
	const { slug } = useParams();
	const isCreditsActive = useSelector(creditsActive);
	const projectConfigurationRequested = useSelector(getProjectConfigurationRequested);

	if (slug) {
		setValueToLocalStorage(PROJECT_SLUG, slug);
	}

	if (virtualVisit === null) {
		history.push(faqsRoute);
	}

	useEffect(() => {
		if (slug && !projectConfigurationRequested) {
			dispatch(requestProjectConfiguration(slug));
		}
	}, [dispatch, projectConfigurationRequested, slug]);

	useEffect(() => {
		if (!isCreditsActive && projectConfigurationRequested) {
			history.push('/404');
		}
	}, [history, isCreditsActive, projectConfigurationRequested]);

	return (
		<div className="content-list-banks">
			<br />
			<TextTag tag="h3" font="DM-sans" fw="medium">
				👋 ¡Bienvenido!
			</TextTag>
			<br />
			<TextTag tag="p" variant="-body" font="Lato" fw="normal">
				Selecciona la entidad financiera con la que deseas tramitar la solicitud de tu preaprobado
			</TextTag>
			<br />
			<BankCard />
			<br />
			<Card classNameCard="card-info" onClick={() => history.push(faqsRoute)}>
				<TextTag tag="h5" font="DM-sans" fw="bold">
					Conoce más sobre preaprobado
				</TextTag>
				<TextTag tag="p" variant="-body-sm" font="DM-sans" fw="normal">
					¿Tienes dudas? en esta sección encontrarás sobre todo lo que debes saber sobre preaprobado
				</TextTag>
				<div className="arrow">
					<Icon icon="ri-arrow-right-circle-fill" size="large" />
				</div>
			</Card>
		</div>
	);
};

export default Home;
