import { NODE_ENV, URL_PROD } from '../../application/constants/env';

export const urlBase = NODE_ENV === 'development' ? 'http://localhost:3012/api/v1/' : URL_PROD;

// CITY
export const urlGetCityList = `${urlBase}ciudades`;
export const urlGetDataList = `${urlBase}obtener-listas`;
// BANKS
export const urlGetBanks = `${urlBase}bancos`;

export default { urlGetCityList, urlGetDataList };
