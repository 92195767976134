import { createSelector } from '@reduxjs/toolkit';

export const projectState = (state) => state.adminLayout;

export const getNavBarLogo = createSelector(projectState, (adminLayout) => {
	const { navBarLogo } = adminLayout;
	return navBarLogo;
});

export const getAlertData = createSelector(projectState, (adminLayout) => {
	const { alertData } = adminLayout;
	return alertData;
});

export const getBanks = createSelector(projectState, (adminLayout) => {
	return adminLayout?.banksInfo;
});

export const getBankCredifamilia = createSelector(getBanks, (banksInfo) => {
	const res = banksInfo.find((item) => {
		return item?.slug_bank === 'credifamilia';
	});
	return res;
});
