import React from 'react';
import './DataAuthorization.scss';

const DataAuthorization = () => {
	return (
		<section className="data-authorization">
			<div className="text">
				<p className="paragraph">
					<li>
						Autorizo a Vecindario para consultarme en la centrales de información conforme a lo dispuesto en la Ley 1266 de
						2008.
					</li>
					<li>
						Declaro que cuento con la autorización previa, expresa e informada de todas las personas cuyos datos estan siendo
						diligenciados en la presente solicitud.
					</li>
					<li>Acepto la politica de tratamiento de datos personales. </li>
				</p>
				<p className="paragraph">
					Autorizo a Vecindario, en ejercicio de su derecho a la libertad y autodeterminación informática, previo a la
					relación contractual y de manera irrevocable, escrita, expresa, concreta, suficiente, voluntaria e informada, con
					la finalidad que la información comercial, crediticia, financiera y de servicios de la cual es titular, referida al
					nacimiento, ejecución y extinción de obligaciones dinerarias, al comportamiento e historial crediticio (pasado,
					presente y futuro), incluida la información positiva y negativa de los hábitos de pago, y aquella que se refiere a
					la información personal necesaria para el estudio, análisis y eventual otorgamiento de un crédito, sea en general
					administrada y en especial: consultada, capturada, tratada, procesada, operada, verificada, transmitida,
					transferida, usada o puesta en circulación y consultada por terceras personas autorizadas expresamente por la ley
					1266 de 2008 y cualquier otra norma que la modifique o adicione, incluidos los usuarios de la información.
				</p>
			</div>
		</section>
	);
};

export default DataAuthorization;
