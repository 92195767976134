import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import InformativeSection from '../components/InformativeSection';
import FAQ from '../components/FAQ';
import Divider from '../../../../shared/presentation/components/utils/Divider';
import StepSection from '../components/StepSection/StepSection';
import MyPreApproved from '../../../creditRequest/presentation/components/MyPreApproved';
import { resetCache } from '../../../../shared/application/helpers/common-functions';
import './FAQS.scss';
import { resetCreditState } from '../../../creditRequest/application/slices/credit-request';

const FAQS = () => {
	const { hash } = useLocation();
	const faqRef = useRef(null);
	const infoRef = useRef(null);
	const myPreApproves = useRef(null);
	const dispatch = useDispatch();

	useEffect(() => {
		resetCache();
		dispatch(resetCreditState());
	}, [dispatch]);

	useEffect(() => {
		if (hash === '#faq') {
			faqRef.current.scrollIntoView();
		} else if (hash === '#todo-sobre-pre-aprobados') {
			infoRef.current.scrollIntoView();
		} else if (hash === '#mis-pre-aprobados') {
			myPreApproves.current.scrollIntoView();
		}
	}, [hash]);

	localStorage.setItem('virtualVisit', true);

	return (
		<div>
			<div ref={infoRef}>
				<InformativeSection />
			</div>
			<Divider />
			<StepSection />
			<Divider />
			<div ref={myPreApproves}>
				<MyPreApproved />
			</div>

			<div ref={faqRef}>
				<FAQ />
			</div>
		</div>
	);
};

export default FAQS;
