import React from 'react';
import { Helmet } from 'react-helmet';
import RequestInformationSection from '../../components/RequestInformationSection';

const RequestInformation = () => {
	return (
		<>
			<Helmet>
				<title>Preaprobados | Inicio de solicitud | Vecindario </title>
				<meta name="description" content="Solicitud de preaprobado online para crédito hipotecario." />
				<meta property="og:type" content="website" />
				<meta property="og:title" content="Preaprobados | Inicio de solicitud | Vecindario" />
				<meta property="og:description" content="Solicitud de preaprobado online para crédito hipotecario." />
				<meta property="og:image" content="https://fiducia-suite.s3.us-east-2.amazonaws.com/vecindario-logo.svg" />
			</Helmet>
			<RequestInformationSection />
		</>
	);
};

export default RequestInformation;
