import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@vecindario/vecindario-suite-components';
import { updateThankYouParams } from '../../../application/slices/credit-request';
import { SUCCESS_THANK_YOU } from '../../../application/constants/thank-you';
import { SUCCESS_THANK_YOU_IMAGE } from '../../../../../shared/application/constants/images';
import { selectOwnerNameText, selectPreApprovedResponse } from '../../../application/selectors/credit-request';
import CopyEmailForm from '../../components/CopyEmailForm';
import { homeBankRoute, homeRoute } from '../../../../home/infrastructure/routing/routes';
import { PROJECT_SLUG } from '../../../../../shared/application/constants/pre-approved-data';
import { getValueFromLocalStorage } from '../../../../../shared/application/helpers/common-functions';

const SuccessThankYou = () => {
	const dispatch = useDispatch();
	const preApprovedResponse = useSelector(selectPreApprovedResponse);
	const ownerName = useSelector(selectOwnerNameText);
	const history = useHistory();
	const projectSlug = getValueFromLocalStorage(PROJECT_SLUG);

	useEffect(() => {
		dispatch(updateThankYouParams({ type: SUCCESS_THANK_YOU }));
	}, [dispatch]);

	const openLetter = () => {
		if (preApprovedResponse.letter_path) {
			window.open(preApprovedResponse.letter_path, '_blank');
		}
	};

	const handleNewRequest = () => {
		if (projectSlug) {
			return history.replace(homeBankRoute(projectSlug));
		}
		return history.replace(homeRoute);
	};

	return (
		<>
			<h2 className="thank-you-user-name color-white">{ownerName}</h2>
			<h3 className="thank-you-top-text color-white">¡Ya tienes preaprobado!</h3>
			<img src={SUCCESS_THANK_YOU_IMAGE} alt="solicitud-aprobada" className="thank-you-image" />
			<p className="thank-you-description color-white">
				¡Felicitaciones! Los detalles de tu solicitud llegarán a tu correo electrónico.
			</p>
			<div className="success-thank-you-actions">
				<Button onClick={openLetter} disabled={!preApprovedResponse.letter_path}>
					Ver comprobante
				</Button>
				<br />
				<Button onClick={handleNewRequest}>Realizar otra solicitud</Button>
			</div>
			<br />
			<br />
			<br />
			<CopyEmailForm />
			<br />
		</>
	);
};

export default SuccessThankYou;
