import * as yup from 'yup';

import { startRequestFields } from '../constants/finantialInformation';

export const commonStarRequest = {};
commonStarRequest[startRequestFields.NUMBER_APPLICANTS] = yup.string();
commonStarRequest[startRequestFields.CENTRAL_CONSULTATION] = yup.bool().oneOf([true], '');
commonStarRequest[startRequestFields.DATA_TREATMENT] = yup.bool().oneOf([true], '');
commonStarRequest[startRequestFields.DATA_AUTHORIZATION] = yup.bool().oneOf([true], '');

export const commonStartRequestSchema = yup.object().shape(commonStarRequest);
