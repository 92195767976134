import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Footer } from '@vecindario/vecindario-suite-components';
import { useSelector, useDispatch } from 'react-redux';
import { VECINDARIO_DARK_LOGO } from '../../../../../shared/application/constants/images';
import { selectThankYouParams } from '../../../application/selectors/credit-request';
import { resetCache } from '../../../../../shared/application/helpers/common-functions';
import { resetCreditState } from '../../../application/slices/credit-request';
import './ThankYouLayout.scss';

const ThankYouLayout = ({ children }) => {
	const { type } = useSelector(selectThankYouParams);
	const dispatch = useDispatch();

	useEffect(() => {
		return () => {
			resetCache();
			dispatch(resetCreditState());
		};
	}, [dispatch]);

	return (
		<section className={`thank-you-layout ${type}`}>
			<header className="thank-you-header">
				<img src={VECINDARIO_DARK_LOGO} alt="vecindario-suite" />
			</header>
			<div className="thank-you-content">{children}</div>
			<Footer theme="light" footerStyle={{ backgroundColor: 'transparent' }} />
		</section>
	);
};

ThankYouLayout.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default ThankYouLayout;
