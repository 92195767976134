import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { history } from '../../../../shared/application/helpers/history';
import { getCityList } from '../../../../shared/infrastructure/api/cities';
import { getDataLists } from '../../../../shared/infrastructure/api/dataLists';
import { fetchUserDetails } from '../../infrastructure/api/associates-information';
import {
	getLastPreApproved,
	postPreApproved,
	postRequestInformation,
	saveUserBudget,
	updatePreApproved,
	getPreApprovedList,
} from '../../infrastructure/api/personal-information';
import {
	associatesInformationRoute,
	checkingRequestRoute,
	financialInformationRoute,
	informationRoute,
	personalInformationRoute,
} from '../../infrastructure/routing/routes';
import { financialInformationFields } from '../constants/finantialInformation';
import { isEmptyObject } from '../../../../shared/application/helpers/common-functions';
import { FORM_ERRORS, PRE_APPROVED_RESULT_KEY, REQUEST_STEP_KEY } from '../constants/local-storage';
import { PENDING_THANK_YOU } from '../constants/thank-you';

export const initialState = {
	cities: [],
	showSuccessfulSave: false,
	currentPreApproved: {},
	loadingPreApproved: false,
	preApprovedError: null,
	dataLists: {},
	authorizePreApproved: false,
	myPreApproved: [],
	requestCredential: {},
	ownerInformation: {},
	ownerPersonaInformationErrors: {},
	ownerFinancialErrors: {},
	companionErrors: {},
	captchaErrors: '',
	userAlreadyExistError: '',
	thankYouParams: {
		type: PENDING_THANK_YOU,
	},
	preApprovedResult: null,
};

export const requestCities = createAsyncThunk('request-city-list', async (_, { rejectWithValue }) => {
	try {
		return getCityList();
	} catch (error) {
		return rejectWithValue(error);
	}
});

export const requestDataLists = createAsyncThunk('request-data-lists', async (_, { rejectWithValue }) => {
	try {
		return getDataLists();
	} catch (error) {
		return rejectWithValue(error);
	}
});

export const UpdateBudget = createAsyncThunk(
	'credit-request/UpdateBudgetPreApproved',
	async (data, { rejectWithValue }) => {
		try {
			const { id, values } = data;
			const response = await saveUserBudget(id, values);
			history.push(values.last_step ? checkingRequestRoute : associatesInformationRoute);
			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const lastPreApproved = createAsyncThunk('credit-request/lastPreApproved', async (_, { rejectWithValue }) => {
	try {
		const response = await getLastPreApproved();
		return response;
	} catch (error) {
		return rejectWithValue(error);
	}
});

export const createPreApproved = createAsyncThunk(
	'credit-request/createPreApproved',
	async (data, { rejectWithValue }) => {
		try {
			const response = await postPreApproved(data);
			localStorage.setItem(REQUEST_STEP_KEY, '2');
			localStorage.setItem(PRE_APPROVED_RESULT_KEY, JSON.stringify(response));
			history.push(personalInformationRoute);
			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const changePreApprovedPartner = createAsyncThunk(
	'credit-request/updatePreApproved',
	async (data, { rejectWithValue }) => {
		try {
			const response = await updatePreApproved(data);
			localStorage.setItem(REQUEST_STEP_KEY, '2');
			history.push(personalInformationRoute);
			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const requestInformation = createAsyncThunk(
	'credit-request/requestInformation',
	async (data, { rejectWithValue }) => {
		try {
			const response = await postRequestInformation(data);
			localStorage.setItem(REQUEST_STEP_KEY, '1');
			history.push(informationRoute);
			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const preApprovedList = createAsyncThunk('credit-request/preApprovedList', async (_, { rejectWithValue }) => {
	try {
		const response = await getPreApprovedList();
		return response;
	} catch (error) {
		return rejectWithValue(error);
	}
});

export const fetchOwnerInformation = createAsyncThunk(
	'credit-request/fetchOwnerInformation',
	async (data, { rejectWithValue }) => {
		try {
			const response = await fetchUserDetails(data.id, data.owner);
			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const updateFormErrors = createAsyncThunk('credit-request/updateFormErrors', (data, { rejectWithValue }) => {
	try {
		const _ownerErrors = data?.owner ?? {};

		const { captcha_code, already_exist } = _ownerErrors;
		if (captcha_code !== '') delete _ownerErrors.captcha_code;
		if (already_exist !== '') delete _ownerErrors.already_exist;

		const _companionErrors = data?.companionErrors ?? {};
		const _financialErrors = {};
		const stopRedirect = !!data?.stopRedirect;

		if (!isEmptyObject(data)) localStorage.setItem(FORM_ERRORS, JSON.stringify(data));
		delete _ownerErrors[''];

		Object.values(financialInformationFields).forEach((value) => {
			if (_ownerErrors[value]) {
				_financialErrors[value] = _ownerErrors[value];
				delete _ownerErrors[value];
			}
		});

		if (!stopRedirect) {
			if (!isEmptyObject(_ownerErrors)) history.push(personalInformationRoute);
			else if (!isEmptyObject(_financialErrors)) history.push(financialInformationRoute);
			else if (!isEmptyObject(_companionErrors)) {
				const companionIds = Object.keys(_companionErrors);
				const lastId = companionIds.find((id) => !isEmptyObject(_companionErrors[id]));
				lastId && history.push(associatesInformationRoute(lastId));
			}
		}
		return {
			ownerErrors: _ownerErrors,
			ownerFinancialErrors: _financialErrors,
			companionErrors: _companionErrors,
			captchaError: captcha_code,
			userAlreadyExistError: already_exist,
		};
	} catch (error) {
		return rejectWithValue(error);
	}
});

const CreditRequest = createSlice({
	name: 'creditRequest',
	initialState,
	reducers: {
		updateSuccessfulSave(state, { payload }) {
			state.showSuccessfulSave = payload;
		},
		updateNotificationState(state, { payload }) {
			const { myPreApproved } = state;

			const summerFruitsCopy = myPreApproved.map((notification) =>
				notification.id === payload.id
					? { ...notification, pre_approved_notification: { ...notification.pre_approved_notification, notify_read: true } }
					: notification,
			);

			state.myPreApproved = summerFruitsCopy;
		},
		updateRequestCredential(state, { payload }) {
			state.requestCredential = payload;
		},
		cleanRequestErrors(state) {
			state.ownerPersonaInformationErrors = {};
			state.ownerFinancialErrors = {};
			state.companionErrors = {};
			state.captchaErrors = '';
			state.userAlreadyExistError = '';
			state.preApprovedError = null;
		},
		updateThankYouParams(state, { payload }) {
			state.thankYouParams = payload;
		},
		updatePreApprovedResult(state, { payload }) {
			state.preApprovedResult = payload;
		},
		resetCreditState: () => initialState,
	},
	extraReducers: {
		[requestCities.fulfilled]: (state, { payload = [] }) => {
			state.cities = payload;
		},
		[requestCities.rejected]: (state) => {
			state.cities = [];
		},
		[requestDataLists.fulfilled]: (state, { payload = [] }) => {
			state.dataLists = { ...payload };
		},
		[requestDataLists.rejected]: (state) => {
			state.dataLists = [];
		},
		[createPreApproved.pending]: (state) => {
			state.loadingPreApproved = true;
			state.preApprovedError = null;
		},
		[createPreApproved.rejected]: (state, { payload }) => {
			state.loadingPreApproved = false;
			state.preApprovedError = payload;
		},
		[createPreApproved.fulfilled]: (state, { payload }) => {
			state.loadingPreApproved = false;
			state.currentPreApproved = payload;
		},
		[lastPreApproved.pending]: (state) => {
			state.loadingPreApproved = true;
			state.preApprovedError = null;
		},
		[lastPreApproved.rejected]: (state) => {
			state.loadingPreApproved = false;
			state.preApprovedError = null;
		},
		[lastPreApproved.fulfilled]: (state, { payload }) => {
			state.loadingPreApproved = false;
			state.currentPreApproved = payload;
		},
		[UpdateBudget.pending]: (state) => {
			state.preApprovedError = null;
			state.loadingPreApproved = true;
		},
		[UpdateBudget.rejected]: (state, { payload }) => {
			state.preApprovedError = payload;
			state.loadingPreApproved = false;
		},
		[UpdateBudget.fulfilled]: (state, { payload }) => {
			state.currentPreApproved = payload;
			state.loadingPreApproved = false;
		},
		[requestInformation.pending]: (state) => {
			state.loadingPreApproved = true;
			state.preApprovedError = null;
		},
		[requestInformation.rejected]: (state, { payload }) => {
			state.loadingPreApproved = false;
			state.preApprovedError = payload;
		},
		[requestInformation.fulfilled]: (state, { payload }) => {
			state.loadingPreApproved = false;
			state.authorizePreApproved = payload;
		},
		[changePreApprovedPartner.pending]: (state) => {
			state.loadingPreApproved = true;
			state.preApprovedError = null;
		},
		[changePreApprovedPartner.rejected]: (state, { payload }) => {
			state.loadingPreApproved = false;
			state.preApprovedError = payload;
		},
		[changePreApprovedPartner.fulfilled]: (state, { payload }) => {
			state.loadingPreApproved = false;
			state.currentPreApproved = payload;
		},
		[preApprovedList.pending]: (state) => {
			state.myPreApproved = [];
		},
		[preApprovedList.fulfilled]: (state, { payload }) => {
			state.myPreApproved = payload;
		},
		[fetchOwnerInformation.pending]: (state) => {},
		[fetchOwnerInformation.fulfilled]: (state, { payload }) => {
			state.ownerInformation = payload;
		},
		[updateFormErrors.fulfilled]: (state, { payload }) => {
			state.ownerPersonaInformationErrors = payload.ownerErrors;
			state.ownerFinancialErrors = payload.ownerFinancialErrors;
			state.companionErrors = payload.companionErrors;
			state.captchaErrors = payload.captchaError;
			state.userAlreadyExistError = payload.userAlreadyExistError;
		},
	},
});

export const {
	updateSuccessfulSave,
	updateRequestCredential,
	updateNotificationState,
	cleanRequestErrors,
	updateThankYouParams,
	updatePreApprovedResult,
	resetCreditState,
} = CreditRequest.actions;

export default CreditRequest.reducer;
