import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { NavBar, Footer } from '@vecindario/vecindario-suite-components';
import { history } from '../../../application/helpers/history';
import { leftElementData } from './navBarData';
import BackSection from '../../components/utils/BackSection';
import { homeBankRoute, homeRoute } from '../../../../domains/home/infrastructure/routing/routes';
import { faqsRoute } from '../../../../domains/faqs/infrastructure/routes';
import { LOGO_PREAPROVADO } from '../../../application/constants/images';
import './AdminLayout.scss';
import { dataAuthorizationRoute, dataProcessingPoliciesRoute } from '../../../infrastructure/routing/routes';
import { PROJECT_SLUG } from '../../../application/constants/pre-approved-data';
import {
	getValueFromLocalStorage,
	removeValueFromLocalStorage,
	setValueToLocalStorage,
} from '../../../application/helpers/common-functions';

const AdminLayout = ({ children }) => {
	const [url, setUrl] = useState('');
	const [classHome, setClassHome] = useState('');
	const { slug } = useParams();
	const projectSlug = getValueFromLocalStorage(PROJECT_SLUG);

	const returnAction = () => {
		if (history.location.pathname === homeRoute) {
			// TODO pending implementation "Separación" module
			removeValueFromLocalStorage(PROJECT_SLUG, slug);
			history.push('');
		} else if (history.location.pathname === faqsRoute) {
			projectSlug ? history.push(homeBankRoute(projectSlug)) : history.push(homeRoute);
		} else {
			history.goBack();
		}
	};

	if (slug) {
		setValueToLocalStorage(PROJECT_SLUG, slug);
	}

	useEffect(() => {
		if (history.location.pathname === homeRoute) {
			removeValueFromLocalStorage(PROJECT_SLUG);
			setUrl(homeRoute);
			setClassHome('home');
		}
	}, [url]);

	const showBack =
		history.location.pathname !== homeRoute &&
		![dataProcessingPoliciesRoute, dataAuthorizationRoute].includes(history.location.pathname);
	return (
		<section className={`admin-layout ${classHome}`}>
			<NavBar
				logo={LOGO_PREAPROVADO}
				leftElement={
					url !== '/' && (
						<img
							className="navBar-icons"
							src="https://fiducia-suite.s3.us-east-2.amazonaws.com/navBarLeftIcon.png"
							alt="logo-Vecindario"
						/>
					)
				}
				history={history}
				leftElementData={projectSlug ? leftElementData(homeBankRoute(projectSlug)) : leftElementData()}
			/>

			<div className="layout-container">
				{showBack && <BackSection onClick={returnAction} />}
				{children}
			</div>
			<Footer theme="light" />
		</section>
	);
};

AdminLayout.propTypes = {
	children: PropTypes.node,
};

export default AdminLayout;
