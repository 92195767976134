import AdminLayout from '../../../shared/presentation/layouts/AdminLayout';
import FAQS from '../presentation/pages/FAQS';
import { UnauthenticatedRoute } from '../../../shared/presentation/redirect-route';
import { faqsRoute } from './routes';

const faqsRouter = {
	layout: AdminLayout,
	router: [
		{
			path: faqsRoute,
			page: FAQS,
			routeComponent: UnauthenticatedRoute,
			exact: true,
		},
	],
};

export default faqsRouter;
