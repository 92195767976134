import React from 'react';
import { useSelector } from 'react-redux';
import { Toast } from '@vecindario/vecindario-suite-components';
import PropTypes from 'prop-types';
import { history } from '../../../../application/helpers/history';
import { getAlertData } from '../../../../application/selectors/adminLayout';
import { ICON_CHECK, ICON_CHECK_BLANK, ICON_INFORMATION } from '../../../../application/constants/icons';
import './BackSection.scss';

const BackSection = ({ onClick = null }) => {
	const alertData = useSelector(getAlertData);

	const getIconMessage = () => {
		const arrayIcons = {
			'success-icon': (
				<div className="check-icon">
					<i className={`${ICON_CHECK_BLANK} circle`}>
						<i className={`${ICON_CHECK} check`}></i>
					</i>
				</div>
			),
			'info-icon': (
				<div className="info-icon">
					<div className="square">
						<i className={`${ICON_INFORMATION} info`}></i>
					</div>
				</div>
			),
		};

		return arrayIcons[alertData.icon];
	};

	return (
		<div className="return-section">
			<div onClick={onClick ? () => onClick() : () => history.goBack()} className="text-section">
				<i className="icon ri-arrow-left-s-line"></i>
				<span className="back-text">Volver</span>
			</div>
			{alertData?.text !== '' && (
				<Toast
					toastList={[
						{
							id: 0,
							description: alertData?.text,
							icon: getIconMessage(),
							className: `alert-message-container ${alertData?.type}`,
						},
					]}
					position=""
					autoDelete={alertData?.dismissTime > 0}
					dismissTime={alertData?.dismissTime || 0}
				/>
			)}
		</div>
	);
};

BackSection.propTypes = {
	onClick: PropTypes.func,
};

export default BackSection;
