import AdminLayout from '../../../../shared/presentation/layouts/AdminLayout';
import Home from '../../presentation/pages/Home';
import { UnauthenticatedRoute } from '../../../../shared/presentation/redirect-route';
import { homeBankRoute, homeBankRoute2, homeRoute } from './routes';

const homeRouter = {
	layout: AdminLayout,
	router: [
		{
			path: homeRoute,
			page: Home,
			routeComponent: UnauthenticatedRoute,
			exact: true,
		},
		{
			path: homeBankRoute(),
			page: Home,
			routeComponent: UnauthenticatedRoute,
			exact: true,
		},
		{
			path: homeBankRoute2(),
			page: Home,
			routeComponent: UnauthenticatedRoute,
			exact: true,
		},
	],
};

export default homeRouter;
