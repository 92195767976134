export const requestRoute = '/solicitud';
export const informationRoute = `${requestRoute}/informacion`;
export const personalInformationRoute = `${requestRoute}/informacion-personal`;
export const financialInformationRoute = `${requestRoute}/informacion-financiera`;
export const associatesInformationRoute = (partner_id = ':partner_id') => `${requestRoute}/asociado/${partner_id}`;
export const passedRoute = (pre_approved_id = ':pre_approved_id') => `${requestRoute}/aprobado/${pre_approved_id}`;
export const deniedRoute = (pre_approved_id = ':pre_approved_id') => `${requestRoute}/denegado/${pre_approved_id}`;

// THANK YOU PAGES
export const successRequestThankYouRoute = `${requestRoute}/solicitud-aprobada`;
export const pendingRequestThankYouRoute = `${requestRoute}/solicitud-pendiente`;
export const rejectedRequestThankYouRoute = `${requestRoute}/solicitud-rechazada`;
export const checkingRequestRoute = `${requestRoute}/verificando-solicitud`;
