import * as yup from 'yup';
import { commonPersonalInformation } from './commonPersonalInformation';
import { personalInformationFields, credifamiliaFields } from '../constants/finantialInformation';
import { EMPTY_FIELD, MAX_FROM_NOW_DATE } from '../../../../shared/application/constants/messages/error-messages';
import { EMPLOYMENT_EMPLOYEE, EMPLOYMENT_INDEPENDENT } from '../../../../shared/application/constants/form-options';

const personalInformation = { ...commonPersonalInformation };

personalInformation[personalInformationFields.ACTIVITY_TYPE] = yup.string().when(personalInformationFields.EMPLOYMENT, {
	is: EMPLOYMENT_EMPLOYEE || EMPLOYMENT_INDEPENDENT,
	then: yup.string().required(EMPTY_FIELD),
});
personalInformation[personalInformationFields.CONTRACT_TYPE] = yup.string().when(personalInformationFields.EMPLOYMENT, {
	is: EMPLOYMENT_EMPLOYEE,
	then: yup.string().required(EMPTY_FIELD),
});
personalInformation[personalInformationFields.ADMISSION_DATE] = yup
	.date()
	.required(EMPTY_FIELD)
	.max(new Date(), MAX_FROM_NOW_DATE);
personalInformation[personalInformationFields.DEPENDENTS] = yup.string().required(EMPTY_FIELD);
personalInformation[personalInformationFields.CIVIL_STATUS] = yup.string().required(EMPTY_FIELD);
personalInformation[personalInformationFields.OWN_HOME] = yup.bool().required(EMPTY_FIELD);

personalInformation[credifamiliaFields.CAPTCHA] = yup.string();

export default yup.object().shape(personalInformation);
