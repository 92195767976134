import { authHeader, handleResponse } from '../../../../shared/infrastructure/api/apiHandler';
import { urlUpdateDetailsInformation, urlGetDetailsInformation, urlReadingConfirmation } from './backend-urls';

export const saveUserDetails = (id, partnerId, data = {}) => {
	const requestOptions = {
		method: 'PATCH',
		headers: authHeader(),
		body: JSON.stringify(data),
	};
	return fetch(urlUpdateDetailsInformation(id, partnerId), requestOptions).then(handleResponse);
};

export const fetchUserDetails = (id, partnerId) => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};
	return fetch(urlGetDetailsInformation(id, partnerId), requestOptions).then(handleResponse);
};

export const fetchReadingConfirmation = (id) => {
	const requestOptions = {
		method: 'PATCH',
		headers: authHeader(),
	};
	return fetch(urlReadingConfirmation(id), requestOptions).then(handleResponse);
};
