import React from 'react';
import PropTypes from 'prop-types';
import ProgressBarComponent from '../../../../../shared/presentation/components/utils/ProgressBarComponent';
import BackSection from '../../../../../shared/presentation/components/utils/BackSection';
import './TipCard.scss';
import BankInformation from '../../../../../shared/presentation/components/utils/BankInformation';

const TipCard = ({
	children,
	subTitle,
	banner,
	infoCredifamilia,
	text,
	variant,
	currentTipStep,
	totalStep,
	onClick,
}) => {
	return (
		<div className={`tip-card-container ${variant}`}>
			<div className="return-section">
				<BackSection onClick={onClick} />
			</div>

			<BankInformation
				logo={infoCredifamilia?.logo}
				responseTime={infoCredifamilia?.responseTime}
				validityPreApproved={infoCredifamilia?.validityPreApproved}
			/>

			<div className="banner-container">
				<img src={banner} className="banner animate__fadeIn" />
			</div>
			<ProgressBarComponent
				title="¡APRENDE MÁS!"
				showActualStep={true}
				actualStep={currentTipStep}
				totalSteps={totalStep}
			></ProgressBarComponent>

			<div className="tip-card-text">
				<h2 className="title">{subTitle}</h2>
				<p className="text">{text}</p>
			</div>

			<div className="children">{children}</div>
		</div>
	);
};

TipCard.propTypes = {
	currentTipStep: PropTypes.number,
	totalStep: PropTypes.number,
	subTitle: PropTypes.string,
	banner: PropTypes.string,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	infoCredifamilia: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	variant: PropTypes.string,
	children: PropTypes.node,
	onClick: PropTypes.func,
};

TipCard.defaultProps = {
	currentTipStep: 0,
	totalStep: 0,
	subTitle: '',
	banner: '',
	text: '',
	variant: '',
	onClick: () => {},
};

export default TipCard;
