import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button } from '@vecindario/vecindario-suite-components';
import InformativePageWrapper from '../../../../../shared/presentation/pages/InformativePageWrapper';
import { CREDIFAMILIA_LOGO, FINANTIAL_INFORMATION_IMAGE } from '../../../../../shared/application/constants/images';
import { selectCurrentUserName } from '../../../../authentication/application/selectors/user';
import './DeniedPreApproved.scss';
import Divider from '../../../../../shared/presentation/components/utils/Divider';
import { getPreApprovedResponse } from '../../../infrastructure/api/personal-information';
import { history } from '../../../../../shared/application/helpers/history';
import { homeRoute } from '../../../../home/infrastructure/routing/routes';

const DeniedPreApproved = () => {
	const userName = useSelector(selectCurrentUserName);
	const { pre_approved_id } = useParams();

	useEffect(() => {
		getPreApprovedResponse(pre_approved_id).catch((error) => {
			if (error) {
				history.push(homeRoute);
			}
		});
	}, [pre_approved_id]);

	const InformativeDeniedSection = () => {
		return (
			<div className="denied-informative-section">
				Gracias por realizar la solicitud con nosotros, actualmente no cumples con las políticas internas de nuestro aliado
				para acceder al preaprobado.
			</div>
		);
	};

	return (
		<InformativePageWrapper banner={FINANTIAL_INFORMATION_IMAGE} name={`${userName},`} variant="denied-container">
			<InformativeDeniedSection />
			<Divider />
			<div className="denied-title-section">
				<p className="text">¡No te desanimes!</p>
				<p className="description">Puedes empezar un ahorro</p>
			</div>

			<img alt="credifamilia-logo" className="denied-logo" src={CREDIFAMILIA_LOGO} />

			<div className="text-denied-section">
				<p className="text">
					También te queremos contar que en alianza con Credifamilia, hemos diseñado una cuenta de ahorros que permite
					duplicar el ahorro que hagas utilizando el baneficio que el gobierno nacional ha creado, el Semillero de
					Propietarios.
				</p>

				<p className="text">
					¿Quieres saber los términos y condiciones? Ingresa ahora y empieza a ahorrar. Después de unos meses podremos
					mostrarle al banco que tu situción ha cambiado y que tienes un hábito de ahorro y una capacidad de pago constante.
				</p>
			</div>

			<Button type="submit" className="denied-button">
				Conoce más
			</Button>
		</InformativePageWrapper>
	);
};

export default DeniedPreApproved;
