import { UnauthenticatedRoute } from '../../../../shared/presentation/redirect-route';
import ThankYouRequestBanks from '../../presentation/Components/RequestForm/thankYouRequestBanks';
import LoadingRequestBanks from '../../presentation/Components/RequestForm/LoadingRequestBanks';
import BankRequest from '../../presentation/pages/BankRequest';
import { bankRequestRoute, successDataSentBanksRoute, LoadingSentBanksRoute, bankRequestProjectRoute } from './routes';

const banksRequestRouter = {
	router: [
		{
			path: bankRequestRoute(),
			page: BankRequest,
			routeComponent: UnauthenticatedRoute,
			exact: true,
		},
		{
			path: bankRequestProjectRoute(),
			page: BankRequest,
			routeComponent: UnauthenticatedRoute,
			exact: true,
		},
		{
			path: successDataSentBanksRoute(),
			page: ThankYouRequestBanks,
			routeComponent: UnauthenticatedRoute,
			exact: true,
		},
		{
			path: LoadingSentBanksRoute(),
			page: LoadingRequestBanks,
			routeComponent: UnauthenticatedRoute,
			exact: true,
		},
	],
};

export default banksRequestRouter;
