import { combineReducers } from 'redux';
import user, { initialState as userInitial } from '../../../domains/authentication/application/slices/user';
import home, { initialState as homeInitial } from '../../../domains/home/application/slices/home';
import adminLayout, { initialState as adminLayoutInitial } from '../slices/adminLayout';
import creditRequest, {
	initialState as requestInitial,
} from '../../../domains/creditRequest/application/slices/credit-request';

import bankRequest, {
	initialState as bankRequestInitial,
} from '../../../domains/bankRequest/infrastructure/slices/bankRequest';

export const initialStates = {
	user: userInitial,
	home: homeInitial,
	adminLayout: adminLayoutInitial,
	creditRequest: requestInitial,
	bankRequest: bankRequestInitial,
};

export default combineReducers({
	user,
	home,
	adminLayout,
	creditRequest,
	bankRequest,
});
