import { authHeader, handleResponse } from '../../../../shared/infrastructure/api/apiHandler';
import { urlGetActiveBanks, urlGetBanks } from './backend-urls';

export const getBanks = (slug = null) => {
	const urlAPI = slug ? urlGetActiveBanks(slug) : urlGetBanks;
	const requestOptions = {
		method: 'Get',
		headers: authHeader(),
	};
	return fetch(urlAPI, requestOptions).then(handleResponse);
};

export default {};
