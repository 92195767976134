import React from 'react';
import PropTypes from 'prop-types';
import { Icon, TextTag } from '@vecindario/vecindario-suite-components';
import { ICON_CALENDAR, ICON_CLOCK } from '../../../../application/constants/icons';
import './BankInformation.scss';

const BankInformation = ({ logo, responseTime, validityPreApproved }) => {
	return (
		<div className="bank-information-wrapper">
			<img src={logo} alt="" className="bank-logo" />
			<section className="section-information">
				<InformationRow responseTime={responseTime} />
				<InformationRow validityPreApproved={validityPreApproved} />
			</section>
		</div>
	);
};

BankInformation.propTypes = {
	logo: PropTypes.string,
	responseTime: PropTypes.string,
	validityPreApproved: PropTypes.string,
};

const InformationRow = ({ responseTime, validityPreApproved }) => {
	const icon = responseTime ? ICON_CLOCK : ICON_CALENDAR;

	return (
		<div className="container-information-row">
			<div className="container-icon">
				<Icon icon={icon} aditionalClassName="icon" />
			</div>
			<TextTag variant="-body-sm" className="reponse-time">
				{responseTime ? 'Tiempo de respuesta: ' : 'Vigencia de preaprobado: '}
				<TextTag tag="span" variant="-body-sm" className="time">
					{responseTime || validityPreApproved}
				</TextTag>
			</TextTag>
		</div>
	);
};

InformationRow.propTypes = {
	responseTime: PropTypes.string,
	validityPreApproved: PropTypes.string,
};

export default BankInformation;
