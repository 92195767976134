import { INFORMATIVE_SECTION_1, INFORMATIVE_SECTION_2 } from '../../../../../shared/application/constants/images';

export default [
	{
		banner: INFORMATIVE_SECTION_1,
		mainText: '¿Qué es un preaprobado?',
		description:
			'Es una suma de dinero que el banco ofrece de acuerdo a la calificación que tiene de sus clientes según su comportamiento financiero, buen historial y capacidad de pago. (No es un crédito aprobado)',
	},
	{
		banner: INFORMATIVE_SECTION_2,
		mainText: '¿Por qué es importante?',
		description:
			'Algunas constructoras exigen el preaprobado para cerrar el negocio, además te ayuda a determinar si tienes capacidad financiera, y si puedes asumir el endeudamiento. Con un preaprobado tienes una alta probabilidad que el proceso de solicitud del crédito sea más fácil.',
	},
];
