import React, { useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { VECINDARIO_DARK_LOGO } from '../../../../../shared/application/constants/images';
import { PENDING_THANK_YOU, REJECTED_THANK_YOU, SUCCESS_THANK_YOU } from '../../../application/constants/thank-you';
import {
	pendingRequestThankYouRoute,
	rejectedRequestThankYouRoute,
	successRequestThankYouRoute,
} from '../../../infrastructure/routing/routes';
import './CheckingRequest.scss';
import { getPreApprovedResponse } from '../../../infrastructure/api/personal-information';
import {
	PRE_APPROVED_STAGE_APPROVED,
	PRE_APPROVED_STAGE_REJECTED,
} from '../../../../../shared/application/constants/pre-approved-data';
import { updatePreApprovedResult } from '../../../application/slices/credit-request';
import { PRE_APPROVED_RESULT_KEY } from '../../../application/constants/local-storage';
import { selectPreApprovedResponse } from '../../../application/selectors/credit-request';

const CheckingRequest = () => {
	const [requestStatus, setRequestStatus] = useState('');
	const timerRef = useRef(null);
	const intervalRef = useRef(null);
	const history = useHistory();
	const preApprovedResponse = useSelector(selectPreApprovedResponse);
	const dispatch = useDispatch();

	useEffect(() => {
		timerRef.current = setTimeout(() => {
			setRequestStatus(PENDING_THANK_YOU);
		}, 60000);

		return () => {
			timerRef.current && clearTimeout(timerRef.current);
		};
	}, []);

	useEffect(() => {
		intervalRef.current = setInterval(() => {
			getPreApprovedResponse(preApprovedResponse?.id).then((data) => {
				const preApprovedStage = data.pre_approved_response.stage;
				if (preApprovedStage === PRE_APPROVED_STAGE_APPROVED) {
					setRequestStatus(SUCCESS_THANK_YOU);
					dispatch(updatePreApprovedResult(data.pre_approved_response));
					localStorage.setItem(PRE_APPROVED_RESULT_KEY, JSON.stringify(data.pre_approved_response));
				} else if (preApprovedStage === PRE_APPROVED_STAGE_REJECTED) setRequestStatus(REJECTED_THANK_YOU);
			});
		}, 9800);

		return () => {
			intervalRef.current && clearTimeout(intervalRef.current);
		};
	}, [dispatch, preApprovedResponse]);

	useEffect(() => {
		if (requestStatus) {
			switch (requestStatus) {
				case SUCCESS_THANK_YOU:
					history.replace(successRequestThankYouRoute);
					break;
				case REJECTED_THANK_YOU:
					history.replace(rejectedRequestThankYouRoute);
					break;
				default:
					history.replace(pendingRequestThankYouRoute);
			}
		}
	}, [requestStatus, history]);

	return (
		<section className="checking-request">
			<header className="header">
				<img src={VECINDARIO_DARK_LOGO} alt="Vecindario-suite" />
			</header>
			<div className="content">
				<div className="dot-spin"></div>
				<h2 className="main-text">Un momento por favor</h2>
			</div>
		</section>
	);
};

export default CheckingRequest;
