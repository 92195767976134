import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm, useController } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	IconBadge,
	Icon,
	Input,
	PhoneInput,
	NumberFormat,
	TextTag,
	Checkbox,
	Button,
	MessageError,
} from '@vecindario/vecindario-suite-components';
import { personalBankInformationFields } from '../../../application/constants/formInformation';
import { bankInformationSchema } from '../../../application/schemas/bank-Information';
import { createBreapprovedBanks } from '../../../infrastructure/api/banks-request-information';
import './RequestForm.scss';
import LoadingRequestBanks from './LoadingRequestBanks';
import { successDataSentBanksRoute } from '../../../infrastructure/routing/routes';
import { setUserInfo } from '../../../infrastructure/slices/bankRequest';
import { dataProcessingPoliciesRoute } from '../../../../../shared/infrastructure/routing/routes';
import { setValueToLocalStorage } from '../../../../../shared/application/helpers/common-functions';
import { PROJECT_SLUG } from '../../../../../shared/application/constants/pre-approved-data';

const RequestForm = ({ slug_bank, slug }) => {
	const [check1, setCheck1] = useState(false);
	const [check2, setCheck2] = useState(false);
	const [formError, setFormError] = useState('');
	const inputFrontDocument = useRef();
	const inputBackDocument = useRef();
	const inputWorkingLetter = useRef();
	const dispatch = useDispatch();
	const {
		register,
		control,
		formState: { errors },
		handleSubmit,
	} = useForm({
		resolver: yupResolver(bankInformationSchema),
		mode: 'onChange',
	});

	const { field: phoneinput } = useController({ control, name: personalBankInformationFields.CELLPHONE });
	const { field: phone } = useController({ control, name: personalBankInformationFields.PHONE });
	const { field: amount } = useController({ control, name: personalBankInformationFields.AMOUNT });
	const [dataFiles, setDataFiles] = useState([]);
	const [stateFilesFrontDoc, setStateFilesFrontDoc] = useState(0);
	const [stateFilesBackDoc, setStateFilesBackDoc] = useState(0);
	const [stateFilesWorking, setStateFilesWorking] = useState(0);
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	const onSubmit = (data) => {
		const values = { ...data, slug_bank, slug };
		const formData = new FormData();
		Object.keys(values).forEach((field) => formData.append(field, values[field]));
		dataFiles.forEach((input) => formData.append(input.name, input.inputFile));
		dispatch(setUserInfo(data.first_name));

		setLoading(true);
		createBreapprovedBanks(formData)
			.then((response) => {
				if (response.ok) {
					setValueToLocalStorage(PROJECT_SLUG, slug);
					history.push(successDataSentBanksRoute(slug_bank));
				} else {
					setFormError('No hemos podido enviar tu solicitud, contacta con tu asesor.');
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleCheckOption1 = (checked) => {
		setCheck1(checked);
	};
	const handleCheckOption2 = (checked) => {
		setCheck2(checked);
	};

	const handleLoad = (e, input) => {
		e.preventDefault();
		input?.current?.click();
	};

	const handleChange = (e) => {
		e.preventDefault();
		let files;
		if (e.target) files = e.target.files;
		else if (e.dataTransfer) files = e.dataTransfer.files;
		if (files.length !== 0) {
			if (files[0].size > 6 * 1024 * 1024) {
				if (personalBankInformationFields.FRONTDOCUMENT === e.target.name) {
					setStateFilesFrontDoc(2);
				} else if (personalBankInformationFields.BACKDOCUMENT === e.target.name) {
					setStateFilesBackDoc(2);
				} else if (personalBankInformationFields.WORKINGLETTER === e.target.name) {
					setStateFilesWorking(2);
				}
			} else {
				const arrayFiles = dataFiles;
				arrayFiles.push({ name: e.target.name, inputFile: files[0] });
				setDataFiles(arrayFiles);

				if (personalBankInformationFields.FRONTDOCUMENT === e.target.name) {
					setStateFilesFrontDoc(1);
				} else if (personalBankInformationFields.BACKDOCUMENT === e.target.name) {
					setStateFilesBackDoc(1);
				} else if (personalBankInformationFields.WORKINGLETTER === e.target.name) {
					setStateFilesWorking(1);
				}
			}
		}
	};

	const handleRemoveError = (e, name) => {
		e.preventDefault();
		if (personalBankInformationFields.FRONTDOCUMENT === name) {
			setStateFilesFrontDoc(0);
		} else if (personalBankInformationFields.BACKDOCUMENT === name) {
			setStateFilesBackDoc(0);
		} else if (personalBankInformationFields.WORKINGLETTER === name) {
			setStateFilesWorking(0);
		}
	};

	return (
		<>
			{loading ? <LoadingRequestBanks /> : null}
			<form className="banks-request-form-group" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
				<div className="initial-from-group">
					<div className="form-control">
						<Input
							label="Nombres"
							type="text"
							{...register(personalBankInformationFields.NAME)}
							error={errors[personalBankInformationFields.NAME]?.message}
						/>
					</div>
					<div className="form-control">
						<Input
							label="Apellidos"
							type="text"
							{...register(personalBankInformationFields.LASTNAME)}
							error={errors[personalBankInformationFields.LASTNAME]?.message}
						/>
					</div>
					<div className="form-control">
						<PhoneInput label="Celular" {...phoneinput} error={errors[personalBankInformationFields.CELLPHONE]?.message} />
					</div>
					<div className="form-control">
						<Input
							label="Correo electrónico"
							type="email"
							{...register(personalBankInformationFields.EMAIL)}
							error={errors[personalBankInformationFields.EMAIL]?.message}
						/>
					</div>
					<div className="form-control">
						<Input
							type="number"
							label="Teléfono (opcional)"
							{...phone}
							error={errors[personalBankInformationFields.PHONE]?.message}
						/>
					</div>
					<div className="form-control">
						<Input
							label="Dirección de residencia"
							type="text"
							{...register(personalBankInformationFields.ADDRESS)}
							error={errors[personalBankInformationFields.ADDRESS]?.message}
						/>
					</div>
					<div className="form-control">
						<TextTag tag="label" variant="-body-sm" font="DM-sans" className="color-secondary-500t">
							Valor del monto a solicitar
						</TextTag>
						<NumberFormat placeholder="$" {...amount} error={errors[personalBankInformationFields.AMOUNT]?.message} />
					</div>
				</div>

				<div className="credit-warning">
					<p>
						Puedes adjuntar los documentos a continuación o enviarlos a uno de nuestros asesores una vez te contactemos.
						Recuerda que los documentos deben ser legibles
					</p>
				</div>

				<div className="file-from-group">
					<div className="form-file">
						{stateFilesFrontDoc === 0 && (
							<div className="content">
								<div className="left">
									<TextTag tag="p" variant="-body-sm" font="DM-sans" fw="bold" className="color-secondary-500t">
										Documento de identidad
									</TextTag>
									<TextTag tag="p" variant="-body-xs" font="DM-sans" fw="normal" className="color-secondary-500t">
										Vista frontal
									</TextTag>
								</div>
								<div className="right">
									<input
										{...register(personalBankInformationFields.FRONTDOCUMENT)}
										type="file"
										className="hide"
										ref={inputFrontDocument}
										accept=".jpg,.jpeg,.png,.pdf"
										onChange={handleChange}
									/>
									<Button onClick={(event) => handleLoad(event, inputFrontDocument)} variant="bordered">
										Adjuntar
									</Button>
								</div>
							</div>
						)}
						{stateFilesFrontDoc === 1 && (
							<div className="content cont-success">
								<div className="left">
									<IconBadge icon="ri-check-line" size="small" bgColor="bg-success" iconColor="color-white" />
								</div>
								<div className="center">
									<TextTag tag="p" variant="-body-sm" font="DM-sans" fw="bold" className="color-secondary-500t">
										Documento de identidad
									</TextTag>
									<TextTag tag="p" variant="-body-xs" font="DM-sans" fw="normal" className="color-secondary-500t">
										Vista frontal
									</TextTag>
								</div>
								<div className="right">
									<Button onClick={(event) => handleRemoveError(event, personalBankInformationFields.FRONTDOCUMENT)}>
										<TextTag tag="p" variant="-body-xs" font="DM-sans" fw="normal" className="color-secondary-700t">
											EDITAR
										</TextTag>
										<Icon icon="ri-draft-line" size="small" aditionalClassName="color-secondary-700t" />
									</Button>
								</div>
							</div>
						)}

						{stateFilesFrontDoc === 2 && (
							<div className="content cont-error">
								<div className="left">
									<IconBadge icon="ri-close-line" size="small" bgColor="bg-error" iconColor="color-white" />
								</div>

								<div className="center">
									<TextTag tag="p" variant="-body-sm" font="DM-sans" fw="bold" className="color-secondary-500t">
										Documento de identidad
									</TextTag>
									<TextTag tag="p" variant="-body-xs" font="DM-sans" fw="normal" className="color-secondary-500t">
										Vista frontal
									</TextTag>
									<TextTag tag="p" variant="-body-xs" font="DM-sans" fw="normal" className="color-error">
										Error: el archivo sobrepasa el peso máximo (6MB)
									</TextTag>
								</div>

								<div className="right">
									<Button onClick={(event) => handleRemoveError(event, personalBankInformationFields.FRONTDOCUMENT)}>
										<TextTag tag="p" variant="-body-xs" font="DM-sans" fw="normal" className="color-secondary-700t">
											EDITAR
										</TextTag>
										<Icon icon="ri-draft-line" size="small" aditionalClassName="color-secondary-700t" />
									</Button>
								</div>
							</div>
						)}
					</div>

					<div className="form-file">
						{stateFilesBackDoc === 0 && (
							<div className="content">
								<div className="left">
									<TextTag tag="p" variant="-body-sm" font="DM-sans" fw="bold" className="color-secondary-500t">
										Documento de identidad
									</TextTag>
									<TextTag tag="p" variant="-body-xs" font="DM-sans" fw="normal" className="color-secondary-500t">
										Vista posterior
									</TextTag>
								</div>
								<div className="right">
									<input
										{...register(personalBankInformationFields.BACKDOCUMENT)}
										type="file"
										className="hide"
										ref={inputBackDocument}
										accept=".jpg,.jpeg,.png,.pdf"
										onChange={handleChange}
									/>
									<Button onClick={(event) => handleLoad(event, inputBackDocument)} variant="bordered">
										Adjuntar
									</Button>
								</div>
							</div>
						)}

						{stateFilesBackDoc === 1 && (
							<div className="content cont-success">
								<div className="left">
									<IconBadge icon="ri-check-line" size="small" bgColor="bg-success" iconColor="color-white" />
								</div>

								<div className="center">
									<TextTag tag="p" variant="-body-sm" font="DM-sans" fw="bold" className="color-secondary-500t">
										Documento de identidad
									</TextTag>
									<TextTag tag="p" variant="-body-xs" font="DM-sans" fw="normal" className="color-secondary-500t">
										Vista posterior
									</TextTag>
								</div>

								<div className="right">
									<Button onClick={(event) => handleRemoveError(event, personalBankInformationFields.BACKDOCUMENT)}>
										<TextTag tag="p" variant="-body-xs" font="DM-sans" fw="normal" className="color-secondary-700t">
											EDITAR
										</TextTag>
										<Icon icon="ri-draft-line" size="small" aditionalClassName="color-secondary-700t" />
									</Button>
								</div>
							</div>
						)}

						{stateFilesBackDoc === 2 && (
							<div className="content cont-error">
								<div className="left">
									<IconBadge icon="ri-close-line" size="small" bgColor="bg-error" iconColor="color-white" />
								</div>

								<div className="center">
									<TextTag tag="p" variant="-body-sm" font="DM-sans" fw="bold" className="color-secondary-500t">
										Documento de identidad
									</TextTag>
									<TextTag tag="p" variant="-body-xs" font="DM-sans" fw="normal" className="color-secondary-500t">
										Vista posterior
									</TextTag>
									<TextTag tag="p" variant="-body-xs" font="DM-sans" fw="normal" className="color-error">
										Error: el archivo sobrepasa el peso máximo (6MB)
									</TextTag>
								</div>

								<div className="right">
									<Button onClick={(event) => handleRemoveError(event, personalBankInformationFields.BACKDOCUMENT)}>
										<TextTag tag="p" variant="-body-xs" font="DM-sans" fw="normal" className="color-secondary-700t">
											EDITAR
										</TextTag>
										<Icon icon="ri-draft-line" size="small" aditionalClassName="color-secondary-700t" />
									</Button>
								</div>
							</div>
						)}
					</div>

					{slug_bank !== 'colpatria' && slug_bank !== 'banco-popular' && (
						<div className="form-file">
							{stateFilesWorking === 0 && (
								<div className="content">
									<div className="left">
										<TextTag tag="p" variant="-body-sm" font="DM-sans" fw="bold" className="color-secondary-500t">
											Carta laboral
										</TextTag>
									</div>
									<div className="right">
										<input
											{...register(personalBankInformationFields.WORKINGLETTER)}
											type="file"
											className="hide"
											ref={inputWorkingLetter}
											accept=".jpg,.jpeg,.png,.pdf"
											onChange={handleChange}
										/>
										<Button onClick={(event) => handleLoad(event, inputWorkingLetter)} variant="bordered">
											Adjuntar
										</Button>
									</div>
								</div>
							)}

							{stateFilesWorking === 1 && (
								<div className="content cont-success">
									<div className="left">
										<div className="icon">
											<IconBadge icon="ri-check-line" size="small" bgColor="bg-success" iconColor="color-white" />
										</div>
										<TextTag tag="p" variant="-body-sm" font="DM-sans" fw="bold" className="color-secondary-500t">
											Carta laboral
										</TextTag>
									</div>

									<div className="right">
										<Button onClick={(event) => handleRemoveError(event, personalBankInformationFields.WORKINGLETTER)}>
											<TextTag tag="p" variant="-body-xs" font="DM-sans" fw="normal" className="color-secondary-700t">
												EDITAR
											</TextTag>
											<Icon icon="ri-draft-line" size="small" aditionalClassName="color-secondary-700t" />
										</Button>
									</div>
								</div>
							)}

							{stateFilesWorking === 2 && (
								<div className="content cont-error">
									<div className="left">
										<IconBadge icon="ri-close-line" size="small" bgColor="bg-error" iconColor="color-white" />
									</div>

									<div className="center">
										<TextTag tag="p" variant="-body-sm" font="DM-sans" fw="bold" className="color-secondary-500t">
											Carta laboral
										</TextTag>
										<TextTag tag="p" variant="-body-xs" font="DM-sans" fw="normal" className="color-error">
											Error: el archivo sobrepasa el peso máximo (6MB)
										</TextTag>
									</div>

									<div className="right">
										<Button onClick={(event) => handleRemoveError(event, personalBankInformationFields.WORKINGLETTER)}>
											<TextTag tag="p" variant="-body-xs" font="DM-sans" fw="normal" className="color-secondary-700t">
												EDITAR
											</TextTag>
											<Icon icon="ri-draft-line" size="small" aditionalClassName="color-secondary-700t" />
										</Button>
									</div>
								</div>
							)}
						</div>
					)}

					<div className="form-control">
						<Checkbox
							className="check-box-form"
							label="Acepto ser consultado ante centrales de riesgo para entender mejor mi
                            perfil y determinar el banco que más se ajuste a mis características."
							labelPosition="right"
							onChange={(e) => handleCheckOption1(e.target.checked)}
						/>

						<div className="data-treatment">
							<Checkbox
								className="check-box-form"
								label="Acepto ser consultado ante centrales de riesgo para entender mejor mi perfil y determinar el banco que más se ajuste a mis características."
								labelPosition="left"
								onChange={(e) => handleCheckOption2(e.target.checked)}
							/>

							<div className="text-treatment">
								<p>
									Acepto las{' '}
									<a href={dataProcessingPoliciesRoute} target="_blank" rel="noreferrer">
										Políticas de Tratamiento de Datos
									</a>
								</p>
							</div>
						</div>
						{formError && <MessageError message={formError} className="form-error" />}
					</div>
				</div>

				<div className="form-action">
					<Button type="submit" text="Solicitar preaprobado" disabled={check1 === false || check2 === false} />
				</div>
			</form>
		</>
	);
};

RequestForm.propTypes = {
	slug_bank: PropTypes.string,
	slug: PropTypes.string,
};

export default RequestForm;
