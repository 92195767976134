import React, { useState, useEffect } from 'react';
import { Button, PhoneInput, Input, Select, DatePicker, MessageError } from '@vecindario/vecindario-suite-components';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import {
	EMPLOYMENT,
	INDEPENDENT_ACTIVITIES,
	EMPLOYEE_ACTIVITIES,
	CONTRACT_TYPE,
	CIVIL_STATUS,
	DEPENDENTS,
	YES_NO_OPTION,
	EMPLOYMENT_EMPLOYEE,
	EMPLOYMENT_PENSIONER,
	EMPLOYMENT_INDEPENDENT,
} from '../../../../../../shared/application/constants/form-options';
import { personalInformationFields } from '../../../../application/constants/finantialInformation';
import personalInformationSchema from '../../../../application/schemas/personal-information';
import {
	getErrorMessage,
	isEmptyObject,
	removeEmptyFields,
} from '../../../../../../shared/application/helpers/common-functions';
import {
	ownerInformationData,
	selectCities,
	selectDataLists,
	selectOwnerPersonalInformationErrors,
	selectPreApprovedDetail,
	selectPreApprovedResponse,
} from '../../../../application/selectors/credit-request';
import { financialInformationRoute } from '../../../../infrastructure/routing/routes';
import { fetchOwnerInformation, updateSuccessfulSave } from '../../../../application/slices/credit-request';
import { saveUserDetails } from '../../../../infrastructure/api/associates-information';
import {
	EMPTY_CAPTCHA_ERROR,
	SERVER_SIDE_ERROR,
} from '../../../../../../shared/application/constants/messages/error-messages';
import './PersonalForm.scss';
import { REQUEST_STEP_KEY } from '../../../../application/constants/local-storage';
import { isUserLogged } from '../../../../../authentication/application/selectors/user';

const PersonalForm = () => {
	const [loadingSave, setLoadingSave] = useState(false);
	const [requestError, setRequestError] = useState('');

	const history = useHistory();
	const cities = useSelector(selectCities);
	const dataLists = useSelector(selectDataLists);
	const preApproved = useSelector(selectPreApprovedDetail);
	const ownerInformation = useSelector(ownerInformationData);
	const validationErrors = useSelector(selectOwnerPersonalInformationErrors);
	const hasUser = useSelector(isUserLogged);
	const dispatch = useDispatch();
	const preApprovedResponse = useSelector(selectPreApprovedResponse);

	const currentPreApproved = preApprovedResponse || preApproved;

	const {
		handleSubmit,
		register,
		formState: { errors, isDirty },
		watch,
		setValue,
		setError,
		reset,
	} = useForm({
		resolver: yupResolver(personalInformationSchema),
		mode: 'onChange',
		defaultValues: {},
		shouldUseNativeValidation: false,
	});

	useEffect(() => {
		if (!isEmptyObject(ownerInformation)) {
			const extraInformation = removeEmptyFields(ownerInformation.extra_information);
			const details = removeEmptyFields({ ...ownerInformation, extra_information: null, id: null });
			const initialValues = { ...details, ...extraInformation };
			Object.keys(initialValues).forEach((key) => {
				setValue(key, initialValues[key]);
			});
		}
	}, [ownerInformation, setValue]);

	useEffect(() => {
		Object.keys(validationErrors).forEach((key) => setError(key, { type: 'manual', message: validationErrors[key] }));
		if (validationErrors.captcha_code) setRequestError(EMPTY_CAPTCHA_ERROR);
	}, [validationErrors, setError]);

	const errorClassName = 'form-error-message';
	const currentEmployment = watch()[personalInformationFields.EMPLOYMENT];
	const disableSave = loadingSave || !isDirty || !isEmptyObject(errors);
	const typeActivityData =
		currentEmployment === EMPLOYMENT_EMPLOYEE
			? dataLists?.actividadEmpleado || INDEPENDENT_ACTIVITIES
			: dataLists?.actividadIndependiente || EMPLOYEE_ACTIVITIES;

	const saveData = () => {
		setLoadingSave(true);
		setRequestError('');
		saveUserDetails(currentPreApproved?.id, currentPreApproved?.owner, removeEmptyFields(watch()))
			.then(() => {
				dispatch(updateSuccessfulSave(true));
			})
			.catch((err) => {
				setRequestError(err?.message || SERVER_SIDE_ERROR);
			})
			.finally(() => setLoadingSave(false));
	};

	const onSubmit = (values) => {
		if (currentPreApproved.disabled) {
			history.push(financialInformationRoute);
		} else {
			saveUserDetails(currentPreApproved?.id, currentPreApproved?.owner, {
				...removeEmptyFields(values),
				step_to_complete: 2,
			})
				.then(() => {
					localStorage.setItem(REQUEST_STEP_KEY, '3');
					dispatch(fetchOwnerInformation(currentPreApproved));
					history.push(financialInformationRoute);
					reset({});
				})
				.catch((err) => {
					setRequestError(err?.message || SERVER_SIDE_ERROR);
				});
		}
	};

	const handleDateChange = (field, value) => {
		setValue(field, value, { shouldValidate: true, shouldDirty: true });
	};

	const handleSelectChange = (field, item) => {
		setValue(field, item?.value, { shouldValidate: true, shouldDirty: true });
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="personal-information-form" noValidate autoComplete="off">
			<Input
				label="Nombres"
				placeholder="Nombres"
				{...register(personalInformationFields.NAME)}
				error={getErrorMessage(personalInformationFields.NAME, errors)}
				errorClassName={errorClassName}
			/>
			<Input
				label="Apellidos"
				placeholder="Apellidos"
				{...register(personalInformationFields.LASTNAME)}
				error={getErrorMessage(personalInformationFields.LASTNAME, errors)}
				errorClassName={errorClassName}
			/>
			<label className="form-label">Fecha de nacimiento</label>
			<DatePicker
				{...register(personalInformationFields.BIRTH_DATE)}
				error={getErrorMessage(personalInformationFields.BIRTH_DATE, errors)}
				errorClassName={errorClassName}
				onDayChange={(value) => handleDateChange(personalInformationFields.BIRTH_DATE, value)}
				value={watch()[personalInformationFields.BIRTH_DATE]}
				disabledDays={{ after: new Date() }}
			/>
			<Select
				options={dataLists?.sexo}
				label="Sexo"
				id="sex"
				{...register(personalInformationFields.GENDER)}
				error={getErrorMessage(personalInformationFields.GENDER, errors)}
				errorClassName={errorClassName}
				onSelectItem={(item) => handleSelectChange(personalInformationFields.GENDER, item)}
				defaultOptionValue={watch()[personalInformationFields.GENDER]}
			/>
			<Input
				label="Correo electrónico"
				placeholder="Correo electrónico"
				type="email"
				{...register(personalInformationFields.EMAIL)}
				error={getErrorMessage(personalInformationFields.EMAIL, errors)}
				errorClassName={errorClassName}
			/>
			<PhoneInput
				{...register(personalInformationFields.PHONE_NUMBER)}
				label="Número de teléfono"
				onChange={(e) => handleDateChange(personalInformationFields.PHONE_NUMBER, e.target.value)}
				error={getErrorMessage(personalInformationFields.PHONE_NUMBER, errors)}
				errorClassName={errorClassName}
				className="phone-input-text"
				onlyCountries={['co']}
				value={watch()[personalInformationFields.PHONE_NUMBER]}
			/>
			<Select
				options={dataLists?.ocupacionPrincipal || EMPLOYMENT}
				label="Ocupación"
				id="employment"
				{...register(personalInformationFields.EMPLOYMENT)}
				error={getErrorMessage(personalInformationFields.EMPLOYMENT, errors)}
				errorClassName={errorClassName}
				onSelectItem={(item) => handleSelectChange(personalInformationFields.EMPLOYMENT, item)}
				defaultOptionValue={watch()[personalInformationFields.EMPLOYMENT]}
			/>
			{currentEmployment !== EMPLOYMENT_PENSIONER && (
				<Select
					options={typeActivityData}
					label="Tipo de actividad"
					id="activity_type"
					{...register(personalInformationFields.ACTIVITY_TYPE)}
					error={getErrorMessage(personalInformationFields.ACTIVITY_TYPE, errors)}
					errorClassName={errorClassName}
					onSelectItem={(item) => handleSelectChange(personalInformationFields.ACTIVITY_TYPE, item)}
					defaultOptionValue={watch()[personalInformationFields.ACTIVITY_TYPE]}
				/>
			)}
			{currentEmployment === EMPLOYMENT_EMPLOYEE && (
				<>
					<div className="form-control">
						<Select
							options={dataLists?.tipoContrato || CONTRACT_TYPE}
							label="Tipo de contrato"
							id="contract"
							error={getErrorMessage(personalInformationFields.CONTRACT_TYPE, errors)}
							onSelectItem={(item) => handleSelectChange(personalInformationFields.CONTRACT_TYPE, item)}
							defaultOptionValue={watch()[personalInformationFields.CONTRACT_TYPE]}
							errorClassName={errorClassName}
						/>
					</div>
					<div className="form-control">
						<label className="form-label">Fecha de ingreso laboral</label>
						<DatePicker
							onDayChange={(value) => handleDateChange(personalInformationFields.ADMISSION_DATE, value)}
							error={getErrorMessage(personalInformationFields.ADMISSION_DATE, errors)}
							value={watch()[personalInformationFields.ADMISSION_DATE]}
							disabledDays={{ after: new Date() }}
						/>
					</div>
				</>
			)}
			{[EMPLOYMENT_INDEPENDENT, EMPLOYMENT_PENSIONER].includes(currentEmployment) && (
				<div className="form-control">
					<label className="form-label">
						Fecha de inicio de {currentEmployment === EMPLOYMENT_INDEPENDENT ? 'trabajo' : 'pensión'}
					</label>
					<DatePicker
						{...register(personalInformationFields.ADMISSION_DATE)}
						error={getErrorMessage(personalInformationFields.ADMISSION_DATE, errors)}
						errorClassName={errorClassName}
						onDayChange={(value) => handleDateChange(personalInformationFields.ADMISSION_DATE, value)}
						value={watch()[personalInformationFields.ADMISSION_DATE]}
					/>
				</div>
			)}
			<Select
				options={cities}
				label="Ciudad de residencia"
				id="city"
				{...register(personalInformationFields.CITY)}
				error={getErrorMessage(personalInformationFields.CITY, errors)}
				errorClassName={errorClassName}
				onSelectItem={(item) => handleSelectChange(personalInformationFields.CITY, item)}
				defaultOptionValue={watch()[personalInformationFields.CITY]}
				showFinder
			/>
			<Select
				options={DEPENDENTS}
				label="Personas a cargo"
				id="dependents"
				{...register(personalInformationFields.DEPENDENTS)}
				error={getErrorMessage(personalInformationFields.DEPENDENTS, errors)}
				errorClassName={errorClassName}
				onSelectItem={(item) => handleSelectChange(personalInformationFields.DEPENDENTS, item)}
				defaultOptionValue={watch()[personalInformationFields.DEPENDENTS]}
			/>
			<Select
				options={dataLists?.estadoCivil || CIVIL_STATUS}
				label="Estado civil"
				id="civil_status"
				{...register(personalInformationFields.CIVIL_STATUS)}
				error={getErrorMessage(personalInformationFields.CIVIL_STATUS, errors)}
				errorClassName={errorClassName}
				onSelectItem={(item) => handleSelectChange(personalInformationFields.CIVIL_STATUS, item)}
				defaultOptionValue={watch()[personalInformationFields.CIVIL_STATUS]}
			/>
			<Select
				options={YES_NO_OPTION}
				label="¿Tienes vivienda propia?"
				id="own_home"
				{...register(personalInformationFields.OWN_HOME)}
				error={getErrorMessage(personalInformationFields.OWN_HOME, errors)}
				errorClassName={errorClassName}
				onSelectItem={(item) => handleSelectChange(personalInformationFields.OWN_HOME, item)}
				defaultOptionValue={watch()[personalInformationFields.OWN_HOME]}
			/>

			{requestError && <MessageError message={requestError} className="general-error" />}

			<div className="form-actions">
				{!currentPreApproved.disabled && hasUser && (
					<Button
						text="Guardar"
						variant="bordered"
						className="save-cta"
						type="button"
						onClick={saveData}
						disabled={disableSave || currentPreApproved.disabled}
					/>
				)}

				<Button text="Siguiente" variant="default" type="submit" className="save-cta" />
			</div>
		</form>
	);
};

export default PersonalForm;
