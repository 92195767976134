import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Footer } from '@vecindario/vecindario-suite-components';
import { PERSONAL_INITIAL_BANNER } from '../../../../shared/application/constants/images';
import BankRequestContainer from '../Components/BankRequestContainer';
import RequestForm from '../Components/RequestForm';
import { getBanks } from '../../../home/infrastructure/api/banks-information';
import { history } from '../../../../shared/application/helpers/history';
import { homeBankRoute, homeRoute } from '../../../home/infrastructure/routing/routes';
import './BankRequest.scss';
import { PROJECT_SLUG, INFO_BANK } from '../../../../shared/application/constants/pre-approved-data';
import {
	getValueFromLocalStorage,
	removeValueFromLocalStorage,
	setValueToLocalStorage,
} from '../../../../shared/application/helpers/common-functions';
import { requestProjectConfiguration } from '../../infrastructure/slices/bankRequest';
import { creditsActive, getProjectConfigurationRequested } from '../../infrastructure/selectors/bankRequest';

const BankRequest = () => {
	const dispatch = useDispatch();
	const { slug, bank_slug } = useParams();
	const projectSlug = getValueFromLocalStorage(PROJECT_SLUG) || slug;
	const infoBank = JSON.parse(getValueFromLocalStorage(INFO_BANK));
	const [bankCurrent, setBankCurrent] = useState(infoBank);
	const isCreditsActive = useSelector(creditsActive);
	const projectConfigurationRequested = useSelector(getProjectConfigurationRequested);

	useEffect(() => {
		if (projectSlug) {
			dispatch(requestProjectConfiguration(projectSlug));
		}
	}, [dispatch, projectSlug]);

	useEffect(() => {
		if (!isCreditsActive && projectConfigurationRequested) {
			history.push('/404');
		}
	}, [isCreditsActive, projectConfigurationRequested]);

	useEffect(() => {
		if (bank_slug !== bankCurrent?.slug_bank) {
			getBanks().then((resp) => {
				const bank = resp.find((bankItem) => bank_slug === bankItem.slug_bank);
				if (bank) {
					const bankToJson = JSON.stringify(bank);
					setValueToLocalStorage(INFO_BANK, bankToJson);
				}
				setBankCurrent(bank);
			});
		}
	}, [bankCurrent?.slug_bank, projectSlug, bank_slug]);

	const backBanks = () => {
		if (projectSlug) {
			return history.push(homeBankRoute(projectSlug));
		}
		removeValueFromLocalStorage(PROJECT_SLUG);
		return history.push(homeRoute);
	};

	return (
		<section className="bank-request">
			<BankRequestContainer
				logo={bankCurrent?.logo}
				title={bankCurrent?.name}
				responseTime={bankCurrent?.responseTime}
				validityPreApproved={bankCurrent?.validityPreApproved}
				banner={PERSONAL_INITIAL_BANNER}
				subTitle="Déjanos conocerte"
				text="¡Queremos hacerlo todo más sencillo para ti!
                Para empezar compártenos la siguiente información"
				onClick={backBanks}
			>
				<RequestForm slug_bank={bankCurrent?.slug_bank} name_bank={bankCurrent?.name} slug={projectSlug} />
			</BankRequestContainer>
			<Footer theme="light" />
		</section>
	);
};

export default BankRequest;
