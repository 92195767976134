import React from 'react';
import { LOGO_PREAPROVADO } from '../../../../../../shared/application/constants/images';
import './LoadingRequestBanks.scss';

const LoadingRequestBanks = () => {
	return (
		<section className="checking-request">
			<header className="header">
				<img src={LOGO_PREAPROVADO} alt="Vecindario-suite" />
			</header>
			<div className="content">
				<div className="dot-spin"></div>
				<h2 className="main-text">Un momento por favor</h2>
			</div>
		</section>
	);
};

export default LoadingRequestBanks;
