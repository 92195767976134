export const ICON_CHECK = 'ri-check-line';
export const ICON_CHECK_BLANK = 'ri-checkbox-blank-circle-fill';
export const ICON_INFORMATION = 'ri-information-line';
export const ICON_MAIL_OPEN = 'ri-mail-open-line';
export const WARNING_ERROR = 'ri-error-warning-line';
export const CLOSE_ICON = 'ri-close-line';
export const DOWNLOAD_LINE = 'ri-download-line';
export const CALENDAR_ICON = 'ri-calendar-2-line';
export const DOWNLOAD_ICON = 'ri-download-line';
export const CLOSE_ICON_LINE = 'ri-close-circle-line';
export const ICON_SPIN = 'ri-loader-3-line';

export const ICON_CALENDAR = 'ri-calendar-line';
export const ICON_CLOCK = 'ri-time-line';
