import React from 'react';
import { UnauthenticatedRoute } from '../../../../shared/presentation/redirect-route';
import {
	requestRoute,
	informationRoute,
	personalInformationRoute,
	financialInformationRoute,
	associatesInformationRoute,
	successRequestThankYouRoute,
	passedRoute,
	deniedRoute,
	pendingRequestThankYouRoute,
	rejectedRequestThankYouRoute,
	checkingRequestRoute,
} from './routes';
import RequestInformation from '../../presentation/pages/RequestInformation/RequestInformation';
import PersonalInformation from '../../presentation/pages/PersonalInformation/PersonalInformation';
import CompanionInformation from '../../presentation/pages/CompanionInformation/CompanionInformation';
import CreditRequestLayout from '../../presentation/layout/CreditRequestLayout/CreditRequestLayout';
import FinancialInformation from '../../presentation/pages/FinancialInformation/FinancialInformation';
import InitialInformation from '../../presentation/pages/InitialInformation/InitialInformation';
import PassedStatus from '../../presentation/pages/PassedPreApproved/PassedPreApproved';
import DeniedStatus from '../../presentation/pages/DeniedPreApproved/DeniedPreApproved';
import SuccessThankYou from '../../presentation/pages/SuccessThankYouPage';
import ThankYouLayout from '../../presentation/layout/ThankYouLayout';
import PendingThankYou from '../../presentation/pages/PendingThankYou';
import RejectedThankYou from '../../presentation/pages/RejectedThankYou';
import CheckingRequest from '../../presentation/pages/CheckingRequest';

const creditRequestRouter = {
	layout: CreditRequestLayout,
	router: [
		{
			path: requestRoute,
			page: InitialInformation,
			routeComponent: UnauthenticatedRoute,
			exact: true,
		},
		{
			path: informationRoute,
			page: RequestInformation,
			routeComponent: UnauthenticatedRoute,
			exact: true,
		},
		{
			path: personalInformationRoute,
			page: PersonalInformation,
			routeComponent: UnauthenticatedRoute,
			exact: true,
		},
		{
			path: financialInformationRoute,
			page: FinancialInformation,
			routeComponent: UnauthenticatedRoute,
			exact: true,
		},
		{
			path: associatesInformationRoute(),
			page: CompanionInformation,
			routeComponent: UnauthenticatedRoute,
			exact: true,
		},
		{
			path: passedRoute(),
			page: PassedStatus,
			routeComponent: UnauthenticatedRoute,
			exact: true,
		},
		{
			path: deniedRoute(),
			page: DeniedStatus,
			routeComponent: UnauthenticatedRoute,
			exact: true,
		},
		{
			path: successRequestThankYouRoute,
			page: SuccessThankYou,
			routeComponent: UnauthenticatedRoute,
			exact: true,
			layout: ThankYouLayout,
		},
		{
			path: pendingRequestThankYouRoute,
			page: PendingThankYou,
			routeComponent: UnauthenticatedRoute,
			exact: true,
			layout: ThankYouLayout,
		},
		{
			path: rejectedRequestThankYouRoute,
			page: RejectedThankYou,
			routeComponent: UnauthenticatedRoute,
			exact: true,
			layout: ThankYouLayout,
		},
		{
			path: checkingRequestRoute,
			page: CheckingRequest,
			routeComponent: UnauthenticatedRoute,
			exact: true,
			layout: ({ children }) => <>{children}</>,
		},
	],
};

export default creditRequestRouter;
