import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Divider from '../../../../../shared/presentation/components/utils/Divider';
import { myPreApprovedList } from '../../../application/selectors/credit-request';
import Card from './Card';
import { preApprovedList } from '../../../application/slices/credit-request';
import { isUserLogged } from '../../../../authentication/application/selectors/user';
import './MyPreApproved.scss';

const MyPreApproved = () => {
	const dispatch = useDispatch();
	const hasUser = useSelector(isUserLogged);
	const list = useSelector(myPreApprovedList);

	useEffect(() => {
		if (hasUser) {
			dispatch(preApprovedList());
		}
	}, [dispatch, hasUser]);

	if (!hasUser) return null;

	return (
		<>
			{list.length > 0 && (
				<>
					<div className="my-pre-approved-container">
						<label className="my-pre-approved-title">Mis preaprobados</label>
						<label className="my-pre-approved-description">
							Muy bien, ahora puedes conocer el estado de tus preaprobados
						</label>
						<div className="my-pre-approved-cards">
							{list.map((item, index) => {
								return <Card preApproved={item} key={index} preApprovedKey={index} />;
							})}
						</div>
					</div>
					<Divider />
				</>
			)}
		</>
	);
};

export default MyPreApproved;
