import { homeRoute } from '../../../../domains/home/infrastructure/routing/routes';
import { FIDUCIARY_URL } from '../../../application/constants/env';

export const rightElementData = (propertySeparation) => {
	const elementData = [
		{
			icon: 'https://fiducia-suite.s3.us-east-2.amazonaws.com/iconLeft3.png',
			text: 'Mis simulaciones',
			goTo: homeRoute,
			class: '',
			textInfo: '',
			externalLink: false,
		},
		{
			icon: 'https://fiducia-suite.s3.us-east-2.amazonaws.com/iconLeft1.png',
			text: 'Separación del inmueble',
			goTo: null,
			class: '',
			textInfo: '',
			externalLink: false,
		},
		{
			icon: `https://fiducia-suite.s3.us-east-2.amazonaws.com/iconLeft2${propertySeparation ? '' : 'Inactive'}.png`,
			text: 'Vinculación a fiduciaria',
			goTo: FIDUCIARY_URL,
			class: `${propertySeparation ? '' : ' -inactive'}`,
			textInfo: '',
			externalLink: true,
		},
		{
			icon: 'https://fiducia-suite.s3.us-east-2.amazonaws.com/lefticon4Inactive.png',
			text: 'Subsidio',
			goTo: '/subsidio',
			class: ' -inactive',
			textInfo: '',
			externalLink: true,
		},
		{
			icon: 'https://fiducia-suite.s3.us-east-2.amazonaws.com/leftIcon5Inactive.png',
			text: 'Cesantias',
			goTo: '/cesantias',
			class: ' -inactive',
			textInfo: '',
			externalLink: true,
		},
		{
			icon: 'https://fiducia-suite.s3.us-east-2.amazonaws.com/leftIcon6Inactive.png',
			text: 'Pagos cuota inicial',
			goTo: '/pagos-cuota-inicial',
			class: ' -inactive',
			textInfo: '',
			externalLink: false,
		},
		{
			icon: 'https://fiducia-suite.s3.us-east-2.amazonaws.com/leftIcon7.png',
			text: 'Credito hipotecario',
			goTo: null,
			class: '',
			textInfo: '',
			externalLink: false,
		},
	];

	return elementData;
};

export const leftElementData = (goToHome = homeRoute) => [
	{
		icon: 'https://fiducia-suite.s3.us-east-2.amazonaws.com/rightIcon1.png',
		text: 'Home',
		goTo: goToHome,
		class: ' -blue',
		externalLink: false,
	},
	{
		icon: 'https://fiducia-suite.s3.us-east-2.amazonaws.com/rightIcon4.png',
		text: 'Todo sobre Pre-aprobados',
		goTo: '/preguntas-frecuentes/#todo-sobre-pre-aprobados',
		class: '',
		externalLink: false,
	},
	{
		icon: 'https://fiducia-suite.s3.us-east-2.amazonaws.com/rightIcon5.png',
		text: 'Preguntas frecuentes',
		goTo: '/preguntas-frecuentes/#faq',
		class: '',
		externalLink: false,
	},
];
