import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, MessageError, NumberFormat, Textarea, TextTag } from '@vecindario/vecindario-suite-components';
import InformativePageWrapper from '../../../../../shared/presentation/pages/InformativePageWrapper';
import { FINANTIAL_INFORMATION_IMAGE } from '../../../../../shared/application/constants/images';
import ProgressBarComponent from '../../../../../shared/presentation/components/utils/ProgressBarComponent';
import {
	financialInformationFields,
	credifamiliaFields,
	financialDefaultValues,
} from '../../../application/constants/finantialInformation';
import { financialInformationSchema } from '../../../application/schemas/financialInformation';
import './FinantialInformationSection.scss';
import { WARNING_ERROR } from '../../../../../shared/application/constants/icons';
import AlertCard from '../../../../../shared/presentation/components/utils/AlertCard';

import {
	isPreApprovedComplete,
	ownerInformationData,
	selectCaptchaError,
	selectOwnerFinancialErrors,
	selectPreApprovedDetail,
	selectPreApprovedResponse,
	selectRequestSteps,
	selectUserAlreadyExistError,
} from '../../../application/selectors/credit-request';
import { isUserLogged, selectCurrentUserName } from '../../../../authentication/application/selectors/user';
import {
	currencyCopFormat,
	isEmptyObject,
	removeCopFormat,
} from '../../../../../shared/application/helpers/common-functions';
import { getUserBudget, saveUserBudget } from '../../../infrastructure/api/personal-information';
import { SERVER_SIDE_ERROR } from '../../../../../shared/application/constants/messages/error-messages';
import { updateFormErrors, updateSuccessfulSave } from '../../../application/slices/credit-request';
import { history } from '../../../../../shared/application/helpers/history';
import { associatesInformationRoute, checkingRequestRoute } from '../../../infrastructure/routing/routes';
import {
	CLOSE_PRE_APPROVED_MESSAGE,
	COMPLETE_PROGRESS_COMMENT,
	COMPLETE_PROGRESS_TEXT,
} from '../../../../../shared/application/constants/messages/info-message';
import CaptchaSection from '../CaptchaSection';
import {
	EMPLOYMENT_EMPLOYEE,
	EMPLOYMENT_INDEPENDENT,
	SERVICE_PROVIDER,
} from '../../../../../shared/application/constants/form-options';
import Spinner from '../../../../../shared/presentation/components/utils/Spinner';
import { CAPTCHA_TRANSACTION_ID, REQUEST_STEP_KEY } from '../../../application/constants/local-storage';

const FinancialInformationSection = () => {
	const totalSteps = useSelector(selectRequestSteps);
	const userName = useSelector(selectCurrentUserName);
	const preApproved = useSelector(selectPreApprovedDetail);
	const dispatch = useDispatch();
	const [loader, setLoader] = useState(false);
	const [formError, setFormError] = useState('');
	const [budget, setBudget] = useState({});
	const [reloadCaptcha, setReloadCaptcha] = useState(false);
	const [alertCard, SetAlertCard] = useState(false);

	const hasUser = useSelector(isUserLogged);
	const preApprovedResponse = useSelector(selectPreApprovedResponse);
	const validationErrors = useSelector(selectOwnerFinancialErrors);
	const captchaError = useSelector(selectCaptchaError);
	const alreadyExistError = useSelector(selectUserAlreadyExistError);

	const currentPreApproved = preApprovedResponse || preApproved;

	const isComplete = useSelector(isPreApprovedComplete);
	const ownerInformation = useSelector(ownerInformationData);
	const actualStep = 3;

	useEffect(() => {
		currentPreApproved.id &&
			getUserBudget(currentPreApproved.id)
				.then((response) => {
					setBudget(response[0]);
				})
				.catch((err) => setFormError(err?.message || SERVER_SIDE_ERROR));
	}, [currentPreApproved.id]);

	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
		getValues,
		watch,
		setError,
		reset,
		control,
	} = useForm({
		resolver: yupResolver(financialInformationSchema),
		mode: 'onChange',
		reValidateMode: 'onChange',
		defaultValues: financialDefaultValues,
	});

	useEffect(() => {
		if (alreadyExistError !== '') {
			setFormError(alreadyExistError);
		}
	}, [alreadyExistError]);

	useEffect(() => {
		const budgetFormat = (nameValue) => {
			return !isEmptyObject(budget) && budget[nameValue] ? currencyCopFormat(budget[nameValue]) : '';
		};

		setValue(financialInformationFields.AMOUNT_CREDIT, budgetFormat('financial_credit_amount'));
		setValue(financialInformationFields.MONTHLY_INCOME, budgetFormat('monthly_income'));
		setValue(financialInformationFields.MONTHLY_EXPENSES, budgetFormat('monthly_outcome'));
		setValue(financialInformationFields.OTHER_INCOME, budgetFormat('other_income'));
		setValue(financialInformationFields.SOURCE_OTHER_INCOME, budget[financialInformationFields.SOURCE_OTHER_INCOME]);
	}, [setValue, budget]);

	useEffect(() => {
		Object.keys(validationErrors).forEach((key) => setError(key, { type: 'manual', message: validationErrors[key] }));
	}, [setError, validationErrors]);

	const alertCardState = (value) => {
		SetAlertCard(value);
	};

	const handleButtonMessage = () => {
		const buttonText = actualStep === totalSteps ? 'Solicitar preaprobado' : 'Siguiente';
		return buttonText;
	};

	const removeFormatCurrencyData = (data, last_step = false, step_stage = '') => {
		let { amountCredit, monthlyIncome, monthlyExpenses, otherIncome } = data;
		const { source_of_other_income } = data;
		const { captcha_code, transaction_id_captcha } = data;
		amountCredit = amountCredit ? parseInt(removeCopFormat(amountCredit)) : null;
		monthlyIncome = monthlyIncome ? parseInt(removeCopFormat(monthlyIncome)) : null;
		monthlyExpenses = monthlyExpenses ? parseInt(removeCopFormat(monthlyExpenses)) : null;
		otherIncome = otherIncome ? parseInt(removeCopFormat(otherIncome)) : null;
		const newData = {
			amountCredit,
			monthlyIncome,
			monthlyExpenses,
			otherIncome,
			last_step,
			step_stage,
			captcha_code,
			transaction_id_captcha,
			owner: currentPreApproved.owner,
			source_of_other_income,
		};

		return newData;
	};

	const showCaptcha = useMemo(
		() =>
			((ownerInformation?.job === EMPLOYMENT_EMPLOYEE &&
				ownerInformation?.extra_information?.activity_type === EMPLOYMENT_EMPLOYEE) ||
				(ownerInformation?.job === EMPLOYMENT_INDEPENDENT &&
					ownerInformation?.extra_information?.activity_type === SERVICE_PROVIDER)) &&
			currentPreApproved?.partners_amount === 0 &&
			!loader &&
			(!alreadyExistError || !captchaError),
		[alreadyExistError, captchaError, currentPreApproved, loader, ownerInformation],
	);

	useEffect(() => {
		if (captchaError !== '' && showCaptcha) {
			setReloadCaptcha(false);
			setFormError(captchaError);
		}
	}, [captchaError, showCaptcha]);

	const onSubmit = (data) => {
		if (currentPreApproved.disabled) {
			history.push(associatesInformationRoute(currentPreApproved?.partners[0]));
		} else {
			const values = removeFormatCurrencyData(data, actualStep === totalSteps, 'finish');
			const captchaTransactionId = localStorage.getItem(CAPTCHA_TRANSACTION_ID);

			if (showCaptcha && watch()[credifamiliaFields.CAPTCHA] === '') {
				setFormError('*Debes diligenciar el código para poder continuar');
				return;
			}
			setReloadCaptcha(false);
			setLoader(true);
			saveUserBudget(currentPreApproved.id, {
				...values,
				transaction_id_captcha: captchaTransactionId,
			})
				.then((success) => {
					if (success?.partners?.length === 0) {
						localStorage.setItem(REQUEST_STEP_KEY, '0');
						history.push(checkingRequestRoute);
					} else {
						history.push(associatesInformationRoute(currentPreApproved?.partners[0]));
						localStorage.setItem(REQUEST_STEP_KEY, '4');
					}
					reset({});
				})
				.catch((err) => {
					if (err.owner) {
						dispatch(updateFormErrors(err));
					} else {
						setFormError(err?.message || err || SERVER_SIDE_ERROR);
					}
				})
				.finally(() => setLoader(false));
		}
	};

	const saveFinancialInformation = (e) => {
		e.preventDefault();
		const values = getValues();
		setFormError('');
		saveUserBudget(currentPreApproved.id, removeFormatCurrencyData(values, false, 'save'))
			.then(() => dispatch(updateSuccessfulSave(true)))
			.catch((err) => setFormError(err?.message || SERVER_SIDE_ERROR));
	};

	return (
		<InformativePageWrapper banner={FINANTIAL_INFORMATION_IMAGE} name={userName}>
			<div className="finantial-information-section">
				<p className="text">Estamos para acompañarte</p>
				<p className="sub-title">Para nosotros es muy importante conocer las características de tu crédito</p>

				<ProgressBarComponent
					showActualStep={true}
					actualStep={actualStep}
					totalSteps={totalSteps}
					comment={actualStep === totalSteps ? 'Estamos a punto de terminar' : CLOSE_PRE_APPROVED_MESSAGE}
					isComplete={isComplete}
					completeText={COMPLETE_PROGRESS_TEXT}
					completeComment={COMPLETE_PROGRESS_COMMENT}
				/>

				<form className="finantial-form-group" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
					<div className="companion-from-group">
						<div className="form-control">
							<TextTag variant="-body-sm" className="label">
								Monto del crédito que necesitas
							</TextTag>
							<Controller
								control={control}
								name={financialInformationFields.AMOUNT_CREDIT}
								render={({ field: { onChange, value, name }, fieldState: { error } }) => (
									<NumberFormat
										name={name}
										placeholder="Monto del crédito que necesitas"
										value={value}
										onChange={onChange}
										error={error?.message}
										showIconError={false}
									/>
								)}
							/>
						</div>

						<div className="form-control">
							<TextTag variant="-body-sm" className="label">
								Ingresos mensuales
							</TextTag>
							<Controller
								control={control}
								name={financialInformationFields.MONTHLY_INCOME}
								render={({ field: { onChange, value, name }, fieldState: { error } }) => (
									<NumberFormat
										name={name}
										placeholder="Ingresos mensuales"
										value={value}
										onChange={onChange}
										error={error?.message}
										showIconError={false}
									/>
								)}
							/>
						</div>

						<div className="form-control">
							<TextTag variant="-body-sm" className="label">
								Egresos mensuales
							</TextTag>
							<Controller
								control={control}
								name={financialInformationFields.MONTHLY_EXPENSES}
								render={({ field: { onChange, value, name }, fieldState: { error } }) => (
									<NumberFormat
										name={name}
										placeholder="Egresos mensuales"
										value={value}
										onChange={onChange}
										error={error?.message}
										showIconError={false}
									/>
								)}
							/>
						</div>

						<div className="form-control">
							<TextTag variant="-body-sm" className="label">
								Recursos propios
							</TextTag>
							<Controller
								control={control}
								name={financialInformationFields.OTHER_INCOME}
								render={({ field: { onChange, value, name }, fieldState: { error } }) => (
									<NumberFormat
										name={name}
										placeholder="Recursos propios"
										value={value}
										onChange={onChange}
										error={error?.message}
										showIconError={false}
									/>
								)}
							/>
						</div>
						<div className="form-control">
							<Controller
								control={control}
								render={({ field: { ref, value, onChange, name }, fieldState: { error } }) => (
									<Textarea
										placeholder="Origen de ingresos propios"
										rows={3}
										errorClassName="error-message"
										ref={ref}
										error={error?.message}
										value={value}
										onChange={onChange}
										name={name}
									/>
								)}
								name={financialInformationFields.SOURCE_OTHER_INCOME}
							/>
						</div>
					</div>

					<CaptchaSection
						show={showCaptcha}
						register={register}
						errors={errors}
						setValue={setValue}
						reloadCaptcha={reloadCaptcha}
					/>

					{alertCard && (
						<AlertCard
							icon={WARNING_ERROR}
							onClick={() => {
								alertCardState(false);
							}}
							text="Hacen referencia a todo el dinero que puedas reunir entre: ahorros, cesantías, parte de la cuota inicial que he dado, para hacer el estudio"
						/>
					)}
					{formError && <MessageError message={formError} className="form-error" />}
					<div className={`${currentPreApproved.disabled ? 'review' : 'form-action '}`}>
						{!currentPreApproved.disabled && hasUser && (
							<Button
								disabled={currentPreApproved.disabled}
								type="submit"
								text="Guardar"
								onClick={saveFinancialInformation}
								className="-light"
							/>
						)}
						<Button type="submit"> {loader ? <Spinner /> : handleButtonMessage()} </Button>
						{currentPreApproved.disabled && actualStep !== totalSteps && <Button type="submit" text="Siguiente" />}
					</div>
				</form>
			</div>
		</InformativePageWrapper>
	);
};

export default FinancialInformationSection;
