import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@vecindario/vecindario-suite-components';
import { updateThankYouParams } from '../../../application/slices/credit-request';
import { MAX_BUTTON_WIDTH, PENDING_THANK_YOU } from '../../../application/constants/thank-you';
import { PENDING_THANK_YOU_IMAGE } from '../../../../../shared/application/constants/images';
import { homeBankRoute, homeRoute } from '../../../../home/infrastructure/routing/routes';
import { selectOwnerNameText } from '../../../application/selectors/credit-request';
import CopyEmailForm from '../../components/CopyEmailForm';
import { getValueFromLocalStorage } from '../../../../../shared/application/helpers/common-functions';
import { PROJECT_SLUG } from '../../../../../shared/application/constants/pre-approved-data';

const PendingThankYou = () => {
	const ownerName = useSelector(selectOwnerNameText);
	const dispatch = useDispatch();
	const history = useHistory();
	const projectSlug = getValueFromLocalStorage(PROJECT_SLUG);

	useEffect(() => {
		dispatch(updateThankYouParams({ type: PENDING_THANK_YOU }));
	}, [dispatch]);

	const handleNewRequest = () => {
		if (projectSlug) {
			return history.replace(homeBankRoute(projectSlug));
		}
		return history.replace(homeRoute);
	};

	return (
		<>
			<h2 className="thank-you-user-name color-secondary-600t">{ownerName}</h2>
			<h3 className="thank-you-top-text color-secondary-600t">Estamos analizando la información</h3>
			<img src={PENDING_THANK_YOU_IMAGE} alt="solicitud-pendiente" className="thank-you-image large" />
			<p className="thank-you-description color-secondary-600t">
				En un momento tendrás el resultado de tu solicitud, esta llegará a tu correo electrónico.
			</p>
			<Button style={{ width: MAX_BUTTON_WIDTH }} onClick={handleNewRequest}>
				Realizar otra solicitud
			</Button>
			<br />
			<br />
			<br />
			<CopyEmailForm colorClassName="color-secondary-600t pending" />
		</>
	);
};

export default PendingThankYou;
