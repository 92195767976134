import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchProjectConfiguration } from '../api/project-configuration';

export const initialState = {
	userInfo: {},
	projectConfiguration: null,
	projectConfigurationRequested: false,
};

export const requestProjectConfiguration = createAsyncThunk(
	'requestProjectConfiguration',
	async (projectSlug, { rejectWithValue }) => {
		try {
			return await fetchProjectConfiguration(projectSlug);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

const BankRequest = createSlice({
	name: 'bankRequest',
	initialState,
	reducers: {
		setUserInfo(state, { payload }) {
			state.userInfo = payload;
		},
	},
	extraReducers: {
		[requestProjectConfiguration.fulfilled]: (state, { payload = [] }) => {
			state.projectConfiguration = payload;
			state.projectConfigurationRequested = true;
		},
		[requestProjectConfiguration.rejected]: (state) => {
			state.projectConfiguration = [];
			state.projectConfigurationRequested = true;
		},
	},
});

export const { setUserInfo } = BankRequest.actions;

export default BankRequest.reducer;
