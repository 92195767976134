import { authHeader, handleResponse } from './apiHandler';
import { urlGetCityList } from './backend-urls';

export const getCityList = () => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};
	return fetch(urlGetCityList, requestOptions).then(handleResponse);
};

export default { getCityList };
