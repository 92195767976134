import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@vecindario/vecindario-suite-components';
import { useHistory } from 'react-router-dom';
import { updateThankYouParams } from '../../../application/slices/credit-request';
import { REJECTED_THANK_YOU } from '../../../application/constants/thank-you';
import { REJECTED_THANK_YOU_IMAGE } from '../../../../../shared/application/constants/images';
import { homeBankRoute, homeRoute } from '../../../../home/infrastructure/routing/routes';
import { selectOwnerNameText } from '../../../application/selectors/credit-request';
import { getValueFromLocalStorage } from '../../../../../shared/application/helpers/common-functions';
import { PROJECT_SLUG } from '../../../../../shared/application/constants/pre-approved-data';

const RejectedThankYou = () => {
	const ownerName = useSelector(selectOwnerNameText);
	const dispatch = useDispatch();
	const history = useHistory();
	const projectSlug = getValueFromLocalStorage(PROJECT_SLUG);

	useEffect(() => {
		dispatch(updateThankYouParams({ type: REJECTED_THANK_YOU }));
	}, [dispatch]);

	const handleClick = () => {
		if (projectSlug) {
			return history.replace(homeBankRoute(projectSlug));
		}
		return history.replace(homeRoute);
	};

	return (
		<>
			<h2 className="thank-you-user-name color-white">{ownerName}</h2>
			<h3 className="thank-you-top-text color-white">Lo sentimos</h3>
			<img src={REJECTED_THANK_YOU_IMAGE} alt="solicitud-rechazada" className="thank-you-image" />
			<p className="thank-you-description color-white">
				La solicitud de preaprobado fue rechazada, en tu correo tendrás más detalles sobre esta decisión.
			</p>
			<Button onClick={handleClick}>Realizar otra solicitud</Button>
		</>
	);
};

export default RejectedThankYou;
