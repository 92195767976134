import React from 'react';
import { Link } from 'react-router-dom';
import { homeRoute } from '../../../infrastructure/routing/routes';
import styles from './NotFound.module.scss';

const NotFoundPage = () => {
	return (
		<section className={styles['not-found-404']}>
			<img
				src="https://s3-sa-east-1.amazonaws.com/viewinmobiliario2/static_assets/404/view-imagen-404.png"
				alt=""
				className={styles.image}
			/>
			<h1 className={`${styles.title} ${styles['fw-700']}`}>Opssss!!!</h1>
			<p className={styles.description}>
				No pudimos encontrar la página que buscabas. <br />
				<span className={`${styles['d-block']} ${styles['mt-2']}`}>Código de error: 404</span>
			</p>
			<Link to={homeRoute} className={`${styles['go-back']} ${styles['color-primary']}`}>
				Regresar
			</Link>
		</section>
	);
};

export default NotFoundPage;
