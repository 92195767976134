import React from 'react';
import PropTypes from 'prop-types';
import './AlertCard.scss';
import { CLOSE_ICON } from '../../../../application/constants/icons';

const AlertCard = ({ icon, text, variant, onClick }) => {
	return (
		<div className={`alert-card-container ${variant}`}>
			<div className="boder-contaner"></div>
			<div className="icon-contaner">
				<div className="main-icon">
					<i className={`${icon} icon`} />
				</div>
			</div>
			<div className="alert-card-text">
				<p className="text">{text}</p>
			</div>
			<i className={`${CLOSE_ICON} close-icon`} onClick={() => onClick()}></i>
		</div>
	);
};

AlertCard.propTypes = {
	icon: PropTypes.string,
	text: PropTypes.string,
	variant: PropTypes.string,
	onClick: PropTypes.func,
};

AlertCard.defaultProps = {
	icon: '',
	text: '',
	title: '',
	variant: '',
	onClick: () => {},
};

export default AlertCard;
