import React, { useState } from 'react';
import { Input, Button, MessageError, TextTag } from '@vecindario/vecindario-suite-components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { copyEmailFields } from '../../../application/constants/copy-email';
import copyEmailSchema from '../../../application/schemas/copy-email';
import { selectPreApprovedResponse } from '../../../application/selectors/credit-request';
import { postSendResultCopy } from '../../../infrastructure/api/send-result-copy';
import './CopyEmailForm.scss';
import { PRE_APPROVED_RESULT_KEY } from '../../../application/constants/local-storage';

const CopyEmailForm = ({ colorClassName = 'color-white' }) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [hide, setHide] = useState(false);

	const preApprovedResponse = useSelector(selectPreApprovedResponse);

	const {
		handleSubmit,
		register,
		formState: { errors, isDirty },
	} = useForm({
		resolver: yupResolver(copyEmailSchema),
		mode: 'onChange',
	});

	const hasErrors = Object.keys(errors).length > 0;

	const onSubmit = (values) => {
		setLoading(true);
		setError('');
		postSendResultCopy(preApprovedResponse?.id, {
			email: values[copyEmailFields.EMAIL],
		})
			.then((data) => {
				setHide(true);
				localStorage.setItem(PRE_APPROVED_RESULT_KEY, JSON.stringify(data.pre_approved));
			})
			.catch((err) => setError(err))
			.finally(() => setLoading(false));
	};

	if (!preApprovedResponse || preApprovedResponse?.result_copy_email) return null;

	return (
		<>
			{!hide ? (
				<div className={`copy-email-form ${colorClassName}`}>
					<TextTag className={colorClassName}>¿Quizás deseas recibir también esta respuesta en otro correo?</TextTag>
					<form onSubmit={handleSubmit(onSubmit)} className="form">
						<Input
							{...register(copyEmailFields.EMAIL)}
							className={`form-input ${colorClassName}`}
							placeholder="Escribe el correo electrónico"
							error={errors[copyEmailFields.EMAIL]?.message}
						/>
						<Button type="submit" disabled={!isDirty || hasErrors || loading}>
							{loading ? 'Cargando...' : 'Enviar copia del resultado'}
						</Button>
						<MessageError message={error} className="submit-error" showIcon={false} />
					</form>
				</div>
			) : (
				<div className={`copy-email-form ${colorClassName}`}>
					<TextTag>Información registrada</TextTag>
					<TextTag>¡Pronto nos contactaremos contigo!</TextTag>
				</div>
			)}
		</>
	);
};

CopyEmailForm.propTypes = {
	colorClassName: PropTypes.string,
};

export default CopyEmailForm;
