import CreditRequestLayout from '../../../domains/creditRequest/presentation/layout/CreditRequestLayout/CreditRequestLayout';
import RequestDeniedPage from '../../presentation/pages/RequestDenied/RequestDenied';
import { UnauthenticatedRoute } from '../../presentation/redirect-route';
import { dataAuthorizationRoute, dataProcessingPoliciesRoute, requestDeniedRoute } from './routes';
import DataProcessingPolicy from '../../presentation/pages/DataProcessingPolicy';
import DataAuthorization from '../../presentation/pages/DataAuthorization';

const sharedRouter = {
	layout: CreditRequestLayout,
	router: [
		{
			path: requestDeniedRoute,
			page: RequestDeniedPage,
			routeComponent: UnauthenticatedRoute,
			exact: true,
		},
		{
			path: dataProcessingPoliciesRoute,
			page: DataProcessingPolicy,
			routeComponent: UnauthenticatedRoute,
			exact: true,
		},
		{
			path: dataAuthorizationRoute,
			page: DataAuthorization,
			routeComponent: UnauthenticatedRoute,
			exact: true,
		},
	],
};

export default sharedRouter;
