import * as yup from 'yup';
import { personalInformationFields } from '../constants/finantialInformation';
import {
	EMPTY_FIELD,
	MAX_FROM_NOW_DATE,
	INVALID_EMAIL_FORMAT,
	CURRENCY_ERROR,
	INVALID_PHONE_FORMAT,
	MAX_LENGTH_FIELD,
	MESSAGE_ERROR_DOMAIN,
} from '../../../../shared/application/constants/messages/error-messages';
import {
	removeCharactersToFormatCurrency,
	validateEmailDomain,
} from '../../../../shared/application/helpers/common-functions';

const NUMERIC_REGEXP = /^\d{1,10}(\.\d{3})*(,\d+)?/;

export const companionInformation = {};
companionInformation[personalInformationFields.NAME] = yup.string().required(EMPTY_FIELD);
companionInformation[personalInformationFields.LASTNAME] = yup.string().required(EMPTY_FIELD);
companionInformation[personalInformationFields.TYPE_ID] = yup.string().required(EMPTY_FIELD);
companionInformation[personalInformationFields.NUMBER_ID] = yup
	.string()
	.max(10, MAX_LENGTH_FIELD)
	.required(EMPTY_FIELD);
companionInformation[personalInformationFields.BIRTH_DATE] = yup
	.date()
	.required(EMPTY_FIELD)
	.max(new Date(), MAX_FROM_NOW_DATE);
companionInformation[personalInformationFields.EMAIL] = yup
	.string()
	.email(INVALID_EMAIL_FORMAT)
	.test('not-con-domain', MESSAGE_ERROR_DOMAIN, (value) => validateEmailDomain(value))
	.required(EMPTY_FIELD);
companionInformation[personalInformationFields.PHONE_NUMBER] = yup
	.string()
	.required(EMPTY_FIELD)
	.matches(/^573/, INVALID_PHONE_FORMAT);
companionInformation[personalInformationFields.CITY] = yup.string().required(EMPTY_FIELD);
companionInformation[personalInformationFields.GENDER] = yup.string().required(EMPTY_FIELD);
companionInformation[personalInformationFields.EMPLOYMENT] = yup.string().required(EMPTY_FIELD);
companionInformation[personalInformationFields.BRINGS_INCOME] = yup.boolean().required(EMPTY_FIELD);
companionInformation[personalInformationFields.MONTHLY_INCOME] = yup
	.string()
	.transform((_, value) => {
		return removeCharactersToFormatCurrency(value);
	})
	.when(personalInformationFields.BRINGS_INCOME, {
		is: true,
		then: yup.string().matches(NUMERIC_REGEXP, CURRENCY_ERROR).required(EMPTY_FIELD),
	});
companionInformation[personalInformationFields.CENTRAL_CONSULTATION] = yup.bool().oneOf([true], '');
companionInformation[personalInformationFields.DATA_TREATMENT] = yup.bool().oneOf([true], '');
companionInformation[personalInformationFields.DATA_AUTHORIZATION] = yup.bool().oneOf([true], '');

export const companionInformationSchema = yup.object().shape(companionInformation);
