import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser, isUserLogged } from './domains/authentication/application/selectors/user';
import { setCurrentUser } from './domains/authentication/application/slices/user';
import { lastPreApproved } from './domains/creditRequest/application/slices/credit-request';
import Router from './shared/presentation/Router';

const App = () => {
	const dispatch = useDispatch();
	const currentUser = useSelector(getCurrentUser);
	const hasUser = useSelector(isUserLogged);

	useEffect(() => {
		if (hasUser) {
			dispatch(lastPreApproved());

			if (currentUser?.token) {
				dispatch(setCurrentUser(currentUser));
			}
		}
	}, [dispatch, hasUser, currentUser]);

	return <Router />;
};

export default App;
