import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ProgressBar } from '@vecindario/vecindario-suite-components';
import {
	CALENDAR_ICON,
	ICON_CHECK_BLANK,
	ICON_CHECK,
	CLOSE_ICON_LINE,
	DOWNLOAD_ICON,
	WARNING_ERROR,
} from '../../../../../shared/application/constants/icons';
import './MyPreApproved.scss';
import { fetchReadingConfirmation } from '../../../infrastructure/api/associates-information';
import { updateNotificationState } from '../../../application/slices/credit-request';
import {
	PRE_APPROVED_STAGE_APPROVED,
	PRE_APPROVED_STAGE_INCOMPLETE,
	PRE_APPROVED_STAGE_REJECTED,
} from '../../../../../shared/application/constants/pre-approved-data';
import { history } from '../../../../../shared/application/helpers/history';
import { deniedRoute, passedRoute } from '../../../infrastructure/routing/routes';

const Card = ({ preApproved, preApprovedKey }) => {
	const dispatch = useDispatch();
	const getIcon = () => {
		if (preApproved.stage === PRE_APPROVED_STAGE_APPROVED) return ICON_CHECK;
		if (preApproved.stage === PRE_APPROVED_STAGE_INCOMPLETE) return CLOSE_ICON_LINE;
		if (preApproved.stage === PRE_APPROVED_STAGE_REJECTED) return CLOSE_ICON_LINE;

		return ICON_CHECK;
	};

	const getStageText = () => {
		if (preApproved.stage === PRE_APPROVED_STAGE_APPROVED) return 'Aprobado';
		if (preApproved.stage === PRE_APPROVED_STAGE_INCOMPLETE) return 'Incompleto';
		if (preApproved.stage === PRE_APPROVED_STAGE_REJECTED) return 'Negado';

		return '';
	};

	const getFormatDate = () => {
		const fullDate = new Date(preApproved?.created_at);
		const month = fullDate.toLocaleString('es-es', { month: 'short' });
		const year = fullDate.toLocaleString('es-es', { year: 'numeric' });
		const day = fullDate.toLocaleString('es-es', { day: 'numeric' });
		return ` ${month} ${day} de ${year}`;
	};

	const handleClick = () => {
		fetchReadingConfirmation(preApproved.pre_approved_notification.id).then((success) => {
			dispatch(updateNotificationState(preApproved));
			if (preApproved.stage === PRE_APPROVED_STAGE_APPROVED) {
				history.push(passedRoute(preApproved.id));
			}
			if (preApproved.stage === PRE_APPROVED_STAGE_REJECTED) {
				history.push(deniedRoute(preApproved.id));
			}
		});
	};

	const openLetter = (url) => {
		window.open(url, '_blank');
	};

	return (
		<div className="card-info">
			<div className="card-header">
				{!preApproved?.pre_approved_notification?.notify_read && <i className={`${ICON_CHECK_BLANK} notification`}></i>}
				<label className="pre-approved-number">Solicitud #{preApprovedKey + 1}</label>
				<label className="pre-approved-date">
					<i className={CALENDAR_ICON}></i> <label className="date-text">{getFormatDate()}</label>
				</label>
			</div>
			<div className="card-description">
				<label className="description">Estado: {getStageText()}</label>
				<div className="check-icon">
					<i className={`${ICON_CHECK_BLANK} circle ${preApproved.stage}`}>
						<i className={`${getIcon()} check ${preApproved.stage}`} />
					</i>
				</div>
				{preApproved.stage !== 'approved' && <i className={`${WARNING_ERROR} warning-icon`}></i>}
			</div>
			<div className="card-progress">
				<label className="progress-percentage">100%</label>
				<ProgressBar
					progress={100}
					classNameFill={`mpprogress-bar-fill ${preApproved.stage}-card-color`}
					className="mpprogress-bar-container"
				/>
			</div>
			<div className="card-footer">
				<label className="footer-text" onClick={() => handleClick()}>
					{preApproved.stage === 'incomplete' ? 'Continuar' : 'Ver detalles'}{' '}
				</label>
				{preApproved.stage === 'approved' && (
					<>
						<div className="vertical"></div>
						{preApproved?.letter_path !== null && (
							<label className="footer-text" onClick={() => openLetter(preApproved?.letter_path)}>
								<i className={`${DOWNLOAD_ICON} rigth`}></i>
								Descagar
							</label>
						)}
					</>
				)}
			</div>
		</div>
	);
};

Card.propTypes = {
	preApproved: PropTypes.object,
	preApprovedKey: PropTypes.number,
};

export default Card;
