import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button } from '@vecindario/vecindario-suite-components';
import InformativePageWrapper from '../../../../../shared/presentation/pages/InformativePageWrapper';
import { PASSED_PRE_APPROVED } from '../../../../../shared/application/constants/images';
import { formatCurrency } from '../../../../../shared/application/helpers/common-functions';
import { selectCurrentUserName } from '../../../../authentication/application/selectors/user';
import Divider from '../../../../../shared/presentation/components/utils/Divider';
import './PassedPreApproved.scss';
import { DOWNLOAD_LINE } from '../../../../../shared/application/constants/icons';
import { getPreApprovedResponse } from '../../../infrastructure/api/personal-information';
import { history } from '../../../../../shared/application/helpers/history';
import { homeRoute } from '../../../../home/infrastructure/routing/routes';

const PassedPreApproved = () => {
	const [preApprovedData, setPreApprovedData] = useState({});
	const userName = useSelector(selectCurrentUserName);
	const { pre_approved_id } = useParams();

	useEffect(() => {
		getPreApprovedResponse(pre_approved_id)
			.then((success) => {
				setPreApprovedData(success);
			})
			.catch((error) => {
				if (error) {
					history.push(homeRoute);
				}
			});
	}, [pre_approved_id]);

	const BudgetPassedSection = ({ text, value }) => {
		return (
			<div className="passed-budget-section">
				<p className="description">{value}</p>
			</div>
		);
	};

	BudgetPassedSection.propTypes = {
		text: PropTypes.string,
		value: PropTypes.string,
	};

	const loanValue =
		preApprovedData?.pre_approved_response?.transaction_response?.resultadoCliente?.detalle?.montoSugeridoPreaprobado ||
		0;

	const openLetter = (url) => {
		window.open(url, '_blank');
	};

	return (
		<InformativePageWrapper banner={PASSED_PRE_APPROVED} name={`Felicidades ${userName},`}>
			<div className="passed-pre-approved-section">
				<p className="text">Tenemos un oferta para ti</p>
				<p className="description">
					Estamos muy felices de contarte que tenemos una oferta para acompañarte a cumplir tu sueño de tener vivienda
					propia, esta oferta tiene las siguientes características:
				</p>
			</div>

			<BudgetPassedSection
				text={'Total ingresos mensuales'}
				value={formatCurrency(preApprovedData?.user_snapshot_budget?.monthly_income || 0)}
			/>
			<Divider />
			<BudgetPassedSection text={'Valor del préstamo'} value={formatCurrency(parseInt(loanValue) || 0)} />
			<BudgetPassedSection text={'Plazo del préstamo'} value={'180 meses'} />

			<div className="text-pre-approved-section">
				<p className="description">
					Esta preaprobación está sujeta al estudio de crédito, a las condiciones y políticas de Credifamilia CF. En este
					orden de ideas se aclara que, dicho documento no tiene carácter vinculante para Credifamilia CF. El valor máximo de
					financiación no podrá exceder el{' '}
					<strong className="bold">80% del valor comercial para inmuebles VIS o del 70% para inmuebles NO VIS.</strong>
				</p>

				<p className="description">
					Descraga ahora aquí tu pre-aprobado, de igual manera te lo hemos enviado a tu dirección de correo electrónico
				</p>
			</div>
			{preApprovedData?.pre_approved_response?.letter_path !== null && (
				<Button
					type="button"
					className="passed-button"
					onClick={() => openLetter(preApprovedData?.pre_approved_response?.letter_path)}
				>
					{' '}
					<i className={`${DOWNLOAD_LINE} icon-button`}></i> Descagar{' '}
				</Button>
			)}
		</InformativePageWrapper>
	);
};

export default PassedPreApproved;
