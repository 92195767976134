import { createSlice } from '@reduxjs/toolkit';

export const initialState = {};

const Home = createSlice({
	name: 'home',
	initialState,
	reducers: {},
});

export default Home.reducer;
