import { authHeader, handleResponse } from '../../../../shared/infrastructure/api/apiHandler';
import { urlGetProjectConfiguration } from './backend-urls';

export const fetchProjectConfiguration = (slug) => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};
	return fetch(urlGetProjectConfiguration(slug), requestOptions).then(handleResponse);
};

export default {};
