import { URL_PROD, URL_INTEGRATIONS_PROD } from '../../../../shared/application/constants/env';

const urlBase = URL_PROD;
const urlIntegrationBase = URL_INTEGRATIONS_PROD;

// USER DETAILS
export const urlUpdateDetailsInformation = (preApprovedId = ':pre_approved_id', partnerId = ':partner_id') =>
	`${urlBase}preaprobados/${preApprovedId}/asociados/${partnerId}`;
export const urlGetDetailsInformation = (preApprovedId = ':pre_approved_id', partnerId = ':partner_id') =>
	`${urlBase}preaprobados/${preApprovedId}/usuario/${partnerId}`;

// PRE APPROVED
export const urlUpdatePersonalInformation = (preApprovedId = ':pre_approved_id') =>
	`${urlBase}preaprobado/${preApprovedId}/asociados`;
export const urlFinancialInformation = (preApprovedId = ':pre_approved_id') => `${urlBase}presupuesto/${preApprovedId}`;
export const urlPutPreApproved = (preApprovedId = ':pre_approved_id') =>
	`${urlBase}actualizar/cantidad/asociados/${preApprovedId}`;
export const urlPostPreApproved = `${urlBase}crear-preaprobado`;
export const urlPostRequest = `${urlBase}autorizar-preaprobado`;
export const urlGetLastPreApproved = `${urlBase}preaprobados/en-curso`;
export const urlPreApprovedList = `${urlBase}preaprobados`;
export const urlReadingConfirmation = (notificationId) =>
	`${urlBase}lectura/notificacion/preaprobados/${notificationId}`;
export const urlGetCaptcha = `${urlIntegrationBase}credifamilia/generar-captcha`;
export const urlPreApprovedResponse = (preApprovedId = ':pre_approved_id') =>
	`${urlBase}preaprobado/respuesta/${preApprovedId}`;

export const urlSendResultCopy = (preApprovedId = ':pre_approved_id') =>
	`${urlBase}preaprobado/${preApprovedId}/enviar-copia-resultado`;
