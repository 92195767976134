import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from '@vecindario/vecindario-suite-components';
import { PARTNER_AMOUNT } from '../../../../../shared/application/constants/images';

const ParentsAmountModal = ({ open, onClose, text }) => {
	const contentStyle = {
		backgroundColor: '#36BB7E',
		borderRadius: '8px',
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '0 3%',
		maxWidth: '650px',
	};

	const titleStyle = {
		color: 'white',
		fontWeight: '400',
		textAlign: 'center',
		fontSize: '2rem',
		lineHeight: '2.7rem',
	};

	const descriptionStyle = {
		color: 'white',
		fontWeight: '400',
		textAlign: 'center',
		fontSize: '1.7rem',
		lineHeight: '2.7rem',
		paddingBottom: '2rem',
	};

	const imageStyle = {
		width: '10vw',
		maxWidth: '150px',
		minWidth: '100px',
		marginBottom: '1rem',
	};

	return (
		<Modal isOpen={open} onClose={onClose} contentStyle={contentStyle}>
			<img src={PARTNER_AMOUNT} alt="" style={imageStyle} />
			<h1 style={titleStyle}>¡Tu progreso ha sido guardado con éxito!</h1>
			<h1 style={descriptionStyle}>{text}</h1>
			<Button type="button" onClick={onClose} text="Continuar" />
		</Modal>
	);
};

ParentsAmountModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	text: PropTypes.string,
};

export default ParentsAmountModal;
