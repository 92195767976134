import { authHeader, handleResponse } from './apiHandler';
import { urlGetDataList } from './backend-urls';

export const getDataLists = () => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};
	return fetch(urlGetDataList, requestOptions).then(handleResponse);
};

export default { getDataLists };
