export const startRequestFields = {
	NUMBER_APPLICANTS: 'partners_amount',
	CENTRAL_CONSULTATION: 'centralConsultation',
	DATA_TREATMENT: 'dataTreatment',
	DATA_AUTHORIZATION: 'data_authorization',
};

export const financialInformationFields = {
	AMOUNT_CREDIT: 'amountCredit',
	MONTHLY_INCOME: 'monthlyIncome',
	MONTHLY_EXPENSES: 'monthlyExpenses',
	OTHER_INCOME: 'otherIncome',
	SOURCE_OTHER_INCOME: 'source_of_other_income',
};

export const personalInformationFields = {
	NAME: 'first_name',
	LASTNAME: 'last_name',
	TYPE_ID: 'document_type',
	NUMBER_ID: 'document_number',
	EMAIL: 'email',
	PHONE_NUMBER: 'mobile',
	BIRTH_DATE: 'birthday',
	CITY: 'city',
	EMPLOYMENT: 'job',
	OCCUPATION: 'occupation',
	BRINGS_INCOME: 'brings_income',
	MONTHLY_INCOME: 'monthly_income',
	RELATIONSHIP: 'owner_relation',
	PLACE_BIRTH: 'place_birth',
	ADDRESS: 'address',
	COUNTRY: 'country',
	DEPARTMENT: 'department',
	ACTIVITY_TYPE: 'activity_type',
	CONTRACT_TYPE: 'contract_type',
	ADMISSION_DATE: 'job_join_date',
	DEPENDENTS: 'dependent_number_people',
	CIVIL_STATUS: 'civil_status',
	OWN_HOME: 'has_home',
	GENDER: 'gender',
	CENTRAL_CONSULTATION: 'central_consultation',
	DATA_TREATMENT: 'data_treatment',
	DATE_OF_EMPLOYMENT: 'date_of_employment',
	ACCEPTED_CONDITIONS_DATE: 'accepted_conditions_date',
	DATA_AUTHORIZATION: 'accepted_data_authorization_date',
};

export const credifamiliaFields = {
	CAPTCHA: 'captcha_code',
	TRANSACTION_ID_CAPTCHA: 'transaction_id_captcha',
};

export const financialDefaultValues = {
	[financialInformationFields.AMOUNT_CREDIT]: '',
	[financialInformationFields.MONTHLY_EXPENSES]: '',
	[financialInformationFields.MONTHLY_INCOME]: '',
	[financialInformationFields.OTHER_INCOME]: '',
	[financialInformationFields.SOURCE_OTHER_INCOME]: '',
};

export const companionDefaultValues = {
	[personalInformationFields.NAME]: '',
	[personalInformationFields.LASTNAME]: '',
	[personalInformationFields.TYPE_ID]: '',
	[personalInformationFields.NUMBER_ID]: '',
	[personalInformationFields.EMAIL]: '',
	[personalInformationFields.BIRTH_DATE]: '',
	[personalInformationFields.GENDER]: '',
	[personalInformationFields.CITY]: '',
	[personalInformationFields.EMPLOYMENT]: '',
	[personalInformationFields.BRINGS_INCOME]: '',
	[personalInformationFields.MONTHLY_INCOME]: '',
	[personalInformationFields.RELATIONSHIP]: '',
};
