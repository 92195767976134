import * as yup from 'yup';
import { personalInformationFields } from '../constants/finantialInformation';
import {
	EMPTY_FIELD,
	INVALID_EMAIL_FORMAT,
	INVALID_PHONE_FORMAT,
	MAX_FROM_NOW_DATE,
	MESSAGE_ERROR_DOMAIN,
} from '../../../../shared/application/constants/messages/error-messages';
import { validateEmailDomain } from '../../../../shared/application/helpers/common-functions';

export const commonPersonalInformation = {};
commonPersonalInformation[personalInformationFields.NAME] = yup.string().required(EMPTY_FIELD);
commonPersonalInformation[personalInformationFields.LASTNAME] = yup.string().required(EMPTY_FIELD);
commonPersonalInformation[personalInformationFields.BIRTH_DATE] = yup
	.date()
	.required(EMPTY_FIELD)
	.max(new Date(), MAX_FROM_NOW_DATE);
commonPersonalInformation[personalInformationFields.EMAIL] = yup
	.string()
	.email(INVALID_EMAIL_FORMAT)
	.test('not-con-domain', MESSAGE_ERROR_DOMAIN, (value) => validateEmailDomain(value))
	.required(EMPTY_FIELD);
commonPersonalInformation[personalInformationFields.PHONE_NUMBER] = yup
	.string()
	.required(EMPTY_FIELD)
	.matches(/^573/, INVALID_PHONE_FORMAT);
commonPersonalInformation[personalInformationFields.CITY] = yup.string().required(EMPTY_FIELD);
commonPersonalInformation[personalInformationFields.EMPLOYMENT] = yup.string().required(EMPTY_FIELD);
commonPersonalInformation[personalInformationFields.GENDER] = yup.string().required(EMPTY_FIELD);

export const commonPersonalInformationSchema = yup.object().shape(commonPersonalInformation);
