import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);

		// Added to prevent form focus - delete if prefer focus form
		const forms = Array.from(document.getElementsByTagName('form'));
		forms.forEach((form) => {
			form.scrollTo(0, 0);
			window.scrollTo(0, 0);
		});
	}, [pathname]);

	return null;
};

export default ScrollToTop;
