import React from 'react';
import { Button } from '@vecindario/vecindario-suite-components';
import AdminLayout from '../../../shared/presentation/layouts/AdminLayout';
import { UnauthenticatedRoute } from '../../../shared/presentation/redirect-route';
import LoginPage from '../presentation/pages/Login';
import RegisterPage from '../presentation/pages/Register';
import { loginRoute, registerRoute } from './routes';

const authRouter = {
	layout: AdminLayout,
	router: [
		{
			path: loginRoute,
			page: LoginPage,
			routeComponent: UnauthenticatedRoute,
			layout: ({ children }) => <>{children}</>,
		},
		{
			path: registerRoute,
			page: RegisterPage,
			routeComponent: UnauthenticatedRoute,
			// eslint-disable-next-line react/prop-types
			layout: ({ children }) => (
				<>
					{children}
					<Button text="Hola" />
				</>
			),
		},
	],
};

export default authRouter;
