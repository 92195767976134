import * as yup from 'yup';
import { EMPTY_FIELD } from '../../../../shared/application/constants/messages/error-messages';
import { financialInformationFields, credifamiliaFields } from '../constants/finantialInformation';

export const financialInformation = {};
financialInformation[financialInformationFields.AMOUNT_CREDIT] = yup.string().required(EMPTY_FIELD);
financialInformation[financialInformationFields.MONTHLY_EXPENSES] = yup.string().required(EMPTY_FIELD);
financialInformation[financialInformationFields.MONTHLY_INCOME] = yup.string().required(EMPTY_FIELD);
financialInformation[financialInformationFields.OTHER_INCOME] = yup.string();
financialInformation[financialInformationFields.SOURCE_OTHER_INCOME] = yup
	.string()
	.nullable()
	.when(financialInformationFields.OTHER_INCOME, {
		is: (other_income) => !!other_income && parseInt(other_income) > 0,
		then: yup.string().required(EMPTY_FIELD).typeError(EMPTY_FIELD),
	});
financialInformation[credifamiliaFields.CAPTCHA] = yup.string();
financialInformation[credifamiliaFields.TRANSACTION_ID_CAPTCHA] = yup.string();

export const financialInformationSchema = yup.object().shape(financialInformation);
