import React from 'react';
import { Collapse } from '@vecindario/vecindario-suite-components';
import questions from './questions.json';
import './FAQ.scss';

const FAQ = () => {
	return (
		<div className="faq-section">
			<h1 className="title">Preguntas frecuentes</h1>
			<Collapse items={questions} />
		</div>
	);
};

export default FAQ;
