import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { COMPANION_INFORMATION_BANNER } from '../../../../../shared/application/constants/images';
import InformativePageWrapper from '../../../../../shared/presentation/pages/InformativePageWrapper';
import ProgressBarComponent from '../../../../../shared/presentation/components/utils/ProgressBarComponent';
import CompanionInformationSection from '../../components/CompanionInformationSection';
import {
	isPreApprovedComplete,
	selectCurrentStep,
	selectPreApprovedDetail,
	selectPreApprovedResponse,
	selectRequestSteps,
} from '../../../application/selectors/credit-request';
import './CompanionInformation.scss';
import { selectCurrentUserName } from '../../../../authentication/application/selectors/user';
import { formatName } from '../../../../../shared/application/helpers/common-functions';
import { setAlertData } from '../../../../../shared/application/slices/adminLayout';
import {
	COMPLETE_PROGRESS_COMMENT,
	COMPLETE_PROGRESS_TEXT,
	SAVE_PROGRESS_MESSAGE,
} from '../../../../../shared/application/constants/messages/info-message';
import { fetchUserDetails } from '../../../infrastructure/api/associates-information';
import { history } from '../../../../../shared/application/helpers/history';
import { homeRoute } from '../../../../home/infrastructure/routing/routes';
import { REQUEST_STEP_KEY } from '../../../application/constants/local-storage';

const InfoDescription = (partners_amount, label_partner) => {
	useEffect(() => {
		localStorage.setItem(REQUEST_STEP_KEY, '4');
	}, []);

	return (
		<div>
			<p className="companion-wrapper-title">Queremos saber quién te acompañará en el proceso de tu crédito </p>
			<p className="companion-wrapper-description">
				{partners_amount === 1 && (
					<>¡Dos son mejor que uno! Compártenos la siguiente información de la persona con al que vas a tomar el crédito</>
				)}
				{partners_amount === 2 && (
					<>
						¡Tres son mejor que uno! Compártenos la siguiente información de la <b>{label_partner}</b> persona con la que vas
						a tomar el crédito
					</>
				)}
			</p>
		</div>
	);
};

const CompanionInformation = () => {
	const dispatch = useDispatch();
	const { partner_id } = useParams();
	const currentStep = useSelector(selectCurrentStep);
	const preApprovedDetail = useSelector(selectPreApprovedDetail);
	const preApprovedResponse = useSelector(selectPreApprovedResponse);
	const total_steps = useSelector(selectRequestSteps);
	const userName = useSelector(selectCurrentUserName);
	const [userData, setUserData] = React.useState({});

	const isComplete = useSelector(isPreApprovedComplete);
	const preApproved = preApprovedResponse || preApprovedDetail;

	useEffect(() => {
		if (preApproved?.total_steps < 4) {
			history.replace(homeRoute);
		}
	}, [preApproved]);

	useEffect(() => {
		if (partner_id) {
			fetchUserDetails(preApproved.id, partner_id).then((success) => {
				setUserData(success);
			});
		}
	}, [partner_id, preApproved]);

	useEffect(() => {
		if (currentStep === 4 && !preApproved.disabled) {
			dispatch(
				setAlertData({
					text: SAVE_PROGRESS_MESSAGE,
					type: 'information',
					icon: 'info-icon',
					dismissTime: 5000,
				}),
			);
		}
		return () => {
			dispatch(
				setAlertData({
					text: '',
					type: '',
					icon: '',
					dismissTime: 0,
				}),
			);
		};
	}, [dispatch, preApproved, currentStep]);

	const getPartnerLabel = () => {
		const { partners } = preApproved;
		if (partners) {
			const index = partners.findIndex((partner) => partner === partner_id);
			return index === 0 ? 'primera' : 'segunda';
		}
		return '';
	};

	return (
		<>
			<Helmet>
				<title>Preaprobados | Codeudores | Vecindario </title>
				<meta name="description" content="Solicitud de preaprobado online para crédito hipotecario." />
				<meta property="og:type" content="website" />
				<meta property="og:title" content="Preaprobados | Codeudores | Vecindario" />
				<meta property="og:description" content="Solicitud de preaprobado online para crédito hipotecario." />
				<meta property="og:image" content="https://fiducia-suite.s3.us-east-2.amazonaws.com/vecindario-logo.svg" />
			</Helmet>
			<InformativePageWrapper
				nameClassName="companion-information"
				name={formatName(userName)}
				description={InfoDescription(preApproved?.partners_amount, getPartnerLabel())}
				banner={COMPANION_INFORMATION_BANNER}
				bannerClassName="companion-information-banner"
			>
				<ProgressBarComponent
					title=""
					inlineText="PASO"
					comment="Estamos a punto de terminar"
					showActualStep={true}
					actualStep={4}
					totalSteps={total_steps}
					isComplete={isComplete}
					completeText={COMPLETE_PROGRESS_TEXT}
					completeComment={COMPLETE_PROGRESS_COMMENT}
				/>
				<CompanionInformationSection partnerId={partner_id} userData={userData} />
			</InformativePageWrapper>
		</>
	);
};

export default CompanionInformation;
