import { authHeader, handleResponse } from '../../../../shared/infrastructure/api/apiHandler';
import {
	urlGetLastPreApproved,
	urlPostPreApproved,
	urlFinancialInformation,
	urlUpdatePersonalInformation,
	urlPostRequest,
	urlPutPreApproved,
	urlPreApprovedList,
	urlPreApprovedResponse,
} from './backend-urls';

export const saveUserDetails = (id, data = {}) => {
	const requestOptions = {
		method: 'PATCH',
		headers: authHeader(),
		body: JSON.stringify(data),
	};
	return fetch(urlUpdatePersonalInformation(id), requestOptions).then(handleResponse);
};

export const saveUserBudget = (id, data = {}) => {
	const requestOptions = {
		method: 'PATCH',
		headers: authHeader(),
		body: JSON.stringify(data),
	};
	return fetch(urlFinancialInformation(id), requestOptions).then(handleResponse);
};

export const getUserBudget = (id = {}) => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};
	return fetch(urlFinancialInformation(id), requestOptions).then(handleResponse);
};

export const postPreApproved = (data = {}) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data),
	};
	return fetch(urlPostPreApproved, requestOptions).then(handleResponse);
};

export const updatePreApproved = (data = {}) => {
	const requestOptions = {
		method: 'PATCH',
		headers: authHeader(),
		body: JSON.stringify(data),
	};
	return fetch(urlPutPreApproved(data.id), requestOptions).then(handleResponse);
};

export const postRequestInformation = (data = {}) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data),
	};
	return fetch(urlPostRequest, requestOptions).then(handleResponse);
};

export const getLastPreApproved = () => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};
	return fetch(urlGetLastPreApproved, requestOptions).then(handleResponse);
};

export const getPreApprovedList = () => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};
	return fetch(urlPreApprovedList, requestOptions).then(handleResponse);
};

export const getPreApprovedResponse = (pre_approved_id) => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};
	return fetch(urlPreApprovedResponse(pre_approved_id), requestOptions).then(handleResponse);
};

export default { saveUserDetails };
