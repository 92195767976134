import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from '@vecindario/vecindario-suite-components';
import './ProgressBarComponent.scss';

const ProgressBarComponent = ({
	title,
	inlineText,
	comment,
	showActualStep,
	actualStep,
	totalSteps,
	completeText,
	completeComment,
	isComplete = false,
}) => {
	const progressValue = isComplete ? 100 : (actualStep * 100) / totalSteps;

	return (
		<div className="progress-bar-section">
			{isComplete ? (
				<p className="progress-bar-text">{completeText}</p>
			) : (
				<p className="progress-bar-text">
					{title}
					<br></br>
					{inlineText && `${inlineText}`} {showActualStep && `${actualStep} de ${totalSteps}`}
				</p>
			)}
			<ProgressBar progress={progressValue} classNameFill="progress-bar-fill" className="progress-bar-container" />
			{isComplete ? (
				<p className="progress-bar-comment">{completeComment}</p>
			) : (
				<p className="progress-bar-comment">{comment}</p>
			)}
		</div>
	);
};

ProgressBarComponent.propTypes = {
	title: PropTypes.string,
	inlineText: PropTypes.string,
	comment: PropTypes.string,
	showActualStep: PropTypes.bool,
	actualStep: PropTypes.number,
	totalSteps: PropTypes.number,
	isComplete: PropTypes.bool,
	completeText: PropTypes.string,
	completeComment: PropTypes.string,
};

export default ProgressBarComponent;
