import { createSelector } from '@reduxjs/toolkit';
import { PRE_APPROVED_RESULT_KEY } from '../constants/local-storage';
import { isEmptyObject } from '../../../../shared/application/helpers/common-functions';

export const creditRequestState = (state) => state.creditRequest;

export const selectRequestSteps = createSelector(creditRequestState, (request) => {
	const { currentPreApproved } = request;
	return currentPreApproved.total_steps || JSON.parse(localStorage.getItem(PRE_APPROVED_RESULT_KEY))?.total_steps || 3;
});

export const selectCurrentStep = createSelector(creditRequestState, (request) => {
	const { current_step } = request.currentPreApproved;
	return current_step || 1;
});

export const selectCities = createSelector(creditRequestState, (request) => {
	const { cities } = request;
	return cities;
});

export const selectDataLists = createSelector(creditRequestState, (request) => {
	const { dataLists } = request;
	return dataLists;
});

export const preApprvedMessageError = createSelector(creditRequestState, (request) => {
	const { preApprovedError } = request;
	return preApprovedError;
});

export const selectShowSuccessfulSave = createSelector(creditRequestState, (request) => {
	const { showSuccessfulSave } = request;
	return showSuccessfulSave;
});

export const selectPreApprovedDetail = createSelector(creditRequestState, (request) => {
	const { currentPreApproved } = request;
	return currentPreApproved;
});

export const dataCredential = createSelector(creditRequestState, (request) => {
	const { requestCredential } = request;
	return requestCredential;
});

export const loader = createSelector(creditRequestState, (request) => {
	const { loadingPreApproved } = request;
	return loadingPreApproved;
});

export const isPreApprovedComplete = createSelector(creditRequestState, (request) => {
	const { currentPreApproved } = request;
	return currentPreApproved?.stage && currentPreApproved.stage !== 'incomplete';
});

export const authorizationRequest = createSelector(creditRequestState, (request) => {
	const { authorizePreApproved } = request;
	return authorizePreApproved;
});

export const myPreApprovedList = createSelector(creditRequestState, (request) => {
	const { myPreApproved } = request;
	return myPreApproved;
});

export const ownerInformationData = createSelector(creditRequestState, (request) => {
	const { ownerInformation } = request;
	return ownerInformation;
});

export const selectOwnerPersonalInformationErrors = createSelector(creditRequestState, (request) => {
	const { ownerPersonaInformationErrors } = request;
	return ownerPersonaInformationErrors;
});

export const selectOwnerFinancialErrors = createSelector(creditRequestState, (request) => {
	const { ownerFinancialErrors } = request;
	return ownerFinancialErrors;
});

export const selectCompanionErrors = createSelector(creditRequestState, (request) => {
	const { companionErrors } = request;
	return companionErrors;
});

export const selectCaptchaError = createSelector(creditRequestState, (request) => {
	const { captchaErrors } = request;
	return captchaErrors;
});

export const selectUserAlreadyExistError = createSelector(creditRequestState, (request) => {
	const { userAlreadyExistError } = request;
	return userAlreadyExistError;
});

export const selectThankYouParams = createSelector(creditRequestState, (state) => {
	const { thankYouParams } = state;
	return thankYouParams;
});

export const selectPreApprovedResponse = createSelector(creditRequestState, (state) => {
	const { preApprovedResult } = state;
	return (
		(!isEmptyObject(preApprovedResult) && preApprovedResult) ||
		JSON.parse(localStorage.getItem(PRE_APPROVED_RESULT_KEY)) ||
		{}
	);
});

export const selectOwnerNameText = createSelector(creditRequestState, (request) => {
	const { ownerInformation } = request;
	return ownerInformation?.name ? `${ownerInformation?.name}, ` : '';
});
