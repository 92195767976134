import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Input } from '@vecindario/vecindario-suite-components';
import { getErrorMessage } from '../../../../../shared/application/helpers/common-functions';
import { fetchCaptcha } from '../../../infrastructure/api/pre-approved';
import { credifamiliaFields } from '../../../application/constants/finantialInformation';
import { CAPTCHA_TRANSACTION_ID } from '../../../application/constants/local-storage';

import './CaptchaSection.scss';
import Spinner from '../../../../../shared/presentation/components/utils/Spinner';

const CaptchaSection = ({ show, register, errors, setValue, reloadCaptcha }) => {
	const [captcha, setCaptcha] = useState('');
	const [loadCaptcha, setLoadCaptcha] = useState(false);
	const errorClassName = 'form-error-message';

	useEffect(() => {
		if (show || reloadCaptcha) {
			setLoadCaptcha(true);
			fetchCaptcha()
				.then((success) => {
					setValue(credifamiliaFields.TRANSACTION_ID_CAPTCHA, success.idTransaccion);
					localStorage.setItem(CAPTCHA_TRANSACTION_ID, success.idTransaccion);
					setCaptcha(success);
				})
				.finally(() => setLoadCaptcha(false));
		}
	}, [show, reloadCaptcha, setValue]);

	return (
		<>
			{show && (
				<div className="captcha-input">
					{!captcha?.urlCaptcha || loadCaptcha ? (
						<div className="captcha-code">
							<Spinner />
						</div>
					) : (
						<img src={captcha?.urlCaptcha} alt="" className="captcha-code" />
					)}

					<Input
						placeholder="Ingresa el código"
						{...register(credifamiliaFields.CAPTCHA)}
						error={getErrorMessage(credifamiliaFields.CAPTCHA, errors)}
						errorClassName={errorClassName}
						className="number-input"
					/>
				</div>
			)}
		</>
	);
};

CaptchaSection.propTypes = {
	show: PropTypes.any,
	reloadCaptcha: PropTypes.any,
	register: PropTypes.any,
	errors: PropTypes.any,
	setValue: PropTypes.any,
};

export default CaptchaSection;
